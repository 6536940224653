import AppReleaseNoteService from 'src/modules/appReleaseNote/appReleaseNoteService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'BENEFICIARY_FORM';


const appReleaseNoteFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,


  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: appReleaseNoteFormActions.UPDATE_STARTED,
      });

      await AppReleaseNoteService.update(id, values);

      dispatch({
        type: appReleaseNoteFormActions.UPDATE_SUCCESS,
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: appReleaseNoteFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default appReleaseNoteFormActions;
