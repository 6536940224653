import { NotificationsActive } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  Link,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { i18n } from 'src/i18n';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'src/hooks/useSnackbar';
import action from 'src/modules/notification/form/notificationFormActions';
import notificationEnumerators, {
  TNotificationStatus,
} from 'src/modules/notification/notificationEnumerators';
import { getHistory } from 'src/modules/store';
import NotificationList from './NotificationList';

const NotificationBell = (props) => {
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesXs = useMediaQuery(
    theme.breakpoints.down('md'),
  );

  const [notificationStatus, setNotificationStatus] =
    useState<TNotificationStatus>(
      notificationEnumerators.status[0].value,
    );
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (event) => {
    if (event?.target.value)
      setNotificationStatus(event?.target.value);
  };

  const markAllRead = () => {
    setIsLoading(true);
    dispatch(action.doMarkAllAsRead(openSnackbar));
  };

  return (
    <>
      <ButtonBase
        sx={{
          borderRadius: '12px',
        }}
        onClick={handleToggle}
      >
        <Avatar
          variant="rounded"
          ref={anchorRef}
          aria-controls={
            open ? 'menu-list-grow' : undefined
          }
          aria-haspopup="true"
          color="inherit"
          sx={{
            transition: 'all .2s ease-in-out',
            background: 'transparent',
            color: 'blue',
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          }}
        >
          <NotificationsActive />
        </Avatar>
      </ButtonBase>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => {
          return (
            <Fade
              in={open}
              timeout={{
                appear: 500,
                enter: 600,
                exit: 400,
              }}
              {...TransitionProps}
            >
              <Box
                sx={
                  matchesXs
                    ? {
                        transformOrigin: 'top',
                      }
                    : {
                        transformOrigin: 'top right',
                      }
                }
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={handleClose}
                  >
                    <Card
                      sx={{
                        border: 'none',
                        borderColor:
                          theme.palette.primary[200] + 25,
                        ':hover': {
                          boxShadow: theme.shadows[16],
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ pt: 2, px: 2 }}
                          >
                            <Grid item>
                              <Stack
                                direction="row"
                                spacing={2}
                              >
                                <Typography variant="subtitle1">
                                  {i18n(
                                    'entities.notification.allNotification',
                                  )}
                                </Typography>
                                <Chip
                                  size="small"
                                  label={
                                    unreadCount > 0
                                      ? String(
                                          unreadCount,
                                        ).padStart(2, '0')
                                      : unreadCount
                                  }
                                  sx={{
                                    color:
                                      theme.palette
                                        .background.default,
                                    bgcolor:
                                      theme.palette.warning
                                        .dark,
                                  }}
                                />
                              </Stack>
                            </Grid>
                            <Grid item>
                              {isLoading && (
                                <CircularProgress
                                  size={10}
                                />
                              )}
                              {!isLoading && (
                                <Link
                                  component="button"
                                  disabled={isLoading}
                                  onClick={markAllRead}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    color="primary"
                                  >
                                    {i18n(
                                      'entities.notification.markRead',
                                    )}
                                  </Typography>
                                </Link>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <div
                            style={{
                              height: '100%',
                              maxHeight:
                                'calc(100vh - 205px)',
                              overflowX: 'hidden',
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Box sx={{ px: 1 }}>
                                  <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    size="small"
                                    fullWidth
                                    value={
                                      notificationStatus
                                    }
                                    onChange={handleChange}
                                    SelectProps={{
                                      native: true,
                                      sx: {
                                        borderRadius:
                                          '10px',
                                        borderColor: 'blue',
                                      },
                                    }}
                                  >
                                    {notificationEnumerators.status.map(
                                      (option) => (
                                        <option
                                          key={option.value}
                                          value={
                                            option.value
                                          }
                                        >
                                          {i18n(
                                            `entities.notification.enumerators.status.` +
                                              option.value,
                                          )}
                                        </option>
                                      ),
                                    )}
                                  </TextField>
                                </Box>
                              </Grid>
                              <Grid item xs={12} p={0}>
                                <Divider sx={{ my: 0 }} />
                              </Grid>
                            </Grid>
                            <NotificationList
                              status={notificationStatus}
                              setUnread={setUnreadCount}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Divider />
                      <CardActions
                        sx={{
                          p: 1.25,
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          disabled={isLoading}
                          onClick={() =>
                            getHistory().push(
                              '/notification',
                            )
                          }
                        >
                          {i18n('mainPage.viewAll')}
                        </Button>
                      </CardActions>
                    </Card>
                  </ClickAwayListener>
                </Paper>
              </Box>
            </Fade>
          );
        }}
      </Popper>
    </>
  );
};

export default NotificationBell;
