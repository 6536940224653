import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'healthInfoProfileName',
    label: i18n('entities.healthInfo.fields.healthInfoProfileName'),
    schema: schemas.enumerator(
      i18n('entities.healthInfo.fields.healthInfoProfileName'),
      {
        "required": true,
      },
    ),
  },
  {
    name: 'healthInfoProfilePhone1',
    label: i18n('entities.healthInfo.fields.healthInfoProfilePhone1'),
    schema: schemas.string(
      i18n('entities.healthInfo.fields.healthInfoProfilePhone1'),
      {
      },
    ),
  },
  {
    name: 'healthInfoProfileAge',
    label: i18n('entities.healthInfo.fields.healthInfoProfileAge'),
    schema: schemas.string(
      i18n('entities.healthInfo.fields.healthInfoProfileAge'),
      {
      },
    ),
  },
  {
    name: 'healthInfoProfileAddress',
    label: i18n('entities.healthInfo.fields.healthInfoProfileAddress'),
    schema: schemas.decimal(
      i18n('entities.healthInfo.fields.healthInfoProfileAddress'),
      {},
    ),
  },
];
