import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n'; import insuranceEnumerators from 'src/modules/insurance/insuranceEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'insType',
    label: i18n('entities.insurance.fields.insType'),
    schema: schemas.enumerator(
      i18n('entities.insurance.fields.insType'),
      {
        "required": true,
        "options": insuranceEnumerators.insType
      },
    ),
  },
  {
    name: 'insInstitution',
    label: i18n('entities.insurance.fields.insInstitution'),
    schema: schemas.string(
      i18n('entities.insurance.fields.insInstitution'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'insNumber',
    label: i18n('entities.insurance.fields.insNumber'),
    schema: schemas.string(
      i18n('entities.insurance.fields.insNumber'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'insValue',
    label: i18n('entities.insurance.fields.insValue'),
    schema: schemas.decimal(
      i18n('entities.insurance.fields.insValue'),
      {},
    ),
  },
  {
    name: 'insCurrency',
    label: i18n('entities.insurance.fields.insCurrency'),
    schema: schemas.enumerator(
      i18n('entities.insurance.fields.insCurrency'),
      {
        "required": true,
        // "options": insuranceEnumerators.insCurrency
        "options": currencyEnumerators.cashCurrency
      },
    ),
  },
  // {
  //   name: 'insBen',
  //   label: i18n('entities.insurance.fields.insBen'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.insurance.fields.insBen'),
  //     {
  //     },
  //   ),
  // },
  {
    name: 'insFile',
    label: i18n('entities.insurance.fields.insFile'),
    schema: schemas.files(
      i18n('entities.insurance.fields.insFile'),
      {},
    ),
  },
  {
    name: 'insRemarks',
    label: i18n('entities.insurance.fields.insRemarks'),
    schema: schemas.string(
      i18n('entities.insurance.fields.insRemarks'),
      {},
    ),
  },
];
