import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { OrderBy } from 'src/types';

export const PUSH_TYPE = {
  EMAIL: 'EMAIL',
  USER_ID: 'USER_ID',
  REFERRAL_CODE: 'REFERRAL_CODE',
  TRUSTEE: 'TRUSTEE',
  TESTATOR: 'TESTATOR',
};

export default class notificationService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/notification/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/notification`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/notification`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/notification/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification/${id}`,
    );

    return response.data;
  }

  static async list(
    filter,
    orderBy: OrderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocompleteFilter(
    query,
    limit,
    filter,
    cur_val,
  ) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification/autocomplete`,
      {
        params,
      },
    );

    var result = [];
    var filter_result = [];

    if (filter === 'Residuary') {
      const filter_data = await authAxios.get(
        `/tenant/${tenantId}/residuary`,
        {
          params,
        },
      );

      filter_data.data.rows.forEach((item) => {
        if (item.resBen !== null)
          if (cur_val !== item.resBen.benName) {
            filter_result.push(item.resBen.benName);
          }
      });
    }

    response.data.forEach((item) => {
      if (!this.Check_IsExists(filter_result, item.label)) {
        result.push(item);
      } else {
      }
    });

    return result;
  }

  static Check_IsExists = (list, comparer) => {
    var result = false;

    list.forEach((item) => {
      if (item === comparer) {
        result = true;
      }
    });

    return result;
  };

  static async listAll(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification-all`,
      {
        params,
      },
    );

    return response.data;
  }

  static async refresh(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification_refresh`,
      {
        params,
      },
    );

    return response.data;
  }

  static async markAllRead() {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/notification_markread`,
    );

    return response.data;
  }

  static async Push_Notification(
    title,
    message,
    enable_email,
    push_type,
    push_to,
  ) {
    const body = {
      title: title,
      linkDesc: message,
      linkLocation: '/',
      enable_email: enable_email,
      push_type: push_type,
      push_to: push_to,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/notification/push_notification`,
      body,
    );

    return response.data;
  }
}
