import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class PetService {
  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet/${id}`,
    );

    return response.data;
  }

  static async list(
    filter,
    orderBy,
    limit,
    offset,
    withFileBuffer = false,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      withFileBuffer,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
