import { i18n } from 'src/i18n';
import globalActions from 'src/modules/global/globalActions';
import listActions from 'src/modules/pet/list/petListActions';
import PetService from 'src/modules/pet/petService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'PET_DESTROY';

const petDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, openSnackbar, type?) => async (dispatch) => {
      try {
        dispatch({
          type: petDestroyActions.DESTROY_STARTED,
        });

        await PetService.destroyAll([id]);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: petDestroyActions.DESTROY_SUCCESS,
        });

        openSnackbar(
          i18n('entities.pet.destroy.success'),
          'success',
        );

        dispatch(listActions.doFetchCurrentFilter());
        getHistory().push('/pet');
      } catch (error) {
        Errors.handle(error);

        dispatch(listActions.doFetchCurrentFilter());

        dispatch({
          type: petDestroyActions.DESTROY_ERROR,
        });
      }
    },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: petDestroyActions.DESTROY_ALL_STARTED,
      });

      await PetService.destroyAll(ids);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: petDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.pet.destroyAll.success'),
        'success',
      );

      getHistory().push('/pet');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: petDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default petDestroyActions;
