var TestatorAddress = (function () {
  // Get Or Set this from cookie/localStorage
  var address = '';

  var getAddress = function () {
    return address;
  };

  var setAddress = function (input) {
    address = input;
  };

  return {
    getAddress: getAddress,
    setAddress: setAddress,
  };
})();

export default TestatorAddress;
