import list from 'src/modules/planInvitation/list/planInvitationListReducers';
import form from 'src/modules/planInvitation/form/planInvitationFormReducers';
import destroy from 'src/modules/planInvitation/destroy/planInvitationDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  destroy,
});
