import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import ContactListService from 'src/modules/contactList/contactListService';
import globalActions from 'src/modules/global/globalActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'CONTACTLIST_FORM';

const doTableEmailUpdate = async (props) => {
  const id = props.planInvitationUserId;

  let values = {
    contactListEmail: props.planInvitationEmail,
  };
  await ContactListService.update(id, values);
};

const contactListFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contactListFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TenantService.findContactById(
          id,
          tenantId,
        );
      }

      dispatch({
        type: contactListFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListFormActions.INIT_ERROR,
      });

      getHistory().push('/contact-list');
    }
  },

  doCreate:
    (values, page = null, openSnackbar) =>
    async (dispatch) => {
      try {
        dispatch({
          type: contactListFormActions.CREATE_STARTED,
        });

        let newValues = values;
        newValues.contactListRole = 'Beneficiary';

        let request = {
          record_type: 'Contact_List',
          record_data: newValues,
          tenantId: tenantId,
        };
        await TenantService.Update_Tenant_Data(
          request,
          true,
        );
        dispatch(globalActions.doEditForm());
        dispatch({
          type: contactListFormActions.CREATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.contactList.create.success'),
          'success',
        );

        getHistory().push(
          !page ? `/contact-list` : `/` + page,
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdate:
    (id, values, fromPage = null, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: contactListFormActions.UPDATE_STARTED,
        });

        await ContactListService.update(id, values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: contactListFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.contactList.update.success'),
          'success',
        );

        // getHistory().push(`/contact-list/${id}`);
        switch (fromPage) {
          case 'beneficiary':
            getHistory().push(`/beneficiary`);
            break;
          default:
            getHistory().push(`/contact-list`);
            break;
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.UPDATE_ERROR,
        });
      }
    },

  doEmailUpdate:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: contactListFormActions.UPDATE_STARTED,
        });

        await doTableEmailUpdate(values);

        dispatch({
          type: contactListFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.contactList.update.success'),
          'success',
        );
        getHistory().push(`/contact-list`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Tenant_Update_Specific_Data:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: contactListFormActions.UPDATE_STARTED,
        });

        await TenantService.Update_Tenant_Specific_Data(
          values,
        );

        dispatch({
          type: contactListFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.contactList.update.success'),
          'success',
        );

        getHistory().push(`/contact-list`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: contactListFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default contactListFormActions;
