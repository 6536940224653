import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import testamentaryTrustEnumerators from 'src/modules/testamentaryTrust/testamentaryTrustEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'testamentaryTrustName',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustName'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'testamentaryTrustIdentification',
    label: i18n(
      'entities.testamentaryTrust.fields.testamentaryTrustIdentification',
    ),
    schema: schemas.string(
      i18n(
        'entities.testamentaryTrust.fields.testamentaryTrustIdentification',
      ),
      {},
    ),
  },
  {
    name: 'testamentaryTrustAddress',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustAddress'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustAddress'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustPhone',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustPhone'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustPhone'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustPhone2',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustPhone2'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustPhone2'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustEmail',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustEmail'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustEmail'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustPhoto',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustPhoto'),
    schema: schemas.images(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustPhoto'),
      {},
    ),
  },
  {
    name: 'fee',
    label: i18n('entities.testamentaryTrust.fields.fee'),
    schema: schemas.integer(
      i18n('entities.testamentaryTrust.fields.fee'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustCurrency',
    label: i18n(
      'entities.testamentaryTrust.fields.testamentaryTrustCurrency',
    ),
    schema: schemas.enumerator(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustCurrency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'testamentaryTrustRemarks',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustRemarks'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustRemarks'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustSequence',
    label: i18n(
      'entities.testamentaryTrust.fields.testamentaryTrustSequence',
    ),
    schema: schemas.integer(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustSequence'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustRelationship',
    label: i18n(
      'entities.testamentaryTrust.fields.testamentaryTrustRelationship',
    ),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustRelationship'),
      {},
    ),
  },
  {
    name: 'testamentaryTrustStatus',
    label: i18n('entities.testamentaryTrust.fields.testamentaryTrustStatus'),
    schema: schemas.string(
      i18n('entities.testamentaryTrust.fields.testamentaryTrustStatus'),
      {},
    ),
  },
];
