import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import AuthService from 'src/modules/auth/authService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import ExportHistoryService from 'src/modules/export_history/exporthistoryService';

const prefix = 'GLOBAL';

const globalActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  EDITFORM_SUCCESS: `${prefix}_EDITFORM_SUCCESS`,

  SAVE_REMOTETENANT_SETTING: `${prefix}_EDITFORM_SUCCESS`,

  doEditForm: () => async (dispatch, getState) => {
    try {
      const remote = AuthCurrentTenant.getRemote();

      const will_mode = localStorage.getItem('willMode');
      const will_type =
        will_mode === 'conventional' ? 'WILL' : 'WASIAT';
      const records = await ExportHistoryService.list(
        {
          user: `${remote.remoteUser._id}`,
          tenant: `${remote.remoteTenant._id}`,
          expType: will_type,
        },
        'createdAt_DESC',
        5,
        null,
      );
      if (records.count > 0) {
        // await AuthService.updateModifyForm({
        //   isModifyBefore: true,
        // });

        dispatch({
          type: globalActions.EDITFORM_SUCCESS,
          payload: {
            isEdit: true,
          },
        });
      }
    } catch (error) {
      Errors.handle(error);

      getHistory().push('/');
    }
  },
  doCloseEditForm: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: globalActions.EDITFORM_SUCCESS,
        payload: {
          isEdit: false,
        },
      });
    } catch (error) {
      Errors.handle(error);

      getHistory().push('/');
    }
  },
  doHideEditForm: () => async (dispatch, getState) => {
    try {
      await AuthService.updateModifyForm({
        isModifyBefore: false,
      });

      dispatch({
        type: globalActions.EDITFORM_SUCCESS,
        payload: {
          isEdit: false,
        },
      });
    } catch (error) {
      Errors.handle(error);

      getHistory().push('/');
    }
  },
};

export default globalActions;
