import { i18n } from 'src/i18n';
import ContactListService from 'src/modules/contactList/contactListService';
import listActions from 'src/modules/contactList/list/contactListListActions';
import globalActions from 'src/modules/global/globalActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'CONTACTLIST_DESTROY';

const contactListDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: contactListDestroyActions.DESTROY_STARTED,
      });

      await ContactListService.destroyAll([id]);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: contactListDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.contactList.destroy.success'),
        'success',
      );
      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/contact-list');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contactListDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyBen: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: contactListDestroyActions.DESTROY_STARTED,
      });

      await ContactListService.destroyAll([id]);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: contactListDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.contactList.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/beneficiary');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contactListDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: contactListDestroyActions.DESTROY_ALL_STARTED,
      });

      await ContactListService.destroyAll(ids);

      dispatch({
        type: contactListDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.contactList.destroyAll.success'),
        'success',
      );
      getHistory().push('/contact-list');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contactListDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default contactListDestroyActions;
