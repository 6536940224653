import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.residuary.fields.id'),
  },
  {
    name: 'resBen',
    label: i18n('entities.residuary.fields.resBen'),
    render: exporterRenders.json(),
  },
  {
    name: 'resBen2',
    label: i18n('entities.residuary.fields.resBen2'),
    render: exporterRenders.json(),
  },
  {
    name: 'resPercent',
    label: i18n('entities.residuary.fields.resPercent'),
  },
  {
    name: 'resRemarks',
    label: i18n('entities.residuary.fields.resRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.residuary.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.residuary.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
