import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';
import WitnessService from 'src/modules/witness/witnessService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'WITNESS_MAIN';

const witnessMainActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: witnessMainActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);
      const remote = AuthCurrentTenant.getRemote();
      const platform = window.location.pathname.includes(
        'hibah',
      )
        ? 'Hibah'
        : window.location.pathname.includes('harta')
        ? 'Harta'
        : remote.platform;

      if (isEdit) {
        // record = await WitnessService.findMain(id);
        record = await TenantService.tenant_Find_Type(
          'Witness',
          tenantId,
          platform,
        );
      }

      dispatch({
        type: witnessMainActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: witnessMainActions.INIT_ERROR,
      });

      getHistory().push('/witness');
    }
  },

  doCreateMain:
    (values, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: witnessMainActions.CREATE_STARTED,
        });

        await WitnessService.create(values, true);

        dispatch({
          type: witnessMainActions.CREATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.witness.create.success'),
          'success',
        );

        getHistory().push('/willListPage');
        // getHistory().push('/witness');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: witnessMainActions.CREATE_ERROR,
        });
      }
    },

  doUpdateMain:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: witnessMainActions.UPDATE_STARTED,
        });

        await WitnessService.update(id, values, true);

        dispatch({
          type: witnessMainActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.witness.update.success'),
          'success',
        );
        getHistory().push('/willListPage');
        // getHistory().push(`/witness/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: witnessMainActions.UPDATE_ERROR,
        });
      }
    },
};

export default witnessMainActions;
