import { tenantSubdomain } from '../tenant/tenantSubdomain';
import EncryptionTool from 'src/modules/encryption/dataEncryption';
import globalEnumerators from 'src/modules/global/globalEnumerators';

export default class AuthCurrentTenant {
  static selectAndSaveOnStorageFor(currentUser) {
    if (!currentUser) {
      return null;
    }

    if (!currentUser.tenants) {
      return null;
    }

    const activeTenants = currentUser.tenants.filter(
      (tenantUser) => tenantUser.status === 'active',
    );

    if (!activeTenants || !activeTenants.length) {
      return null;
    }

    const tenantId = this.get();

    let tenant;

    if (tenantId) {
      tenant = activeTenants
        .map((tenantUser) => tenantUser.tenant)
        .find((tenant) => tenant.id === tenantId);
    }

    tenant = tenant || activeTenants[0].tenant;

    if (
      tenant &&
      tenantSubdomain.isEnabled &&
      !tenantSubdomain.isSubdomainOf(tenant.url)
    ) {
      return tenantSubdomain.redirectAuthenticatedTo(
        tenant.url,
      );
    }

    this.set(tenant);
    return tenant;
  }

  static get() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );

    if (tenantASString) {
      return JSON.parse(tenantASString).id;
    }

    return null;
  }

  static getTenant() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );
    try {
      if (tenantASString) {
        return JSON.parse(tenantASString);
      }
    } catch (exception) {
      this.clear();
      return null;
    }
    return null;
  }

  static getSettings() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenant'),
    );

    if (tenantASString) {
      const tenant = JSON.parse(tenantASString);

      if (tenant) {
        if (Array.isArray(tenant.settings)) {
          if (tenant.settings.length) {
            return tenant.settings[0];
          }
        } else {
          return tenant.settings;
        }
      }
    }

    return null;
  }

  static set(tenant) {
    if (!tenant) {
      return this.clear();
    }

    var newObj = {
      _id: tenant._id,
      id: tenant.id,
      createdBy: tenant.createdBy,
      settings: tenant.settings,
      helpInfo: tenant.helpInfo,
    };

    localStorage.setItem(
      'tenant',
      EncryptionTool.encrypt(JSON.stringify(newObj)),
      // EncryptionTool.encrypt(JSON.stringify(tenant)),
    );
  }

  static clear() {
    localStorage.removeItem('tenant');
    localStorage.removeItem('tenantAssistant');
    this.cleaRemoteSetting();
    this.clearNotification();
    this.removeRemote();
  }

  static clearAllLocal() {
    //retain language setting to prevent user have to reselect the preferred language again
    localStorage.removeItem('jwt');
    localStorage.removeItem('tenant');
    localStorage.removeItem('tenantAssistant');
    localStorage.removeItem('remoteObject');
    localStorage.removeItem('continueShowLanding');
    localStorage.removeItem('access_lists');
    this.clearNotification();
    localStorage.setItem('currentpage', null);
    localStorage.removeItem('isEditForm');
    localStorage.removeItem('assistantPermission');
  }

  static setAssistant(assistant) {
    if (!assistant) {
      return this.clearAssistant();
    }

    var newObj = Object.assign({}, assistant);
    delete newObj.tenant;
    delete newObj.currentUser;
    newObj = {
      ...newObj,
      tenant: {
        createdBy: assistant.tenant.createdBy,
      },
      currentUser: {
        id: assistant.currentUser.id,
      },
    };

    localStorage.setItem(
      'tenantAssistant',
      EncryptionTool.encrypt(JSON.stringify(newObj)),
      // EncryptionTool.encrypt(JSON.stringify(assistant)),
    );
  }

  static getAssistant(target) {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenantAssistant'),
    );

    if (tenantASString) {
      var result = JSON.parse(tenantASString);
      var tenant = result['tenant'];
      var currentUser = result['currentUser'];

      if (tenant.createdBy === currentUser.id) {
        return {
          view: true,
          edit: true,
        };
      }

      const deathStatus = result['deathStatus'];
      const viewField = 'assistant' + target + 'View';
      const modeField = 'assistant' + target + 'Mode';

      if (result[viewField] === undefined) {
        return {
          view: true,
          edit: true,
        };
      }

      return {
        view:
          result[viewField] === 'Now' ||
          (result[viewField] === 'AfterDeath' &&
            deathStatus),
        edit: result[modeField] === 'Read & Edit',
      };
    }

    return {
      view: true,
      edit: true,
    };
  }

  static getAssistantObject() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('tenantAssistant'),
    );

    if (tenantASString) {
      return JSON.parse(tenantASString);
    }

    return null;
  }

  static isAssistantRemote() {
    // const tenantASString = EncryptionTool.decrypt(
    //   localStorage.getItem('tenantAssistant'),
    // );

    // return tenantASString ? true : false;

    return localStorage.hasOwnProperty('tenantAssistant')
      ? true
      : false;
  }

  static clearAssistant() {
    localStorage.removeItem('tenantAssistant');
  }

  // For wasiat/Conventional setting
  static setRemoteSetting(setting) {
    if (!setting) {
      return this.cleaRemoteSetting();
    }

    localStorage.setItem(
      'tenantRemoteSetting',
      JSON.stringify(setting),
    );
  }

  static getWillMode() {
    const remote = this.getRemote();

    if (remote?.remoteTenant) {
      const setting = remote?.remoteTenant;
      return setting.userSettingsWillMode ?? null;
    }

    return null;
  }

  static cleaRemoteSetting() {
    localStorage.removeItem('tenantRemoteSetting');
  }

  //#region For Notification
  static setNotification(notice) {
    if (!notice) {
      return this.clearNotification();
    }
    var newNotice = {
      data: notice,
      retrieve_time: new Date(),
    };

    localStorage.setItem(
      'notification_lists',
      JSON.stringify(newNotice),
    );
  }

  static clearNotification() {
    localStorage.removeItem('notification_lists');
  }

  static getNotificationList() {
    const noticeASString =
      localStorage.getItem('notification_lists') || null;

    if (noticeASString) {
      const notice = JSON.parse(noticeASString);
      return notice ?? null;
    }

    return null;
  }
  //#endregion

  //#region Remote
  static setRemote(item) {
    // const filteredObject = Object.fromEntries(
    //   Object.entries(item).filter(
    //     ([key]) =>
    //       key === 'isRemote' || key === 'tenant',
    //   ),
    // );

    // const parsedObject = item;
    // if (parsedObject.tenant) {
    //   parsedObject.tenant = Object.fromEntries(
    //     Object.entries(parsedObject.tenant).filter(
    //       ([key]) => key === '_id',
    //     ),
    //   );
    // }

    // if (parsedObject.user) {
    //   delete parsedObject.user.tenants;
    // }

    // console.log(
    //   'remoteObject filtered:',
    //   JSON.stringify(parsedObject),
    // );
    if (!item) {
      return this.removeRemote();
    }

    var tmp = {
      isRemote: item.isRemote,
      platform: item.platform,
      tenant: {
        _id: item.tenant._id,
        useWasiatForHibah:
          item.tenant.useWasiatForHibah ?? false,
        userSettingsWillMode:
          item.tenant.userSettingsWillMode,
      },
      user: {
        _id: item.user._id,
        fullName: item.user.fullName,
        idNo: item.user.idNo,
        address: item.user.address,
        email: item.user.email,
      },
      // assistantPermission: item.assistantPermission,
    };

    const current = EncryptionTool.decrypt(
      localStorage.getItem('assistantPermission'),
    );

    if (current?._id !== item.assistantPermission?._id) {
      localStorage.setItem(
        'assistantPermission',
        EncryptionTool.encrypt(
          JSON.stringify(item.assistantPermission),
        ),
      );
    }

    localStorage.setItem(
      'remoteObject',
      EncryptionTool.encrypt(JSON.stringify(tmp)),
    );
  }

  static getRemote() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('remoteObject'),
    );

    if (tenantASString) {
      const item = JSON.parse(tenantASString);
      return {
        remoteTenant: item.tenant,
        remoteUser: item.user,
        platform: item.platform,
        isRemote: item.isRemote
          ? Boolean(item.isRemote)
          : false,
        assistantPermission: item.assistantPermission,
      };
    }

    return null;
  }

  static getRemotePermission() {
    const tenantASString = EncryptionTool.decrypt(
      localStorage.getItem('assistantPermission'),
    );

    if (tenantASString) {
      const item = JSON.parse(tenantASString);
      return item;
    }

    return null;
  }

  static removeRemote() {
    localStorage.removeItem('remoteObject');
    localStorage.removeItem('assistantPermission');
    this.cleaRemoteSetting();
    this.clearAssistant();
  }

  //#endregion
}
