const propertyEnumerators = {
  propType: ['Residential', 'Commercial', 'Land'],
  propDesc: [
    'Bungalow',
    'SemiDetached',
    'Terrace',
    'Townhouse',
    'Condominium',
    'Shophouse',
    'Office',
    'Retail',
    'HotelResort',
    'Factory',
    'Warehouse',
    'AgriculturalLand',
    'IndustrialLand',
    'CommercialLand',
    'ResidentialLand',
  ],
  propDescResidential: [
    'Bungalow',
    'SemiDetached',
    'Terrace',
    'Townhouse',
    'Condominium',
    'Shophouse',
  ],
  propTypeOfLandTitle:[
    'Strata',
    'Individual'
  ],
  propDescCommercial: [
    'Office',
    'Retail',
    'HotelResort',
    'Factory',
    'Warehouse',
  ],
  propDescLand: [
    'AgriculturalLand',
    'IndustrialLand',
    'CommercialLand',
    'ResidentialLand',
  ],
  propOwnershipType:[
    "SoleOwnership",
    "JointlyTenancy",
  ],
  propDistribution:[
    "HARTASEPENCARIAN",
    "HIBAH",
    "WASIAT",
  ]
};

export default propertyEnumerators;
