import { i18n } from 'src/i18n';
import accessListService from 'src/modules/accessList/accessListService';
import assistantService from 'src/modules/assistant/assistantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import AuthService from 'src/modules/auth/authService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';
import workspacePageActions from '../page/workspacePageActions';
import authActions from 'src/modules/auth/authActions';

const prefix = 'WORKSPACE';

const workspaceActions = {
  REMOTE_CUSTOMER_STARTED: `${prefix}_REMOTE_CUSTOMER_STARTED`,
  REMOTE_CUSTOMER_SUCCESS: `${prefix}_REMOTE_CUSTOMER_SUCCESS`,
  REMOTE_CUSTOMER_ERROR: `${prefix}_REMOTE_CUSTOMER_ERROR`,

  RETRIEVE_REMOTE_CUSTOMER_INFORMATION_STARTED: `${prefix}_RETRIEVE_REMOTE_CUSTOMER_INFORMATION_STARTED`,
  RETRIEVE_REMOTE_CUSTOMER_INFORMATION_SUCCESS: `${prefix}_RETRIEVE_REMOTE_CUSTOMER_INFORMATION_SUCCESS`,
  RETRIEVE_REMOTE_CUSTOMER_INFORMATION_ERROR: `${prefix}_RETRIEVE_REMOTE_CUSTOMER_INFORMATION_ERROR`,

  DISABLE_LANDING_PROCESS: `${prefix}_DISABLE_LANDING_PROCESS`,

  doRemoteCustomer:
    (id, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: workspaceActions.REMOTE_CUSTOMER_STARTED,
        });

        await dispatch(
          workspaceActions.doRetrieveRemoteCustomerInformation(
            id,
            openSnackbar,
            true,
          ),
        );

        dispatch({
          type: workspaceActions.REMOTE_CUSTOMER_SUCCESS,
        });

        getHistory().push(`/share-with-me/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: workspaceActions.REMOTE_CUSTOMER_ERROR,
        });
      }
    },

  doRetrieveRemoteCustomerInformation:
    (id, openSnackbar, showMessage = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: workspaceActions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_STARTED,
        });

        var remoteTenant = await TenantService.find(id);
        var remoteUser = await AuthService.fetchUser(
          remoteTenant.id,
          remoteTenant.name,
        );

        var jsonString =
          localStorage.getItem('activeTab') || null;

        var currentActiveTab = localStorage.getItem(
          'currentActiveTab',
        );

        if (jsonString !== null) {
          const hist_tab: {
            id?: string;
            tabs?: string[];
          } = JSON.parse(jsonString);

          if (hist_tab.id !== id) {
            localStorage.removeItem('activeTab');
            localStorage.setItem('currentActiveTab', '1');
          }
        }

        if (!currentActiveTab) {
          localStorage.setItem('currentActiveTab', '1');
        }

        await dispatch(workspacePageActions.doInitTab());

        var currentUser = await AuthService.fetchMe();

        localStorage.setItem(
          'willMode',
          remoteTenant?.userSettingsWillMode
            ? remoteTenant?.userSettingsWillMode
            : 'conventional',
        );

        const remotePlan = remoteTenant.plan;
        let assistantPermission = null;

        let accessList =
          await accessListService.listAllRemote(
            null,
            null,
            null,
            null,
            remoteTenant.id,
          );

        let assistant =
          await assistantService.findByUserIDAndTestatorID(
            currentUser.id,
            remoteTenant.createdBy,
          );

        let access = accessList.rows.filter((x) => {
          return (
            x.planPackage
              .toString()
              .trim()
              .toLowerCase() ===
            remotePlan.toString().trim().toLowerCase()
          );
        });

        if (access.length > 0) {
          assistantPermission = access[0];
        }

        assistant['currentUser'] = currentUser;
        assistant['tenant'] = remoteTenant;
        AuthCurrentTenant.setAssistant(assistant);

        var oldRemote = AuthCurrentTenant.getRemote();
        let platformDetection =
          remoteTenant?.userSettingsWillMode?.toUpperCase() ===
          'WASIAT'
            ? oldRemote?.platform
            : 'Will';

        const remote = {
          isRemote: true,
          tenant: remoteTenant,
          platform: platformDetection,
          user:
            remoteUser.count > 0
              ? remoteUser.rows[0]
              : null,
          assistantPermission: assistantPermission,
        };

        AuthCurrentTenant.setRemote(remote);

        dispatch({
          type: authActions.AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
            remoteTenant,
            remoteUser: remoteUser.rows[0],
            assistantPermission,
          },
        });

        dispatch({
          type: workspaceActions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_SUCCESS,
          payload: {
            tenant: remoteTenant,
            user: remoteUser.rows[0],
            platform: platformDetection,
          },
        });

        dispatch(
          workspacePageActions.doCreateTab(
            remoteTenant?.userSettingsWillMode,
            remoteTenant,
            remoteUser.rows[0],
          ),
        );

        if (showMessage) {
          openSnackbar(
            i18n(
              'workspace.remote.remoteUser',
              remoteTenant.name,
            ),
            'success',
          );
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: workspaceActions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_ERROR,
        });
      }
    },

  doDisableLandingPage: (platform) => async (dispatch) => {
    dispatch({
      type: workspaceActions.DISABLE_LANDING_PROCESS,
      payload: {
        platform: platform,
      },
    });
  },
};

export default workspaceActions;
