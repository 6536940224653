import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/otherAsset/importer/otherAssetImporterSelectors';
import OtherAssetService from 'src/modules/otherAsset/otherAssetService';
import fields from 'src/modules/otherAsset/importer/otherAssetImporterFields';
import { i18n } from 'src/i18n';

const otherAssetImporterActions = importerActions(
  'OTHERASSET_IMPORTER',
  selectors,
  OtherAssetService.import,
  fields,
  i18n('entities.otherAsset.importer.fileName'),
);

export default otherAssetImporterActions;