
import main from 'src/modules/specificGift/main/specificGiftMainReducers';
import list from 'src/modules/specificGift/list/specificGiftListReducers';

import { combineReducers } from 'redux';

export default combineReducers({
  main,
  list,
});
