import { Button, Menu, MenuItem } from '@mui/material';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { useState } from 'react';
import * as React from 'react';
import EncryptionTool from 'src/modules/encryption/dataEncryption';

const LanguageSelectorItem = () => {
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [language, setlanguage] = useState(
    localStorage.getItem('language')
      ? EncryptionTool.decrypt(
          localStorage.getItem('language'),
        )
      : 'en',
  );
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const update_language = (selected_language) => {
    setAnchorEl(null);

    if (selected_language !== language) {
      setlanguage(selected_language);
      localStorage.setItem(
        'language',
        EncryptionTool.encrypt(selected_language),
      );
      getHistory().go(0);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={
          open ? 'demo-positioned-menu' : undefined
        }
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {i18n(`dashboard.languages.${language}`)}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => update_language('en')}>
          {i18n('dashboard.languages.en')}
        </MenuItem>
        <MenuItem onClick={() => update_language('cn')}>
          {i18n('dashboard.languages.cn')}
        </MenuItem>
        {/* <MenuItem onClick={() => update_language('bm')}>
          {i18n('dashboard.languages.bm')}
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default LanguageSelectorItem;
