import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/healthInfo/importer/healthInfoImporterSelectors';
import HealthInfoService from 'src/modules/healthInfo/healthInfoService';
import fields from 'src/modules/healthInfo/importer/healthInfoImporterFields';
import { i18n } from 'src/i18n';

const healthInfoImporterActions = importerActions(
  'HEALTH_INFO_IMPORTER',
  selectors,
  HealthInfoService.import,
  fields,
  i18n('entities.healthInfo.importer.fileName'),
);

export default healthInfoImporterActions;