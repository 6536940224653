import ContactBaseService from 'src/modules/contactBase/contactBaseService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import tenantService from 'src/modules/tenant/tenantService';

const prefix = 'CONTACTBASE_VIEW';

const contactBaseViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contactBaseViewActions.FIND_STARTED,
      });

      const record = await tenantService.tenant_Find(
        'Contact_Base',
        id,
        null,
      );

      dispatch({
        type: contactBaseViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactBaseViewActions.FIND_ERROR,
      });

      getHistory().push('/contact-base');
    }
  },
};

export default contactBaseViewActions;
