import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'locationAddress',
    label: i18n('entities.location.fields.locationAddress'),
    schema: schemas.string(
      i18n('entities.location.fields.locationAddress'),
      {},
    ),
  },
  {
    name: 'locationDescription',
    label: i18n('entities.location.fields.locationDescription'),
    schema: schemas.string(
      i18n('entities.location.fields.locationDescription'),
      {},
    ),
  },
];