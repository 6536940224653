import { i18n } from 'src/i18n';
import AccountService from 'src/modules/account/accountService';
import globalActions from 'src/modules/global/globalActions';
import Errors from 'src/modules/shared/error/errors';
import SpecificGiftService from 'src/modules/specificGift/specificGiftService';
import { getHistory } from 'src/modules/store';

const prefix = 'ACCOUNT_FORM';

const accountFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: accountFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await AccountService.find(id);
      }

      dispatch({
        type: accountFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: accountFormActions.INIT_ERROR,
      });

      getHistory().push('/account');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: accountFormActions.CREATE_STARTED,
      });

      await AccountService.create(values);

      dispatch({
        type: accountFormActions.CREATE_SUCCESS,
      });
      openSnackbar(
        i18n('entities.account.create.success'),
        'success',
      );
      getHistory().push('/account');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: accountFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar, noRedirect?: boolean) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: accountFormActions.UPDATE_STARTED,
        });

        await AccountService.update(id, values);
        const response = await SpecificGiftService.list(
          null,
          null,
          null,
          null,
        );

        const data = response.rows.filter(
          (x) => x.onModel === 'account',
        );
        if (data.length > 0) {
          var isExistsSpecificGift = data.filter(
            (y) => y.giftId?.id === id,
          );
          if (isExistsSpecificGift.length > 0) {
            dispatch(globalActions.doEditForm());
          }
        }
        dispatch({
          type: accountFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.account.update.success'),
          'success',
        );
        if (!noRedirect) {
          getHistory().push(`/account`);
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: accountFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default accountFormActions;
