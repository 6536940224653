import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/resources/importer/resourcesImporterSelectors';
import ResourcesService from 'src/modules/resources/resourcesService';
import fields from 'src/modules/resources/importer/resourcesImporterFields';
import { i18n } from 'src/i18n';

const resourcesImporterActions = importerActions(
  'RESOURCES_IMPORTER',
  selectors,
  ResourcesService.import,
  fields,
  i18n('entities.resources.importer.fileName'),
);

export default resourcesImporterActions;