import list from 'src/modules/couponGenerator/list/couponGeneratorListReducers';
import form from 'src/modules/couponGenerator/form/couponGeneratorFormReducers';
import view from 'src/modules/couponGenerator/view/couponGeneratorViewReducers';
import destroy from 'src/modules/couponGenerator/destroy/couponGeneratorDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
