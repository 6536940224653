import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.vehicle.fields.id'),
  },
  {
    name: 'type',
    label: i18n('entities.vehicle.fields.type'),
  },
  {
    name: 'registrationNo',
    label: i18n('entities.vehicle.fields.registrationNo'),
  },
  {
    name: 'assetValue',
    label: i18n('entities.vehicle.fields.assetValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'currency',
    label: i18n('entities.vehicle.fields.currency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'freeofEncumbrances',
    label: i18n(
      'entities.vehicle.fields.freeofEncumbrances',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'ben',
    label: i18n('entities.vehicle.fields.ben'),
    render: exporterRenders.json(),
  },
  {
    name: 'ben2',
    label: i18n('entities.vehicle.fields.ben2'),
    render: exporterRenders.json(),
  },
  {
    name: 'ben3',
    label: i18n('entities.vehicle.fields.ben3'),
    render: exporterRenders.json(),
  },
  {
    name: 'photo',
    label: i18n('entities.vehicle.fields.photo'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'files',
    label: i18n('entities.vehicle.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'filesLocation',
    label: i18n('entities.vehicle.fields.filesLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'remarks',
    label: i18n('entities.vehicle.fields.remarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.vehicle.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.vehicle.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
