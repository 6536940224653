import { i18n } from 'src/i18n';
import HeirService from 'src/modules/heir/heirService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = '  HEIR_FORM';

const heirFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: heirFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await HeirService.find(id);
      }

      dispatch({
        type: heirFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: heirFormActions.INIT_ERROR,
      });

      getHistory().push('/contact-list');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: heirFormActions.CREATE_STARTED,
      });

      await HeirService.create(values);

      dispatch({
        type: heirFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.heir.create.success'),
        'success',
      );

      getHistory().push('/contact-list');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: heirFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: heirFormActions.UPDATE_STARTED,
        });

        await HeirService.update(id, values);

        dispatch({
          type: heirFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.heir.update.success'),
          'success',
        );

        getHistory().push(`/contact-list`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: heirFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default heirFormActions;
