import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assistant/importer/assistantImporterSelectors';
import AssistantService from 'src/modules/assistant/assistantService';
import fields from 'src/modules/assistant/importer/assistantImporterFields';
import { i18n } from 'src/i18n';

const assistantImporterActions = importerActions(
  'ASSISTANT_IMPORTER',
  selectors,
  AssistantService.import,
  fields,
  i18n('assistant.importer.fileName'),
);

export default assistantImporterActions;