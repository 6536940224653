import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/beneficiary/importer/beneficiaryImporterSelectors';
import BeneficiaryService from 'src/modules/beneficiary/beneficiaryService';
import fields from 'src/modules/beneficiary/importer/beneficiaryImporterFields';
import { i18n } from 'src/i18n';

const beneficiaryImporterActions = importerActions(
  'BENEFICIARY_IMPORTER',
  selectors,
  BeneficiaryService.import,
  fields,
  i18n('entities.beneficiary.importer.fileName'),
);

export default beneficiaryImporterActions;