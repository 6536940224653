import moment from 'moment';
import { i18n } from 'src/i18n';
import en from 'react-phone-number-input/locale/en.json'

const exporterRenders = {
  stringArray: () => (value) => (value || []).join(', '),
  json: () => (value) =>
    value ? JSON.stringify(value, null, 2) : null,
  integer: () => (value) =>
    value ? Number(value) : 0,
  decimal: (fractionDigits?) => (value) =>
    value
      ? fractionDigits
        ? Number(value).toFixed(fractionDigits)
        : Number(value)
      : null,
  boolean: () => (value) => String(Boolean(value)),
  relationToOne: () => (value) =>
    (value && value.id) || null,
  relationToMany: () => (value) =>
    (value || []).map((item) => item.id).join(' '),
  filesOrImages: () => (value) =>
    (value || []).map((item) => item.downloadUrl).join(' '),
  datetime: () => (value) =>
    value ? moment(value).format('YYYY-MM-DD HH:mm') : null,
  currency: () => (value) =>
    value ? i18n(`cash.cashCurrency.${value}`) : null,
  relationToBenPercentage: () => (value) =>
    (value || []).map((item) => JSON.stringify(item.ben, null, 2)).join(' '),
  country: () => (value) =>
    value ? en[value] : '',
};

export default exporterRenders;
