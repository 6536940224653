import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import assistantEnumerators from 'src/modules/assistant/assistantEnumerators';

export default [
  {
    name: 'assistantType',
    label: i18n('assistant.fields.assistantType'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantType'),
      {
        "required": true,
        "options": assistantEnumerators.assistantType
      },
    ),
  },
  {
    name: 'assistantBeneficiaryId',
    label: i18n('assistant.fields.assistantBeneficiaryId'),
    schema: schemas.relationToMany(
      i18n('assistant.fields.assistantBeneficiaryId'),
      {
      },
    ),
  },
  {
    name: 'assistantExecutorId',
    label: i18n('assistant.fields.assistantExecutorId'),
    schema: schemas.relationToMany(
      i18n('assistant.fields.assistantExecutorId'),
      {
      },
    ),
  },
  {
    name: 'assistantLegalGuardianId',
    label: i18n('assistant.fields.assistantLegalGuardianId'),
    schema: schemas.relationToMany(
      i18n('assistant.fields.assistantLegalGuardianId'),
      {
      },
    ),
  },
  {
    name: 'assistantContactlistId',
    label: i18n('assistant.fields.assistantContactlistId'),
    schema: schemas.relationToMany(
      i18n('assistant.fields.assistantContactlistId'),
      {
      },
    ),
  },
  {
    name: 'email',
    label: i18n(
      'assistant.fields.email',
    ),
    schema: schemas.string(
      i18n('assistant.fields.email'),
      {},
    ),
  },
  {
    name: 'emailBody',
    label: i18n(
      'assistant.fields.emailBody',
    ),
    schema: schemas.string(
      i18n('assistant.fields.emailBody'),
      {},
    ),
  },
  {
    name: 'link',
    label: i18n(
      'assistant.fields.link',
    ),
    schema: schemas.string(
      i18n('assistant.fields.link'),
      {},
    ),
  },
  {
    name: 'invitationOption',
    label: i18n('assistant.fields.invitationOption'),
    schema: schemas.enumerator(
      i18n('assistant.fields.invitationOption'),
      {
        "required": true,
        "options": assistantEnumerators.invitationOption
      },
    ),
  },
  {
    name: 'invitationStatus',
    label: i18n('assistant.fields.invitationStatus'),
    schema: schemas.enumerator(
      i18n('assistant.fields.invitationStatus'),
      {
        "required": true,
        "options": assistantEnumerators.invitationStatus
      },
    ),
  },
  {
    name: 'accessRight',
    label: i18n('assistant.fields.accessRight'),
    schema: schemas.boolean(
      i18n('assistant.fields.accessRight'),
      {},
    ),
  },
  {
    name: 'assistantAccountsView',
    label: i18n('assistant.fields.assistantAccountsView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantAccountsView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantBeneficiariesView',
    label: i18n('assistant.fields.assistantBeneficiariesView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantBeneficiariesView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantDebtsView',
    label: i18n('assistant.fields.assistantDebtsView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantDebtsView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantExecutorsView',
    label: i18n('assistant.fields.assistantExecutorsView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantExecutorsView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantLegalGuardiansView',
    label: i18n('assistant.fields.assistantLegalGuardiansView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantLegalGuardiansView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantOtherGiftsView',
    label: i18n('assistant.fields.assistantOtherGiftsView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantOtherGiftsView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantPledgesView',
    label: i18n('assistant.fields.assistantPledgesView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantPledgesView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantPropertiesView',
    label: i18n('assistant.fields.assistantPropertiesView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantPropertiesView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantResiduariesView',
    label: i18n('assistant.fields.assistantResiduariesView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantResiduariesView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantLastInstructionsView',
    label: i18n('assistant.fields.assistantLastInstructionsView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantLastInstructionsView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantWitnessesView',
    label: i18n('assistant.fields.assistantWitnessesView'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantWitnessesView'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantAccountsEdit',
    label: i18n('assistant.fields.assistantAccountsEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantAccountsEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantBeneficiariesEdit',
    label: i18n('assistant.fields.assistantBeneficiariesEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantBeneficiariesEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantDebtsEdit',
    label: i18n('assistant.fields.assistantDebtsEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantDebtsEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantExecutorsEdit',
    label: i18n('assistant.fields.assistantExecutorsEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantExecutorsEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantLegalGuardiansEdit',
    label: i18n('assistant.fields.assistantLegalGuardiansEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantLegalGuardiansEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantOtherGiftsEdit',
    label: i18n('assistant.fields.assistantOtherGiftsEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantOtherGiftsEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantPledgesEdit',
    label: i18n('assistant.fields.assistantPledgesEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantPledgesEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantPropertiesEdit',
    label: i18n('assistant.fields.assistantPropertiesEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantPropertiesEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantResiduariesEdit',
    label: i18n('assistant.fields.assistantResiduariesEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantResiduariesEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantLastInstructionsEdit',
    label: i18n('assistant.fields.assistantLastInstructionsEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantLastInstructionsEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'assistantWitnessesEdit',
    label: i18n('assistant.fields.assistantWitnessesEdit'),
    schema: schemas.enumerator(
      i18n('assistant.fields.assistantWitnessesEdit'),
      {
        "required": true,
        "options": assistantEnumerators.assistantView
      },
    ),
  },
  {
    name: 'tenant',
    label: i18n('assistant.fields.tenant'),
    schema: schemas.relationToMany(
      i18n('assistant.fields.tenant'),
      {
      },
    ),
  },



];
