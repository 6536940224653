import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assetCategory/importer/assetCategoryImporterSelectors';
import AssetCategoryService from 'src/modules/assetCategory/assetCategoryService';
import fields from 'src/modules/assetCategory/importer/assetCategoryImporterFields';
import { i18n } from 'src/i18n';

const assetCategoryImporterActions = importerActions(
  'ASSETCATEGORY_IMPORTER',
  selectors,
  AssetCategoryService.import,
  fields,
  i18n('entities.assetCategory.importer.fileName'),
);

export default assetCategoryImporterActions;