import { i18n } from 'src/i18n';
import AccountService from 'src/modules/account/accountService';
import listActions from 'src/modules/account/list/accountListActions';
import globalActions from 'src/modules/global/globalActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'ACCOUNT_DESTROY';

const accountDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: accountDestroyActions.DESTROY_STARTED,
      });

      await AccountService.destroyAll([id]);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: accountDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.account.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/account');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: accountDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: accountDestroyActions.DESTROY_ALL_STARTED,
      });

      await AccountService.destroyAll(ids);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: accountDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.account.destroyAll.success'),
        'success',
      );

      getHistory().push('/account');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: accountDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default accountDestroyActions;
