import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import legalGuardianEnumerators from 'src/modules/legalGuardian/legalGuardianEnumerators';

export default [
  {
    name: 'legalGuardianSequence',
    label: i18n(
      'entities.legalGuardian.fields.legalGuardianSequence',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.legalGuardian.fields.legalGuardianSequence',
      ),
      {
        options:
          legalGuardianEnumerators.legalGuardianSequence,
      },
    ),
  },
  {
    name: 'legalGuardianName',
    label: i18n(
      'entities.legalGuardian.fields.legalGuardianName',
    ),
    schema: schemas.string(
      i18n(
        'entities.legalGuardian.fields.legalGuardianName',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'legalGuardianIdentificationType',
    label: i18n(
      'entities.legalGuardian.fields.legalGuardianIdentificationType',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.legalGuardian.fields.legalGuardianIdentificationType',
      ),
      {
        required: true,
        options:
          legalGuardianEnumerators.legalGuardianIdentificationType,
      },
    ),
  },
  {
    name: 'legalGuardianIdentificationNo',
    label: i18n(
      'entities.legalGuardian.fields.legalGuardianIdentificationNo',
    ),
    schema: schemas.string(
      i18n(
        'entities.legalGuardian.fields.legalGuardianIdentificationNo',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'legalGuardianAddress',
    label: i18n(
      'entities.legalGuardian.fields.legalGuardianAddress',
    ),
    schema: schemas.string(
      i18n(
        'entities.legalGuardian.fields.legalGuardianAddress',
      ),
      {
        required: true,
      },
    ),
  },
];
