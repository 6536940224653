import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.account.fields.id'),
  },
  {
    name: 'accType',
    label: i18n('entities.account.fields.accType'),
  },
  {
    name: 'accInstitution',
    label: i18n('entities.account.fields.accInstitution'),
  },
  {
    name: 'accNumber',
    label: i18n('entities.account.fields.accNumber'),
  },
  {
    name: 'accValue',
    label: i18n('entities.account.fields.accValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'accCurrency',
    label: i18n('entities.account.fields.accCurrency'),
    render: exporterRenders.relationToOne(),
  },
  // {
  //   name: 'accBen',
  //   label: i18n(
  //     'entities.account.fields.accBen',
  //   ),
  //   render: exporterRenders.json(),
  // },
  // {
  //   name: 'accBen2',
  //   label: i18n(
  //     'entities.account.fields.accBen2',
  //   ),
  //   render: exporterRenders.json(),
  // },
  {
    name: 'accFile',
    label: i18n('entities.account.fields.accFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'accRemarks',
    label: i18n('entities.account.fields.accRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.account.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.account.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
