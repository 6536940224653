import { Box, Divider, styled } from '@mui/material';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Tour from 'reactour';
import authSelectors from 'src/modules/auth/authSelectors';
import { getHistory } from 'src/modules/store';
import Header from 'src/view/layout/Header';
import Ticket from 'src/view/layout/Ticket';
import TimerModal from 'src/view/layout/TimerModal';
import steps from 'src/view/layout/tour_steps/dashboard_step';
import SideBar from './SideBar';
import './styles.css';

export const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const tabs = {
  Unselect: -1,
  Dashboard: 0,
  Customers: 1,
  Performance: 2,
  MyAccount: 3,
  PayoutReport: 4,
  GetHelp: 5,
  SignOut: 6,
  Resources: 7,
  Academy: 8,
};

const Layout = (props) => {
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);
  const [activeRoute, setActiveRoute] = useState<string>();
  const [hoveredTab, setHoveredTab] = useState(-1);
  const [openTour, setOpenTour] = useState(false);

  const match = useRouteMatch();
  const [, SetZoomScale] = useState(100);
  const [timerModalVisible, setTimerModalVisible] =
    useState(false);
  // const scroll = useScrollListener();
  let idleTimer = null;

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const isTrustee = currentUser?.userRole === 'trustee';

  const checkSelectedTab = () => {
    const url = match.url;

    switch (url) {
      case '/':
        setActiveRoute('/');
        setSelectedTab(tabs.Dashboard);
        break;
      case '/resources':
        setActiveRoute('/resources');
        setSelectedTab(tabs.Resources);
        break;
      case '/my-account':
        setActiveRoute('/my-account');
        setSelectedTab(tabs.MyAccount);
        break;
      case '/customers':
        setActiveRoute('/customers');
        setSelectedTab(tabs.Customers);
        break;
      case '/performance':
        setActiveRoute('/performance');
        setSelectedTab(tabs.Performance);
        break;
      case '/academy':
        setActiveRoute('/academy');
        setSelectedTab(tabs.Academy);
        break;
      default:
        if (url.includes('/share-with-me')) {
          setActiveRoute('/share-with-me');
          setSelectedTab(tabs.Customers);
        } else {
          setActiveRoute(undefined);
          setSelectedTab(null);
        }
        break;
    }

    let date = new Date();
    let today = new Date();

    let Advisory_Comprehensive_ExpiryDate = new Date(
      currentTenant?.Advisory_Comprehensive_ExpiryDate ||
        date.setDate(date.getDate() - 1),
    );

    let Difference_In_Time =
      Advisory_Comprehensive_ExpiryDate.getTime() -
      today.getTime();
    let Difference_In_Days =
      Difference_In_Time / (1000 * 3600 * 24);

    let day_different = Math.ceil(Difference_In_Days);

    if (
      day_different < 0 &&
      !['trustee', 'legal_firm'].includes(
        currentUser.userRole,
      )
    ) {
      getHistory().push('/subscription');
    }
  };

  useEffect(() => {
    // window.FreshworksWidget('identify', 'ticketForm', {
    //   name: currentUser?.fullName,
    //   email: currentUser?.email,
    // });
    checkSelectedTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const onChange = (newScale) => {
    window.sessionStorage.setItem('zoomValue', newScale);
    SetZoomScale(newScale);
  };

  const handleOnIdle = (e) => {
    handleTimerModalVisible(true);
  };

  const handleTimerModalVisible = (e) => {
    setTimerModalVisible(e);
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Tour
        startAt={
          location?.state ? location?.state?.step : 0
        }
        steps={steps}
        isOpen={
          location?.state?.open_tour
            ? location?.state?.open_tour
            : openTour
        }
        disableInteraction={true}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        onRequestClose={() => {
          setOpenTour(false);
        }}
        showButtons={false}
        showNavigation={false}
        showCloseButton={false}
        closeWithMask={false}
      />
      <Header
        isMobile={isMobile}
        onZoomChange={onChange}
        set_open_tour={setOpenTour}
        match={match}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        hoveredTab={hoveredTab}
        setHoveredTab={setHoveredTab}
        currentTenant={currentTenant}
        currentUser={currentUser}
        isTrustee={isTrustee}
        tabs={tabs}
        open={open}
        setOpen={setOpen}
      />
      <SideBar
        isOpen={open}
        expend={handleDrawerClose}
        activeRoute={activeRoute}
      />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Divider />
        {props.children}
        <Ticket url={match.url} />
      </Box>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        timeout={1000 * 60 * 5}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <TimerModal
        visible={timerModalVisible}
        handleTimerModalVisible={handleTimerModalVisible}
      />
    </Box>
  );
};

export default Layout;
