import list from 'src/modules/assistant/list/assistantListReducers';
import form from 'src/modules/assistant/form/assistantFormReducers';
import view from 'src/modules/assistant/view/assistantViewReducers';
import destroy from 'src/modules/assistant/destroy/assistantDestroyReducers';
 import importerReducer from 'src/modules/assistant/importer/assistantImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
