import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import LastInstructionsService from 'src/modules/lastInstructions/lastInstructionsService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'LASTINSTRUCTIONS_FORM';

const lastInstructionsFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: lastInstructionsFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TenantService.tenant_Find(
          'Last_Instruction',
          id,
          tenantId,
        );
      }

      dispatch({
        type: lastInstructionsFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: lastInstructionsFormActions.INIT_ERROR,
      });

      getHistory().push('/last-instructions');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: lastInstructionsFormActions.CREATE_STARTED,
      });

      await LastInstructionsService.create(values);

      dispatch({
        type: lastInstructionsFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.lastInstructions.create.success'),
        'success',
      );

      getHistory().push('/last-instructions');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: lastInstructionsFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: lastInstructionsFormActions.UPDATE_STARTED,
        });

        await LastInstructionsService.update(id, values);

        dispatch({
          type: lastInstructionsFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.lastInstructions.update.success'),
          'success',
        );

        // getHistory().push(`/last-instructions/${id}`);
        getHistory().push(`/last-instructions`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: lastInstructionsFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default lastInstructionsFormActions;
