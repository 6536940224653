import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/vehicle/importer/vehicleImporterSelectors';
import VehicleService from 'src/modules/vehicle/vehicleService';
import fields from 'src/modules/vehicle/importer/vehicleImporterFields';
import { i18n } from 'src/i18n';

const vehicleImporterActions = importerActions(
  'VEHICLE_IMPORTER',
  selectors,
  VehicleService.import,
  fields,
  i18n('entities.vehicle.importer.fileName'),
);

export default vehicleImporterActions;