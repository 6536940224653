import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
// import petEnumerators from 'src/modules/pet/petEnumerators';

export default [
  {
    name: 'photo',
    label: i18n('entities.pet.fields.photo'),
    schema: schemas.images(
      i18n('entities.pet.fields.photo'),
      {},
    ),
  },
  {
    name: 'name',
    label: i18n('entities.pet.fields.name'),
    schema: schemas.string(
      i18n('entities.pet.fields.name'),
      {
        required: true,
      },
    ),
  },    
  {
    name: 'remarks',
    label: i18n('entities.pet.fields.remarks'),
    schema: schemas.string(
      i18n('entities.pet.fields.remarks'),
      {},
    ),
  },
];