import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class TrusteeService {
  static async update(id, data,mainList = false) {
    const body = {
      id,
      data,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/trustee/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/trustee`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data,mainList = false) {
    const body = {
      data,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trustee`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trustee/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trustee/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset, withFileBuffer = false,mainList= false) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      withFileBuffer,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trustee`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trustee/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }


  static async invite(data, para) {
    const body = {
      data,
      para,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trusteeInvite`,
      body,
    );

    return response.data;
  }

  //#region MainList
  static async findMain(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trustees/${id}`,
    );

    return response.data;
  }

  //#endregion


}
