// snackbarGlobal.ts
let globalOpenSnackbar: (
    message: string,
    severity: 'success' | 'error' | 'info' | 'warning',
    autoHideDuration?: number,
  ) => void;
  
  export const setGlobalOpenSnackbar = (
    openSnackbar: typeof globalOpenSnackbar,
  ) => {
    globalOpenSnackbar = openSnackbar;
  };
  
  export const getGlobalOpenSnackbar = () => {
    if (!globalOpenSnackbar) {
      throw new Error(
        'globalOpenSnackbar is not initialized. Ensure SnackbarProvider is mounted.',
      );
    }
    return globalOpenSnackbar;
  };
  