import React, { useEffect, useState } from 'react';
import DocViewer, {
  DocViewerRenderers,
  IHeaderOverride,
} from '../shared/docRenderer';
import { i18n } from 'src/i18n';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Button, Typography } from '@mui/material';
import FileViewer from 'react-file-viewer';
import './DocViewer.css'
import { DownloadOutlined } from '@mui/icons-material';
import FileSaver from 'file-saver';
import mime from 'mime-types';

const viewerSupportType = ['csv'
  , 'xlsx'
  , 'jpg'
  , 'jpeg'
  , 'gif'
  , 'bmp'
  , 'png'
  , 'pdf'
  , 'docx'
  , 'mp3'
  , 'webm'
  , 'mp4'
  , 'wexbim']

const DocViewerPage = (props) => {
  // console.log('hey file uri', props.file);

  const docs = [
    {
      uri: props.file,
      downloadName: props.downloadFileName,
    },
  ];

  const [fileType, setFileType] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(props.file);
      const mimeType = data.headers.get('content-type');
      var type = mime.extension(mimeType);
      // console.log("data", data)
      // console.log("mimeType", mimeType)
      // console.log("type", type)

      if (type) {
        if (viewerSupportType.indexOf(type) > 0) {
          setFileType(type);
          return;
        }
      }
      setFileType('');
    }

    if (props.file !== "empty" && isMobile)
      fetchData();
  }, [props.file]);

  const downloadFile = () => {
    // console.log("props.file", props.file)
    // console.log("props.downloadFileName", props.downloadFileName)

    FileSaver.saveAs(props.file, props.downloadFileName);
  }

  const myHeader: IHeaderOverride = (
    state,
    previousDocument,
    nextDocument,
  ) => {
    if (
      !state.currentDocument ||
      state.config?.header?.disableFileName
    ) {
      return null;
    }

    return (
      <>
        <div>{state.currentDocument.uri || ''}</div>
        <div>
          <button
            onClick={previousDocument}
            disabled={state.currentFileNo === 0}
          >
            Back Document
          </button>
          <button
            onClick={nextDocument}
            disabled={
              state.currentFileNo >=
              state.documents.length - 1
            }
          >
            Next Document
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      fullScreen
      style={{
        margin: '24px 10% 24px 10%'
      }}
      sx={{
        '& .css-hz1bth-MuiDialog-container': {
          height: 'auto'
        },
      }}
      onClose={() => props.onCancel()}
      scroll='paper'
      open={props.visible}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >{i18n('app.fileViewer')}</Box>
          <Box>
            <IconButton aria-label="delete" onClick={() => props.onCancel()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.fileType !== 'video' && !isMobile && (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            config={{
              header: {
                disableHeader: true,
                disableFileName: false,
                retainURLParams: false,
                overrideComponent: myHeader,
              },
            }}
            style={{ height: '80vh' }}
          />
        )}

        {props.fileType !== 'video' && isMobile && (
          <>
            {fileType !== '' && fileType !== null &&
              <>
                <div style={{ paddingBottom: '2%' }}>
                <Button
                      sx={{
                        textTransform: 'none',
                        borderRadius: '0',
                        background:
                          'linear-gradient(270deg, #0078FF 0%, #004A9E 100%)',
                        boxShadow: 'none',
                        '&:hover': {
                          boxShadow: 'none',
                        },
                      }}
                      startIcon={<DownloadOutlined style={{color:'#FFFFFF'}} />}
                      onClick={downloadFile}
                    >
                      <Typography
                        fontFamily="Rubik"
                        fontWeight="500"
                        fontSize="20px"
                        lineHeight="24px"
                        color="#FFFFFF"
                      >
                        {i18n('common.download')}
                      </Typography>

                    </Button>
                </div>

                <div style={{ marginTop: '2%' }}>

                  <FileViewer
                    fileType={fileType}
                    filePath={props.file}
                  />
                </div>
              </>}

            {(fileType === '') &&
              <>
                File is not supported
              </>}

          </>
        )}

        {props.fileType === 'video' && (
          <ReactPlayer
            url={props.file}
            controls={true}
            width="100%"
          />
        )}
      </DialogContent>

    </Dialog>
    // <Modal
    //   style={{ top: 24 }}
    //   title={i18n('app.fileViewer')}
    //   visible={props.visible}
    //   maskClosable={false}
    //   onCancel={() => props.onCancel()}
    //   footer={false}
    //   width={isMobile ? '100%' : '80%'}
    // >
    //   {/* <ReportViewer /> */}
    //   {props.fileType !== 'video' && (
    //     <DocViewer
    //       pluginRenderers={DocViewerRenderers}
    //       documents={docs}
    //       config={{
    //         header: {
    //           disableHeader: true,
    //           disableFileName: false,
    //           retainURLParams: false,
    //           overrideComponent: myHeader,
    //         },
    //       }}
    //       style={{ height: '80vh' }}
    //     />
    //   )}

    //   {props.fileType === 'video' && (
    //     <ReactPlayer
    //       url={props.file}
    //       controls={true}
    //       width="100%"
    //     />
    //   )}
    // </Modal>
  );
};

export default DocViewerPage;
