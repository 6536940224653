import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class customerBaseService {
  static async sendMessage(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/customer-base`,
      body,
    );

    return response.data;
  }

  static async listFilter(id, filter, orderBy, limit, offset) {
    const params = {
      id,
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/customer-base-filter`,
      {
        params,
      },
    );

    return response.data;
  }

  static async list(id, filter, orderBy, limit, offset) {
    const params = {
      id,
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/customer-base`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAgent(id, filter, orderBy, limit, offset) {
    const params = {
      id,
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/customer-base-agent`,
      {
        params,
      },
    );

    return response.data;
  }
}
