import { i18n } from 'src/i18n';
import HealthInfoService from 'src/modules/healthInfo/healthInfoService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'HEALTH_INFO_FORM';

const healthInfoFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: healthInfoFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await HealthInfoService.find(id);
      }

      dispatch({
        type: healthInfoFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: healthInfoFormActions.INIT_ERROR,
      });

      getHistory().push('/health-info');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: healthInfoFormActions.CREATE_STARTED,
      });

      const record = await HealthInfoService.create(values);

      dispatch({
        type: healthInfoFormActions.CREATE_SUCCESS,
        payload: record,
      });

      openSnackbar(
        i18n('entities.healthInfo.create.success'),
        'success',
      );
      // getHistory().push(`/emergency-card`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: healthInfoFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: healthInfoFormActions.UPDATE_STARTED,
        });

        const record = await HealthInfoService.update(
          id,
          values,
        );

        dispatch({
          type: healthInfoFormActions.UPDATE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('entities.healthInfo.update.success'),
          'success',
        );
        // getHistory().push(`/emergency-card`);
        // getHistory().push(`/emergency-card/${record._id}/qr-code`);
        return record.id;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: healthInfoFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default healthInfoFormActions;
