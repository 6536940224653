import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/witness/importer/witnessImporterSelectors';
import WitnessService from 'src/modules/witness/witnessService';
import fields from 'src/modules/witness/importer/witnessImporterFields';
import { i18n } from 'src/i18n';

const witnessImporterActions = importerActions(
  'WITNESS_IMPORTER',
  selectors,
  WitnessService.import,
  fields,
  i18n('entities.witness.importer.fileName'),
);

export default witnessImporterActions;