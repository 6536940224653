import { i18n } from 'src/i18n';
import CouponGeneratorService from 'src/modules/couponGenerator/couponGeneratorService';
import listActions from 'src/modules/couponGenerator/list/couponGeneratorListActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'COUPON_GENERATOR_DESTROY';

const couponGeneratorDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_STARTED,
      });

      await CouponGeneratorService.destroyAll([id]);

      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.couponGenerator.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/coupon-generator');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_ALL_STARTED,
      });

      await CouponGeneratorService.destroyAll(ids);

      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.couponGenerator.destroyAll.success'),
        'success',
      );
      getHistory().push('/coupon-generator');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: couponGeneratorDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default couponGeneratorDestroyActions;
