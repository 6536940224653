const insuranceEnumerators = {
  insType: [
    "lifeInsurance",
    "carInsurance",
    "criticalInsurance",
    "personalInsurance",
    "medicInsurance",
    "travelInsurance",
  ],
};

export default insuranceEnumerators;
