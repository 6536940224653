import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import globalActions from 'src/modules/global/globalActions';
import PledgeService from 'src/modules/pledge/pledgeService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'PLEDGE_FORM';

const pledgeFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: pledgeFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TenantService.tenant_Find(
          'Pledges',
          id,
          tenantId,
        );
      }

      dispatch({
        type: pledgeFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pledgeFormActions.INIT_ERROR,
      });

      getHistory().push('/pledge');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: pledgeFormActions.CREATE_STARTED,
      });

      await PledgeService.create(values);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: pledgeFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.pledge.create.success'),
        'success',
      );

      getHistory().push('/pledge');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pledgeFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: pledgeFormActions.UPDATE_STARTED,
        });

        await PledgeService.update(id, values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: pledgeFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.pledge.update.success'),
          'success',
        );

        getHistory().push('/pledge');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: pledgeFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default pledgeFormActions;
