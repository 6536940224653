import form from 'src/modules/wallet/form/walletFormReducers';
import view from 'src/modules/wallet/view/walletViewReducers';
import destroy from 'src/modules/wallet/destroy/walletReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  form,
  view,
  destroy,
});
