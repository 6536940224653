import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contactBase.fields.id'),
  },
  {
    name: 'contactBasePhoto',
    label: i18n('entities.contactBase.fields.contactBasePhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'contactBaseName',
    label: i18n('entities.contactBase.fields.contactBaseName'),
  },
  {
    name: 'contactBasePhone',
    label: i18n('entities.contactBase.fields.contactBasePhone'),
  },
  {
    name: 'contactBasePhone2',
    label: i18n('entities.contactBase.fields.contactBasePhone2'),
  },
  {
    name: 'contactBaseEmail',
    label: i18n('entities.contactBase.fields.contactBaseEmail'),
  },
  {
    name: 'contactBaseAddress',
    label: i18n('entities.contactBase.fields.contactBaseAddress'),
  },
  {
    name: 'contactBaseRemarks',
    label: i18n('entities.contactBase.fields.contactBaseRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.contactBase.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.contactBase.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
