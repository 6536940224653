import { Box, BoxProps } from '@mui/material';
import styled from 'styled-components';

interface IModalContainer extends BoxProps {}
export const ModalContainer = styled(Box)<IModalContainer>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 650px;
  width: 100%;
  height: 77vh;
  background-color: #fefefe;
  box-shadow: 24px;
  border: solid 0px #2c3e50;
  border-radius: 12px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface IHorizontalListContainer extends BoxProps {}
export const HorizontalListContainer = styled(
  Box,
)<IHorizontalListContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: scroll;
  gap: 1;
  margin-top: 1;
  margin-bottom: 3;
  ::-webkit-scrollbar {
    display: none;
  }
`;
