import React from 'react';
import _get from 'lodash/get';
// import moment from 'moment';
import { setLocale as setYupLocale } from 'yup';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import EncryptionTool from 'src/modules/encryption/dataEncryption';

let currentLanguageCode = '';
let bm_wasiat = null;
let en_wasiat = null;
let cn_wasiat = null;
let en_will = null;

const languages: {
  [key: string]: {
    id: string;
    label: string;
    flag: string;
    antd: any;
    dictionary: any;
    wasiat: any;
    planInvitation: any;
  };
} = {
  en: {
    id: 'en',
    label: 'English',
    // flag: '/images/flags/Malaysia.png',
    flag: '/images/flags/United-States.png',
    antd: null,
    dictionary: null,
    wasiat: null,
    planInvitation: null,
  },
  // es: {
  //   id: 'es',
  //   label: 'Espa�ol',
  //   flag: '/images/flags/Spain.png',
  //   antd: null,
  //   dictionary: null,
  // },
  // 'pt-BR': {
  //   id: 'pt-BR',
  //   label: 'Portugu�s',
  //   flag: '/images/flags/Brazil.png',
  //   antd: null,
  //   dictionary: null,
  // },
  bm: {
    id: 'bm',
    label: 'Bahasa Melayu',
    flag: '/images/flags/Malaysia.png',
    antd: null,
    dictionary: null,
    wasiat: null,
    planInvitation: null,
  },
  cn: {
    id: 'cn',
    label: '中文',
    flag: '/images/flags/United-States.png',
    antd: null,
    dictionary: null,
    wasiat: null,
    planInvitation: null,
  },
};

export async function init() {
  try {
    currentLanguageCode = localStorage.getItem('language')
      ? EncryptionTool.decrypt(
          localStorage.getItem('language'),
        )
      : 'en';
    setLanguageCode(currentLanguageCode);
  } catch (error) {
    currentLanguageCode = 'en';
    setLanguageCode(currentLanguageCode);
  }
  if (currentLanguageCode === 'en') {
    await initEn();
  }

  if (currentLanguageCode === 'bm') {
    await initBm();
  }
  if (currentLanguageCode === 'cn') {
    await initCn();
  }

  bm_wasiat = (await import('src/i18n/bm-wasiat')).default;
  en_wasiat = (await import('src/i18n/en-wasiat')).default;
  cn_wasiat = (await import('src/i18n/cn-wasiat')).default;
  en_will = (await import('src/i18n/en')).default;
}

// async function initPtBr() {
//   const language = languages['pt-BR'];

//   // @ts-ignore
//   const momentLocale = (await import('moment/locale/pt-br'))
//     .default;

//   language.antd = (
//     await import('antd/lib/locale-provider/pt_BR')
//   ).default;

//   language.dictionary = (
//     await import('src/i18n/pt-BR')
//   ).default;

//   moment.locale('pt-BR', momentLocale);

//   if (language.dictionary.validation) {
//     setYupLocale(language.dictionary.validation);
//   }

//   return language;
// }

// async function initEs() {
//   const language = languages['es'];

//   // @ts-ignore
//   const momentLocale = (await import('moment/locale/es'))
//     .default;

//   language.antd = (
//     await import('antd/lib/locale-provider/es_ES')
//   ).default;

//   language.dictionary = (
//     await import('src/i18n/es')
//   ).default;

//   moment.locale('es', momentLocale);

//   if (language.dictionary.validation) {
//     setYupLocale(language.dictionary.validation);
//   }

//   return language;
// }

async function initEn() {
  const language = languages['en'];

  language.dictionary = (
    await import('src/i18n/en')
  ).default;

  language.wasiat = (
    await import('src/i18n/en-wasiat')
  ).default;

  language.planInvitation = (
    await import('src/i18n/en-planInvitation')
  ).default;

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }

  return language;
}

async function initBm() {
  const language = languages['bm'];

  language.dictionary = (
    await import('src/i18n/bm')
  ).default;

  language.wasiat = (
    await import('src/i18n/bm-wasiat')
  ).default;

  language.planInvitation = (
    await import('src/i18n/bm-planInvitation')
  ).default;

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }

  return language;
}

async function initCn() {
  const language = languages['cn'];

  language.dictionary = (
    await import('src/i18n/cn')
  ).default;

  language.wasiat = (
    await import('src/i18n/cn-wasiat')
  ).default;

  language.planInvitation = (
    await import('src/i18n/cn-planInvitation')
  ).default;

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }

  return language;
}

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(
      /{(\d+)}/g,
      function (match, number) {
        return typeof args[number] != 'undefined'
          ? args[number]
          : match;
      },
    );
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getAntdLanguage() {
  return getLanguage().antd;
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  // console.log('setLanguageCode', arg);
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  localStorage.setItem(
    'language',
    EncryptionTool.encrypt(arg),
  );
  // console.log('after localstorage', getLanguageCode());
}

export function i18nExists(key) {
  if (!getLanguage()) {
    return false;
  }

  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key, ...args) {
  if (!getLanguage()) {
    return key;
  }

  var remote = AuthCurrentTenant.getRemote();
  let currentWillMode = 'conventional';
  if (remote?.isRemote) {
    const tenant = remote.remoteTenant;
    if (tenant)
      currentWillMode =
        tenant.userSettingsWillMode ?? 'conventional';
  }

  // let currentWillMode =
  // localStorage.getItem('willMode') || 'conventional';

  let message;

  if (currentWillMode === 'wasiat') {
    message = _get(getLanguage().wasiat, key);
  }

  if (!message) {
    message = _get(getLanguage().dictionary, key);
  }

  if (!message) {
    message = _get(getLanguage().planInvitation, key);
  }

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function Get_EN_Code(key, ...args) {
  let message = _get(en_will, key);
  // console.log('message', message);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function Get_Wasiat_Code(key, ...args) {
  let message = _get(bm_wasiat, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function Get_Wasiat_I18n(key, ...args) {
  let language =
    localStorage.getItem('language') &&
    EncryptionTool.decrypt(
      localStorage.getItem('language'),
    );

  let message = '';

  if (language === 'bm') {
    message = _get(bm_wasiat, key);

    if (!message) {
      return key;
    }
  } else if (language === 'en') {
    message = _get(en_wasiat, key);

    if (!message) {
      return key;
    }
  } else if (language === 'cn') {
    message = _get(cn_wasiat, key);

    if (!message) {
      return key;
    }
  } else return '';

  return format(message, args);
}

export function i18nHtml(key, ...args) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  );
}

export function convertSettingsToLanguageCode(language) {
  switch (language) {
    case 'english':
      return 'en';
    case 'bahasaMelayu':
      return 'bm';
    case 'chinese':
      return 'cn';
  }
}

export function convertLanguageCodeToSettings(code) {
  switch (code) {
    case 'en':
      return 'english';
    case 'bm':
      return 'bahasaMelayu';
    case 'cn':
      return 'chinese';
  }
}
