import list from 'src/modules/beneficiary/list/beneficiaryListReducers';
import form from 'src/modules/beneficiary/form/beneficiaryFormReducers';
import view from 'src/modules/beneficiary/view/beneficiaryViewReducers';
import destroy from 'src/modules/beneficiary/destroy/beneficiaryDestroyReducers';
import importerReducer from 'src/modules/beneficiary/importer/beneficiaryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
