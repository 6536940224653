import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'astCategoryName',
    label: i18n('entities.assetCategory.fields.astCategoryName'),
    schema: schemas.string(
      i18n('entities.assetCategory.fields.astCategoryName'),
      {},
    ),
  },
  {
    name: 'astCategoryDescription',
    label: i18n('entities.assetCategory.fields.astCategoryDescription'),
    schema: schemas.string(
      i18n('entities.assetCategory.fields.astCategoryDescription'),
      {},
    ),
  },
];