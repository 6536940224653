import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'contactListPhoto',
    label: i18n(
      'entities.contactList.fields.contactListPhoto',
    ),
    schema: schemas.images(
      i18n('entities.contactList.fields.contactListPhoto'),
      {},
    ),
  },
  {
    name: 'contactListName',
    label: i18n(
      'entities.contactList.fields.contactListName',
    ),
    schema: schemas.string(
      i18n('entities.contactList.fields.contactListName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'contactListPhone',
    label: i18n(
      'entities.contactList.fields.contactListPhone',
    ),
    schema: schemas.string(
      i18n('entities.contactList.fields.contactListPhone'),
      {},
    ),
  },
  {
    name: 'contactListPhone2',
    label: i18n(
      'entities.contactList.fields.contactListPhone2',
    ),
    schema: schemas.string(
      i18n('entities.contactList.fields.contactListPhone2'),
      {},
    ),
  },
  {
    name: 'contactListEmail',
    label: i18n(
      'entities.contactList.fields.contactListEmail',
    ),
    schema: schemas.string(
      i18n('entities.contactList.fields.contactListEmail'),
      {},
    ),
  },
  {
    name: 'contactListAddress',
    label: i18n(
      'entities.contactList.fields.contactListAddress',
    ),
    schema: schemas.string(
      i18n(
        'entities.contactList.fields.contactListAddress',
      ),
      {},
    ),
  },
  {
    name: 'contactListRemarks',
    label: i18n(
      'entities.contactList.fields.contactListRemarks',
    ),
    schema: schemas.string(
      i18n(
        'entities.contactList.fields.contactListRemarks',
      ),
      {},
    ),
  },
];