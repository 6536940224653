import list from 'src/modules/contactBase/list/contactBaseListReducers';
import form from 'src/modules/contactBase/form/contactBaseFormReducers';
import view from 'src/modules/contactBase/view/contactBaseViewReducers';
import destroy from 'src/modules/contactBase/destroy/contactBaseDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
