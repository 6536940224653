import AppReleaseNoteService from 'src/modules/appReleaseNote/appReleaseNoteService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'APP_RELEASE_NOTE_VIEW';

const appReleaseNoteViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (filter) => async (dispatch) => {
    try {
      dispatch({
        type: appReleaseNoteViewActions.FIND_STARTED,
      });

      const record = await AppReleaseNoteService.list(filter);

      dispatch({
        type: appReleaseNoteViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: appReleaseNoteViewActions.FIND_ERROR,
      });
    }
  },
};

export default appReleaseNoteViewActions;
