import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/residuary/importer/residuaryImporterSelectors';
import ResiduaryService from 'src/modules/residuary/residuaryService';
import fields from 'src/modules/residuary/importer/residuaryImporterFields';
import { i18n } from 'src/i18n';

const residuaryImporterActions = importerActions(
  'RESIDUARY_IMPORTER',
  selectors,
  ResiduaryService.import,
  fields,
  i18n('entities.residuary.importer.fileName'),
);

export default residuaryImporterActions;