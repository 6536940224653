import list from 'src/modules/residuary/list/residuaryListReducers';
import form from 'src/modules/residuary/form/residuaryFormReducers';
import view from 'src/modules/residuary/view/residuaryViewReducers';
import destroy from 'src/modules/residuary/destroy/residuaryDestroyReducers';
import importerReducer from 'src/modules/residuary/importer/residuaryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
