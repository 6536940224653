const legalGuardianEnumerators = {
  legalGuardianIdentificationType: [
    'NRIC',
    'Old IC',
    'Passport',
    'Army/Police ID',
  ],
  legalGuardianSequence: [1, 2, 3, 4, 5, 6],
};

export default legalGuardianEnumerators;
