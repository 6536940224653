import ExporterSchema from 'src/modules/shared/exporter/exporterSchema';
import { Excel } from 'src/modules/shared/excel/excel';
import mapKeys from 'lodash/mapKeys';

export default class Exporter {
  schema;
  excelFileName;

  constructor(fields, excelFileName) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
  }

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => {
      const rowCasted = this.schema.cast(row);
      return this._makeNameHeadersIntoLabels(rowCasted);
    });

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      this.excelFileName + '_' + new Date().getTime(),
    );
  }

  _makeNameHeadersIntoLabels(row) {
    return mapKeys(row, (value, key) => {
      return this.schema.labelOf(key);
    });
  }


  transformAndExportMultipleAsFile(rows) {
    // console.log("rows", rows)

    var bufferList = [] as any;

    rows.forEach((item) => {

      var schemeField = new ExporterSchema(item.fields);

      const exportableData = item.data.map((row) => {
        const rowCasted = schemeField.cast(row);
        return this._makeNameHeadersIntoLabelsWithMultiple(rowCasted, schemeField);
      });

      item.data.forEach((row) => {

        Object.keys(row).forEach(async function (key) {
          if (key.toUpperCase().indexOf("FILE") >= 0 || key.toUpperCase().indexOf("PHOTO") >= 0) {

            if (row[key] !== null && row[key] !== undefined) {
              if (row[key].length > 0) {

                row[key].forEach(file => {

                  if (file.fileBufferArray !== undefined) {
                    // console.log(item.title, file)

                    var buffer = {
                      title: item.title,
                      type: key.toUpperCase().indexOf("FILE") >= 0 ? 'FILE' : 'PHOTO',
                      buffer: file.fileBufferArray,
                      name: file.name,
                    }

                    bufferList.push(buffer);
                  }
                })
              }
            }
          }
        });

      })


      item.data = exportableData;
      item.labels = schemeField.labels
      // item.bufferList = bufferList

    })

    // console.log("item", rows)

    return Excel.exportMultipleAsFile(
      rows,
      'koha_summary',
      bufferList,
    );

  }

  _makeNameHeadersIntoLabelsWithMultiple(row, schemaFields) {
    return mapKeys(row, (value, key) => {
      return schemaFields.labelOf(key);
    });
  }



}
