import PetService from 'src/modules/tasks/tasksService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'TASKS_VIEW';

const tasksViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tasksViewActions.FIND_STARTED,
      });

      // const record = await PetService.find(id);
      const record = await TenantService.tenant_Find(
        'Tasks',
        id,
        tenantId,
      );

      dispatch({
        type: tasksViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tasksViewActions.FIND_ERROR,
      });

      getHistory().push('/');
    }
  },
};

export default tasksViewActions;
