import list from 'src/modules/accessList/list/accessListReducers';
import form from 'src/modules/accessList/form/accessListFormReducers';
import view from 'src/modules/accessList/view/accessListViewReducers';
import destroy from 'src/modules/accessList/destroy/accessListDestroyReducers';
// import importerReducer from 'src/modules/accessList/importer/accessListImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  // importer: importerReducer,
});
