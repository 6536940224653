import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import ResiduaryService from 'src/modules/residuary/residuaryService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'RESIDUARY_FORM';

const residuaryFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: residuaryFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);
      if (isEdit) {
        record = await TenantService.tenant_List(
          'Residuary',
          null,
          null,
          null,
          null,
          tenantId,
        );
        record = record['rows'][0];
      }

      dispatch({
        type: residuaryFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryFormActions.INIT_ERROR,
      });

      getHistory().push('/willListPage');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: residuaryFormActions.CREATE_STARTED,
      });

      let rec = await ResiduaryService.create(values);

      dispatch({
        type: residuaryFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.residuary.create.success'),
        'success',
      );

      getHistory().push(`/residuary/${rec.id}/edit`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: residuaryFormActions.UPDATE_STARTED,
        });

        await ResiduaryService.update(id, values);

        dispatch({
          type: residuaryFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.residuary.update.success'),
          'success',
        );
        getHistory().push('/willListPage');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: residuaryFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default residuaryFormActions;
