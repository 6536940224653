import { i18n } from 'src/i18n';
// import FinancialPlanningFormPage from '../financialPlanning/form/FinancialPlanningFormPage';
// import FaraidCalculatorPage from '../faraidCalculator/page/FaraidCalculatorPage';
// import WillContentPage from './pages/WillContentPage';
// import FuneralDirectivesForm from '../funeralDirectives/FuneralDirectivesForm';
// import WorkspaceHome from './tab/WorkspaceHome';
// import WorkspaceEstateDocHub from './tab/WorkspaceEstateDocHub';
import { lazy } from 'react';

const FinancialPlanningFormPage = lazy(
  () =>
    import(
      '../financialPlanning/form/FinancialPlanningFormPage'
    ),
);
const FaraidCalculatorPage = lazy(
  () =>
    import('../faraidCalculator/page/FaraidCalculatorPage'),
);
const WillContentPage = lazy(
  () => import('./pages/WillContentPage'),
);
const WorkspaceHome = lazy(
  () => import('./tab/WorkspaceHome'),
);
const WorkspaceEstateDocHub = lazy(
  () => import('./tab/WorkspaceEstateDocHub'),
);
const FuneralDirectivesForm = lazy(
  () =>
    import('../funeralDirectives/FuneralDirectivesForm'),
);

export default function GenerateDefaultTab(
  platform,
  remoteTenant,
  remoteUser,
) {
  const tabs = [];

  const isWasiatWillMode = platform === 'wasiat';

  tabs.push({
    id: tabs.length + 1,
    label: i18n('common.home'),
    component: (
      <WorkspaceHome
        remoteTenant={remoteTenant}
        remoteUser={remoteUser}
      />
    ),
    closeable: false,
  });

  tabs.push({
    id: tabs.length + 1,
    label: i18n('entities.estateDocHub.menu'),
    component: (
      <WorkspaceEstateDocHub
        remoteTenant={remoteTenant}
        remoteUser={remoteUser}
      />
    ),
    closeable: false,
  });

  tabs.push({
    id: tabs.length + 1,
    label: `${i18n('entities.financialPlanning.menu')}`,
    component: (
      <FinancialPlanningFormPage
        workspace={true}
        platform={'Financial Planning'}
      />
    ),
    closeable: false,
  });

  // if (isWasiatWillMode) {
  //   tabs.push({
  //     id: tabs.length + 1,
  //     label: `WASIAT`,
  //     component: <WillContentPage platform={'Wasiat'} />,
  //     closeable: false,
  //   });

  //   tabs.push({
  //     id: tabs.length + 1,
  //     label: `HIBAH`,
  //     component: <WillContentPage platform={'Hibah'} />,
  //     closeable: false,
  //   });

  //   tabs.push({
  //     id: tabs.length + 1,
  //     label: `HARTA SEPENCARIAN`,
  //     component: (
  //       <WillContentPage platform={'Harta Sepencarian'} />
  //     ),
  //     closeable: false,
  //   });

  //   tabs.push({
  //     id: tabs.length + 1,
  //     label: `${i18n('entities.financialPlanning.menu')}`,
  //     component: (
  //       <FinancialPlanningFormPage
  //         workspace={true}
  //         platform={'Financial Planning'}
  //       />
  //     ),
  //     closeable: false,
  //   });

  //   tabs.push({
  //     id: tabs.length + 1,
  //     label: `${i18n('entities.faraidCalculator.menu')}`,
  //     component: (
  //       <FaraidCalculatorPage
  //         platform={'Faraid Calculator'}
  //       />
  //     ),
  //     closeable: false,
  //   });
  // } else {
  // tabs.push({
  //   id: tabs.length + 1,
  //   label: `WILL`,
  //   component: <WillContentPage platform={'Will'} />,
  //   closeable: false,
  // });

  // tabs.push({
  //   id: tabs.length + 1,
  //   label: `${i18n('entities.financialPlanning.menu')}`,
  //   component: (
  //     <FinancialPlanningFormPage
  //       workspace={true}
  //       platform={'Financial Planning'}
  //     />
  //   ),
  //   closeable: false,
  // });

  // tabs.push({
  //   id: tabs.length + 1,
  //   label: `${i18n('entities.funeralDirectives.menu')}`,
  //   component: (
  //     <>
  //       <FuneralDirectivesForm
  //         platform={'Funeral Directives'}
  //       />
  //     </>
  //   ),
  //   closeable: true,
  // });
  // }

  var jsonString =
    localStorage.getItem('activeTab') || null;

  if (jsonString !== null) {
    const hist_tab: {
      id?: string;
      tabs?: string[];
    } = JSON.parse(jsonString);

    hist_tab.tabs.forEach((o) => {
      var tab = GenerateTabByPageName(o);
      tabs.push({
        ...tab,
        id: tabs.length + 1,
      });
    });
  }

  return tabs;
}

export function GenerateTabByPageName(pageName) {
  if (pageName === 'Financial') {
    return {
      label: 'Financial',
      component: <>This is Add Test</>,
      closeable: true,
    };
  } else if (pageName === 'New Tab') {
    return {
      label: 'New Tab',
      component: (
        <FuneralDirectivesForm
          platform={'Funeral Directives'}
        />
      ),
      closeable: true,
    };
  }
  console.log('pageName', pageName);
  switch (pageName.toUpperCase()) {
    case 'WILL':
      return {
        label: `WILL`,
        component: <WillContentPage platform={'Will'} />,
        closeable: true,
      };
    case 'FUNERAL DIRECTIVES':
      return {
        label: i18n('entities.funeralDirectives.menu'),
        component: (
          <FuneralDirectivesForm
            platform={'Funeral Directives'}
          />
        ),
        closeable: true,
      };

    case 'WASIAT':
      return {
        label: `WASIAT`,
        component: <WillContentPage platform={'Wasiat'} />,
        closeable: true,
      };

    case 'HIBAH':
      return {
        label: `HIBAH`,
        component: <WillContentPage platform={'Hibah'} />,
        closeable: true,
      };

    case 'HARTA SEPENCARIAN':
      return {
        label: `HARTA SEPENCARIAN`,
        component: (
          <WillContentPage platform={'Harta Sepencarian'} />
        ),
        closeable: true,
      };

    case 'FARAID CALCULATOR':
      return {
        label: `${i18n('entities.faraidCalculator.menu')}`,
        component: (
          <FaraidCalculatorPage
            platform={'Faraid Calculator'}
          />
        ),
        closeable: true,
      };
  }
}
