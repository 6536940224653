import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trustee/importer/trusteeImporterSelectors';
import TrusteeService from 'src/modules/trustee/trusteeService';
import fields from 'src/modules/trustee/importer/trusteeImporterFields';
import { i18n } from 'src/i18n';

const trusteeImporterActions = importerActions(
  'TRUSTEE_IMPORTER',
  selectors,
  TrusteeService.import,
  fields,
  i18n('entities.trustee.importer.fileName'),
);

export default trusteeImporterActions;