const beneficiaryEnumerators = {
  benIdentificationType: [
    'NRIC',
    'Old IC',
    'Passport',
    'Army/Police ID',
  ],
  benRelationship: [
    'Fiance',
    'Spouse',
    'Partner',
    'Parent',
    'GrandParent',
    'GreatGrandParent',
    'Child',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    'Relative',
    'Friend',
    'Charity',
    //'Cohabitee',
    //'UnmarriedPartner',
    //'SpouseParent',
    // 'ChildPrevious',
    // 'SpouseChildPrevious',
    // 'Solicitor',
    // 'NominatedSolicitor',
  ],
  familyMember: [
    'Fiance',
    'Spouse',
    'Parent',
    'GrandParent',
    'GreatGrandParent',
    'Child',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    'Relative',
    //'Cohabitee',
    //'UnmarriedPartner',
    //'SpouseParent',
    // 'ChildPrevious',
    // 'SpouseChildPrevious',
    // 'Solicitor',
    // 'NominatedSolicitor',
    'husband',
    'wife',
    'father',
    'mother',
    'son',
    'daughter',
    'paternalGrandfather',
    'paternalGrandmother',
    'maternalGrandfather',
    'maternalGrandmother',
    'grandson',
    'granddaughter',
    'fullBrother',
    'consanguineBrother',
    'uterineBrother',
    'fullSister',
    'consanguineSister',
    'uterineSister',
    'fullBrotherSon',
    'consanguineBrotherSon',
    'fullPaternalUncle',
    'consanguinePaternalUncle',
    'fullPaternalUncleSon',
    'consanguinePaternalUncleSon',
  ],
};

export default beneficiaryEnumerators;
