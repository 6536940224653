import ContactListService from 'src/modules/contactList/contactListService';
import selectors from 'src/modules/contactList/list/contactListListSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/contactList/list/contactListListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'CONTACTLIST_LIST';

const contactListListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doChangeSelected(payload) {
    return {
      type: contactListListActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: contactListListActions.RESETED,
    });

    dispatch(contactListListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: contactListListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await ContactListService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('entities.contactList.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: contactListListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePaginationAndSort: (pagination, sorter) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: contactListListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: contactListListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(contactListListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(contactListListActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: contactListListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      // const response = await ContactListService.list(
      //   filter,
      //   selectors.selectOrderBy(getState()),
      //   selectors.selectLimit(getState()),
      //   selectors.selectOffset(getState()),
      // );

      const response = await TenantService.listContact(
        {excludeRole: 'Witness'},
        null,
        null,
        null,
        tenantId
      );

      dispatch({
        type: contactListListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListListActions.FETCH_ERROR,
      });
    }
  },

  doFetchVideoList: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: contactListListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ContactListService.listVideo(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: contactListListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contactListListActions.FETCH_ERROR,
      });
    }
  },
};

export default contactListListActions;
