import list from 'src/modules/pledge/list/pledgeListReducers';
import form from 'src/modules/pledge/form/pledgeFormReducers';
import view from 'src/modules/pledge/view/pledgeViewReducers';
import destroy from 'src/modules/pledge/destroy/pledgeDestroyReducers';
import importerReducer from 'src/modules/pledge/importer/pledgeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
