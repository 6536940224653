import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/executor/importer/executorImporterSelectors';
import ExecutorService from 'src/modules/executor/executorService';
import fields from 'src/modules/executor/importer/executorImporterFields';
import { i18n } from 'src/i18n';

const executorImporterActions = importerActions(
  'EXECUTOR_IMPORTER',
  selectors,
  ExecutorService.import,
  fields,
  i18n('entities.executor.importer.fileName'),
);

export default executorImporterActions;