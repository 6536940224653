import list from 'src/modules/resources/list/resourcesListReducers';
import form from 'src/modules/resources/form/resourcesFormReducers';
import view from 'src/modules/resources/view/resourcesViewReducers';
import destroy from 'src/modules/resources/destroy/resourcesDestroyReducers';
import importerReducer from 'src/modules/resources/importer/resourcesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
