import ResiduaryService from 'src/modules/residuary/residuaryService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'RESIDUARY_VIEW';

const residuaryViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: residuaryViewActions.FIND_STARTED,
      });

      const record = await ResiduaryService.find(id);

      dispatch({
        type: residuaryViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: residuaryViewActions.FIND_ERROR,
      });

      getHistory().push('/residuary');
    }
  },
};

export default residuaryViewActions;
