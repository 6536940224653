import HeirService from 'src/modules/heir/heirService';
import selectors from 'src/modules/heir/list/heirListSelectors';
// import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'HEIR_LIST';

const heirListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doChangeSelected(payload) {
    return {
      type: heirListActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: heirListActions.RESETED,
    });

    dispatch(heirListActions.doFetch());
  },

  doChangePaginationAndSort:
    (pagination, sorter) => async (dispatch, getState) => {
      dispatch({
        type: heirListActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch({
        type: heirListActions.SORTER_CHANGED,
        payload: sorter,
      });

      dispatch(heirListActions.doFetchCurrentFilter());
    },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        heirListActions.doFetch(filter, rawFilter, true),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: heirListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await HeirService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
        );

        dispatch({
          type: heirListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: heirListActions.FETCH_ERROR,
        });
      }
    },
};

export default heirListActions;
