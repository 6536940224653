import list from 'src/modules/witness/list/witnessListReducers';
import main from 'src/modules/witness/main/witnessMainReducers';
import form from 'src/modules/witness/form/witnessFormReducers';
import view from 'src/modules/witness/view/witnessViewReducers';
import destroy from 'src/modules/witness/destroy/witnessDestroyReducers';
import importerReducer from 'src/modules/witness/importer/witnessImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  main,
  form,
  view,
  destroy,
  importer: importerReducer,
});
