import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class accessListService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/accessList/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/accessList`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/accessList`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/accessList/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocompleteFilter(
    query,
    limit,
    filter,
    cur_val,
  ) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList/autocomplete`,
      {
        params,
      },
    );

    var result = [];
    var filter_result = [];

    if (filter === 'Residuary') {
      const filter_data = await authAxios.get(
        `/tenant/${tenantId}/residuary`,
        {
          params,
        },
      );

      filter_data.data.rows.forEach((item) => {
        if (item.resBen !== null)
          if (cur_val !== item.resBen.benName) {
            filter_result.push(item.resBen.benName);
          }
      });
    }

    response.data.forEach((item) => {
      if (!this.Check_IsExists(filter_result, item.label)) {
        result.push(item);
      } else {
      }
    });

    return result;
  }

  static Check_IsExists = (list, comparer) => {
    var result = false;

    list.forEach((item) => {
      if (item === comparer) {
        result = true;
      }
    });

    return result;
  };

  static async listAll(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList-all`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAllRemote(filter, orderBy, limit, offset,tenantId) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(
      `/tenant/${tenantId}/accessList-all`,
      {
        params,
      },
    );

    return response.data;
  }
}
