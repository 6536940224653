import list from 'src/modules/emergencyCardOrder/list/emergencyCardOrderListReducers';
import form from 'src/modules/emergencyCardOrder/form/emergencyCardOrderFormReducers';
import view from 'src/modules/emergencyCardOrder/view/emergencyCardOrderViewReducers';
import destroy from 'src/modules/emergencyCardOrder/destroy/emergencyCardOrderDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
