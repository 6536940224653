const propertyEnumerators = {
  propType: ['Residential', 'Commercial', 'Land'],
  propDesc: [
    'Bungalow',
    'SemiDetached',
    'Terrace',
    'Townhouse',
    'Condominium',
    'Apartment',
    'Shophouse',
    'Office',
    'Retail',
    'HotelResort',
    'Factory',
    'Warehouse',
    'AgriculturalLand',
    'IndustrialLand',
    'CommercialLand',
    'ResidentialLand',
  ],
  propDescResidential: [
    'Bungalow',
    'SemiDetached',
    'Terrace',
    'Townhouse',
    'Condominium',
    'Apartment',
    'Shophouse',
  ],
  propTypeOfLandTitle: ['Strata', 'Individual', 'Master'],
  propDescCommercial: [
    'Office',
    'Retail',
    'HotelResort',
    'Factory',
    'Warehouse',
    'ShopLot'
  ],
  propDescLand: [
    'AgriculturalLand',
    'IndustrialLand',
    'CommercialLand',
    'ResidentialLand',
  ],
  propOwnershipType: ['SoleOwnership', 'JointlyTenancy'],
  propDistribution: ['HARTASEPENCARIAN', 'HIBAH', 'WASIAT'],
};

export default propertyEnumerators;
