import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'witnessPhoto',
    label: i18n(
      'entities.witness.fields.witnessPhoto',
    ),
    schema: schemas.images(
      i18n('entities.witness.fields.witnessPhoto'),
      {},
    ),
  },
  {
    name: 'witnessName',
    label: i18n(
      'entities.witness.fields.witnessName',
    ),
    schema: schemas.string(
      i18n('entities.witness.fields.witnessName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'witnessPhone',
    label: i18n(
      'entities.witness.fields.witnessPhone',
    ),
    schema: schemas.string(
      i18n('entities.witness.fields.witnessPhone'),
      {},
    ),
  },
  {
    name: 'witnessPhone2',
    label: i18n(
      'entities.witness.fields.witnessPhone2',
    ),
    schema: schemas.string(
      i18n('entities.witness.fields.witnessPhone2'),
      {},
    ),
  },
  {
    name: 'witnessEmail',
    label: i18n(
      'entities.witness.fields.witnessEmail',
    ),
    schema: schemas.string(
      i18n('entities.witness.fields.witnessEmail'),
      {},
    ),
  },
  {
    name: 'witnessAddress',
    label: i18n(
      'entities.witness.fields.witnessAddress',
    ),
    schema: schemas.string(
      i18n(
        'entities.witness.fields.witnessAddress',
      ),
      {},
    ),
  },
  {
    name: 'witnessRemarks',
    label: i18n(
      'entities.witness.fields.witnessRemarks',
    ),
    schema: schemas.string(
      i18n(
        'entities.witness.fields.witnessRemarks',
      ),
      {},
    ),
  },
];