import { getHistory } from "src/modules/store";

export default [
    {
        selector: '[data-tut="reactour__step1"]',
        content: ({ goTo }) => (
          <div>
            <div>
              Hi, Welcome to Koha Digital
            </div>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => goTo(1)}
            >
              Next
            </button>
          </div>
        )
    },
    {
        selector: '[data-tut="reactour__step2"]',
        content: ({ goTo }) => (
          <div>
            <div>
            You can add new client by clicking here
            </div>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => goTo(2)}
            >
              Next
            </button>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => goTo(0)}
            >
              Back
            </button>
          </div>
        )
    },
    {
        selector: '[data-tour="reactour__step3"]',
        content: ({ goTo }) => (
          <div>
            <div>
            You can add new task by clicking here
            </div>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => goTo(3)}
            >
              Next
            </button>
          </div>
        )
    },
    {
        selector: '[data-tut="reactour__step4"]',
        content: ({ goTo }) => (
          <div>
            <div>
            You can manage your customers by clicking here
            </div>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => {
                getHistory().push({
                  pathname: '/customers',
                  state: { step: 4, open_tour: true }
                });
              }}
            >
              Next
            </button>
          </div>
        )
    },
    {
        selector: '[data-tut="reactour__step5"]',
        content: ({ goTo }) => (
          <div>
            <div>
            You can also remote your customers by clicking their name
            </div>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => {
                goTo(5);
                // getHistory().push({
                //   pathname: '/customers',
                //   state: { step: 4 }
                // });
              }}
            >
              Next
            </button>
            <button
              style={{
                  // position: 'relative',
                  // top: '100px',
                  // left: '100px',
                border: "1px solid #f7f7f7",
                background: "none",
                padding: ".3em .7em",
                fontSize: "inherit",
                display: "block",
                cursor: "pointer",
                margin: "1em auto"
              }}
              onClick={() => {
                // goTo(5);
                getHistory().push({
                  pathname: '/',
                  state: { step: 3, open_tour: true }
                });
              }}
            >
              Back
            </button>
          </div>
        )
    },
    {
      selector: '[data-tut="reactour__step6"]',
      content: ({ goTo, close }) => (
        <div>
          <div>
          Here is your customers view
          </div>
          <button
            style={{
                // position: 'relative',
                // top: '100px',
                // left: '100px',
              border: "1px solid #f7f7f7",
              background: "none",
              padding: ".3em .7em",
              fontSize: "inherit",
              display: "block",
              cursor: "pointer",
              margin: "1em auto"
            }}
            onClick={() => {
                // goTo(5);
                getHistory().push({
                    pathname: '/',
                    state: { open_tour: false }
                  });
                // getHistory().push({
                //   pathname: '/customers',
                //   state: { step: 4 }
                // });
              }}
          >
            Done
          </button>
        </div>
      )
  },
]