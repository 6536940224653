const accountEnumerators = {
  accType: [
    'Savings',
    'Investment',
    // 'Insurance/Takaful',
    'RegularSavingFunds',
    'Others',
  ],
};

export default accountEnumerators;
