import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import user from 'src/modules/user/userReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import { combineReducers } from 'redux';
import userSettings from 'src/modules/userSettings/userSettingsReducers';
import advertisement from 'src/modules/advertisement/advertisementReducers';
import notification from 'src/modules/notification/notificationReducers';
import resources from 'src/modules/resources/resourcesReducers';
import paymentHistory from 'src/modules/paymentHistory/paymentHistoryReducers';
import wallet from 'src/modules/wallet/walletReducers';
import customerBase from 'src/modules/customerBase/customerBaseReducers';
import global from 'src/modules/global/globalReducers';
import beneficiary from 'src/modules/beneficiary/beneficiaryReducers';
import account from 'src/modules/account/accountReducers';
import accessList from 'src/modules/accessList/accessListReducers';
import contactList from 'src/modules/contactList/contactListReducers';
import guardians from 'src/modules/guardians/guardiansReducers';
import lastInstructions from 'src/modules/lastInstructions/lastInstructionsReducers';
import otherAsset from 'src/modules/otherAsset/otherAssetReducers';
import property from 'src/modules/property/propertyReducers';
import residuary from 'src/modules/residuary/residuaryReducers';
import location from 'src/modules/location/locationReducers';
import insurance from 'src/modules/insurance/insuranceReducers';
import debt from 'src/modules/debt/debtReducers';
import vehicle from 'src/modules/vehicle/vehicleReducers';
import executor from 'src/modules/executor/executorReducers';
import trustee from 'src/modules/trustee/trusteeReducers';
import assetCategory from 'src/modules/assetCategory/assetCategoryReducers';
import digital from 'src/modules/digital/digitalReducers';
import pledge from 'src/modules/pledge/pledgeReducers';
import healthInfo from 'src/modules/healthInfo/healthInfoReducers';
import emergencyCardOrder from 'src/modules/emergencyCardOrder/emergencyCardOrderReducers';
import legalGuardian from 'src/modules/legalGuardian/legalGuardianReducers';
import witness from 'src/modules/witness/witnessReducers';
import planInvitation from 'src/modules/planInvitation/planInvitationReducers';
import pet from 'src/modules/pet/petReducers';
import assistant from 'src/modules/assistant/assistantReducers';
import contactBase from 'src/modules/contactBase/contactBaseReducers';
import financialPlanning from 'src/modules/financialPlanning/financialPlanningReducers';
import heir from 'src/modules/heir/heirReducers';
import specificGift from 'src/modules/specificGift/specificGiftReducers';
import couponGenerator from 'src/modules/couponGenerator/couponGeneratorReducers';
import tasks from 'src/modules/tasks/tasksReducers';
import appReleaseNote from 'src/modules/appReleaseNote/appReleaseNoteReducers';
import testamentaryTrust from 'src/modules/testamentaryTrust/testamentaryTrustReducers'
import dependent from 'src/modules/dependent/dependentReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    user,
    tenant,
    auditLog,
    settings,
    userSettings,
    advertisement,
    notification,
    resources,
    paymentHistory,
    wallet,
    customerBase,

    beneficiary,
    account,
    accessList,
    contactList,
    guardians,
    lastInstructions,
    otherAsset,
    property,
    residuary,
    location,
    insurance,
    debt,
    vehicle,
    executor,
    trustee,
    assetCategory,
    digital,
    pledge,
    healthInfo,
    emergencyCardOrder,
    legalGuardian,
    witness,
    planInvitation,
    pet,
    assistant,
    contactBase,
    financialPlanning,
    heir,
    specificGift,
    couponGenerator,
    tasks,
    appReleaseNote,
    global,
    testamentaryTrust,
    dependent,
  });
