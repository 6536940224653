import list from 'src/modules/otherAsset/list/otherAssetListReducers';
import form from 'src/modules/otherAsset/form/otherAssetFormReducers';
import view from 'src/modules/otherAsset/view/otherAssetViewReducers';
import destroy from 'src/modules/otherAsset/destroy/otherAssetDestroyReducers';
import importerReducer from 'src/modules/otherAsset/importer/otherAssetImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
