import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pet.fields.id'),
  },
  {
    name: 'photo',
    label: i18n('entities.pet.fields.photo'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'name',
    label: i18n('entities.pet.fields.name'),
  },
  {
    name: 'remarks',
    label: i18n('entities.pet.fields.remarks'),
  },
  {
    name: 'primaryGuardian',
    label: i18n('entities.pet.fields.primaryGuardian'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'backupGuardian',
    label: i18n('entities.pet.fields.backupGuardian'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.pet.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.pet.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
