import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  ChevronRightOutlined,
  InboxOutlined,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import authAxios from 'src/modules/shared/axios/authAxios';
import { getHistory } from 'src/modules/store';
import UserService from 'src/modules/user/userService';
import { TUser } from 'src/types';
import { shortenTextToNLetters } from 'src/utils/functions';
import TrusteeClientModal from 'src/view/user/view2/TrusteeClientModal';
import {
  HorizontalListContainer,
  ModalContainer,
} from './CommandPalette.styled';

const CardRadio = ({ value, label, selected }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: selected ? '#0984e3' : 'white',
        backgroundColor: '#f2f2f2',
        borderWidth: 1,
      }}
      onClick={() =>
        (
          document.querySelector(`input[value="${value}"]`)
            ?.parentElement as HTMLInputElement
        )?.click()
      }
    >
      <FormControlLabel
        value={value}
        control={<Radio sx={{ display: 'none' }} />}
        label={
          <CardContent>
            <Typography variant="body2">{label}</Typography>
          </CardContent>
        }
        sx={{
          width: '100%',
          margin: 0,
          height: 30,
          paddingTop: 1,
          color: selected ? '#0984e3' : 'black',
        }}
      />
    </Card>
  );
};

interface ICommandPalette {
  open: boolean;
  handleClose: () => void;
}

const CommandPalette = ({
  open,
  handleClose,
}: ICommandPalette) => {
  const [isLoading, setIsLoading] =
    useState<boolean>(false);
  const [trusteeClientRow, setTrusteeClientRow] =
    useState<any>();
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedRole, setSelectedRole] = useState('all');
  const [searchResults, setSearchResults] =
    useState<TUser[]>();
  const [favoriteClient, setFavoriteClient] =
    useState<any[]>();

  const [
    isTrusteeClientModalOpen,
    setIsTrusteeClientModalOpen,
  ] = useState<boolean>(false);

  const [
    totalCountSearchResults,
    setTotalCountSearchResults,
  ] = useState<number>(0);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const isTrustee = currentUser?.userRole === 'trustee';

  const searchForUser = async (
    payload: string,
    role: string,
  ) => {
    try {
      setIsLoading(true);
      if (!payload || payload === '') {
        setSearchResults(undefined);
        return setIsLoading(false);
      }
      let result;

      if (isTrustee) {
        result =
          await UserService.fetchAllTrusteeClientFilter(
            {
              clientName: payload,
            },
            'createdAt_DESC',
            10,
            0,
          );
      } else {
        result = await UserService.fetchAllUserFilter(
          {
            input: payload,
            role: role,
          },
          'createdAt_DESC',
          10,
          0,
        );
      }

      if (result && result?.rows) {
        setSearchResults(
          result.rows.length > 0 ? result.rows : undefined,
        );
        setTotalCountSearchResults(
          result.rows.length > 0 ? result?.count ?? 0 : 0,
        );
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const navigateToUserProfile = ({
    remoteTenantId,
    contactBaseId,
    convertedToUser,
    row,
  }: {
    remoteTenantId: string;
    contactBaseId: string;
    convertedToUser: boolean;
    row: any;
  }) => {
    if (isTrustee) {
      setTrusteeClientRow(row);
      setIsTrusteeClientModalOpen(true);
    } else {
      if (!convertedToUser && contactBaseId?.length > 0) {
        getHistory().push(
          `/prospect/user/new-invitation/${contactBaseId}`,
        );
      }

      if (convertedToUser && remoteTenantId?.length > 0) {
        getHistory().push(
          `/share-with-me/${remoteTenantId}`,
        );
        handleClose();
      }
    }
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const removeFavoriteUsers = async (users: string[]) => {
    try {
      const params = {
        users, // array of users id's
        tenantId: AuthCurrentTenant.get(),
      };

      const tenantId = AuthCurrentTenant.get();

      const response = await authAxios.post(
        `/tenant/${tenantId}/favorite-users/remove`,
        {
          ...params,
        },
      );
      if (response?.data?.data?.value) {
        setFavoriteClient(response.data.data.value);
      }
    } catch (error) {
      console.log('🚀 ~ getFav ~ error:', error);
    }
  };

  const addFavoriteUsers = async (users: string[]) => {
    try {
      const params = {
        users, // array of users id's
        tenantId: AuthCurrentTenant.get(),
      };

      const tenantId = AuthCurrentTenant.get();

      const response = await authAxios.post(
        `/tenant/${tenantId}/favorite-users/add`,
        {
          ...params,
        },
      );
      if (response?.data?.data?.value) {
        setFavoriteClient(response.data.data.value);
      }
    } catch (error) {
      console.log('🚀 ~ ', { error });
    }
  };

  const handleAddRemoveFavoriteUsers = (userId: string) => {
    if (
      favoriteClient &&
      favoriteClient.some(
        (user) => user._id === userId || user.id === userId,
      )
    ) {
      removeFavoriteUsers([userId]);
    } else {
      addFavoriteUsers([userId]);
    }
  };

  const debouncedChangeHandler = useRef(
    _.debounce(searchForUser, 1000),
  ).current;

  useEffect(() => {
    debouncedChangeHandler(searchValue, selectedRole);
  }, [searchValue, debouncedChangeHandler, selectedRole]);

  useEffect(() => {
    if (!searchValue || searchValue === '') {
      setSearchResults(undefined);
    }
  }, [searchValue]);

  const getFavoriteUsers = async () => {
    try {
      const params = {
        filter: null,
        orderBy: null,
        limit: 10,
        offset: 0,
      };

      const tenantId = AuthCurrentTenant.get();

      const response = await authAxios.get(
        `/tenant/${tenantId}/favorite-users/list`,
        {
          params,
        },
      );
      if (response?.data) {
        setFavoriteClient(
          response?.data?.data?.value ?? null,
        );
      }
    } catch (error) {
      console.log('🚀 ~ getFav ~ error:', error);
    }
  };
  useEffect(() => {
    getFavoriteUsers();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="command-palette-title"
      aria-describedby="command-palette-description"
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <ModalContainer>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            width: '100%',
            zIndex: 100,
          }}
        >
          <InputBase
            placeholder={i18n(
              'common.findCustomerByFilters',
            )}
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={
              <Typography color={'#a8a8a8'}>esc</Typography>
            }
            startAdornment={
              <SearchIcon
                sx={{ mr: 1, color: '#ffffff' }}
              />
            }
            fullWidth
            sx={{
              padding: '1em',
              color: '#f4faff',
              bgcolor: '#2c3e50',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            autoFocus
            onChange={handleChange}
          />
          {isLoading && <LinearProgress />}
          <Divider />
          <Box mx={2} bgcolor="white" pt={1}>
            {favoriteClient &&
              favoriteClient.length > 0 && (
                <Fragment>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={{ fontWeight: 500 }}
                  >
                    {i18n('common.bookmarkedClients')}
                  </FormLabel>
                  <HorizontalListContainer
                    mb={3}
                    mt={1}
                    gap={1}
                  >
                    {favoriteClient?.map((customer) => (
                      <Box
                        key={customer?._id ?? customer?.id}
                        sx={{
                          pb: 2,
                          pt: 1,
                          pl: 2,
                          pr: 1,
                          bgcolor: 'grey.50',
                          border: '0.5px solid #bfbfbf',
                          borderRadius: 2,
                          cursor: 'pointer',
                          minWidth: '200px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          onClick={() =>
                            navigateToUserProfile({
                              remoteTenantId:
                                customer?.tenants?.[0]
                                  .tenant ?? undefined,
                              convertedToUser: true,
                              contactBaseId: undefined,
                              row: customer,
                            })
                          }
                          width="100%"
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight={500}
                          >
                            {shortenTextToNLetters(
                              customer?.fullName ?? '',
                              14,
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {shortenTextToNLetters(
                              customer?.email ?? '',
                              20,
                            )}
                          </Typography>
                        </Box>

                        <BookmarkOutlined
                          onClick={() =>
                            removeFavoriteUsers([
                              customer?.id ?? customer?._id,
                            ])
                          }
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[500],
                          }}
                        />
                      </Box>
                    ))}
                  </HorizontalListContainer>
                </Fragment>
              )}

            {!isTrustee && (
              <Fragment>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ fontWeight: 500 }}
                >
                  {i18n('common.userType')}
                </FormLabel>
                <RadioGroup
                  value={selectedRole}
                  onChange={handleRoleChange}
                  sx={{ mt: 1 }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                  >
                    <CardRadio
                      value="all"
                      label={i18n('common.all')}
                      selected={selectedRole === 'all'}
                    />
                    <CardRadio
                      value="prospect"
                      label={i18n('common.prospect')}
                      selected={selectedRole === 'prospect'}
                    />
                    <CardRadio
                      value="client"
                      label={i18n('common.client')}
                      selected={selectedRole === 'client'}
                    />
                  </Box>
                </RadioGroup>
              </Fragment>
            )}
            <Box mt={2}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ fontWeight: 500 }}
              >
                {`${
                  searchValue
                    ? totalCountSearchResults ?? 0
                    : 0
                } ${i18n('common.found')}`}
              </FormLabel>
            </Box>
          </Box>
        </Box>
        <Box m={2}>
          {searchResults ? (
            <List
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              {searchResults?.map((user) => (
                <ListItem
                  key={user.user_id}
                  alignItems="flex-start"
                  sx={{
                    backgroundColor: '#f2f2f2',
                    width: '100%',
                    borderRadius: '5px',
                    mb: 1,
                    cursor: 'pointer',
                    p: 1,
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handleAddRemoveFavoriteUsers(
                        user?.remote_user_id ?? '',
                      )
                    }
                  >
                    {favoriteClient &&
                    favoriteClient.some(
                      (item) =>
                        item._id === user?.remote_user_id ||
                        item.id === user?.remote_user_id,
                    ) ? (
                      <BookmarkOutlined />
                    ) : (
                      <BookmarkBorderOutlined />
                    )}
                  </IconButton>
                  <Box
                    display="flex"
                    flexDirection="row"
                    onClick={() =>
                      navigateToUserProfile({
                        remoteTenantId:
                          user?.remote_tenant_id ??
                          undefined,
                        convertedToUser:
                          user?.convertedToUser ?? false,
                        contactBaseId:
                          user?.contact_base_id ??
                          undefined,
                        row: user,
                      })
                    }
                    ml={1}
                    width="100%"
                  >
                    <ListItemText
                      primary={user?.fullName ?? ''}
                      secondary={`${
                        user.email ?? 'No email'
                      }\n${user?.idType ?? 'ID'} - ${
                        user?.idNo ?? 'Not provided'
                      }`}
                      sx={{ whiteSpace: 'pre-wrap' }}
                    />
                    <ListItemSecondaryAction>
                      {user?.plan ? (
                        <Chip
                          variant="outlined"
                          color={
                            new Date() >
                            new Date(user.planExpiresDate)
                              ? 'error'
                              : 'success'
                          }
                          size="small"
                          label={`${
                            (user?.plan ?? '') === 'free'
                              ? i18n('common.unpaid')
                              : user?.plan ?? ''
                          }`}
                        />
                      ) : (
                        <Button
                          endIcon={<ChevronRightOutlined />}
                          onClick={() =>
                            navigateToUserProfile({
                              remoteTenantId:
                                user?.remote_tenant_id ??
                                undefined,
                              convertedToUser:
                                user?.convertedToUser ??
                                false,
                              contactBaseId:
                                user?.contact_base_id ??
                                undefined,
                              row: user,
                            })
                          }
                        >
                          {i18n(
                            'entities.contactBase.convertClient',
                          )}
                        </Button>
                      )}
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="100px"
              flexDirection="column"
            >
              <InboxOutlined sx={{ color: '#a8a8a8' }} />
              <Typography
                variant="body2"
                mt={1}
                color={'#a8a8a8'}
              >
                {i18n('common.searchResult')}
              </Typography>
            </Box>
          )}
        </Box>
        <TrusteeClientModal
          clientList={[]}
          open={isTrusteeClientModalOpen}
          edit={true}
          onClose={() => setIsTrusteeClientModalOpen(false)}
          doUpdateTask={() => {}}
          setIsLoading={setIsLoading}
          trusteeClientRow={trusteeClientRow}
        />
      </ModalContainer>
    </Modal>
  );
};

export default CommandPalette;
