import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import pledgeEnumerators from 'src/modules/pledge/pledgeEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'orgCategory',
    //label: i18n('entities.pledge.fields.orgCategory'),
	label: 'entities.pledge.fields.orgCategory',
    //
	schema: schemas.generic(pledgeEnumerators.orgCategory,),
	/*
	schema: schemas.enumerator(
	
      //i18n('entities.pledge.fields.orgCategory'),
      {
        required: true,
        options: pledgeEnumerators.orgCategory,
      },
    ),
	*/
  },
  {
    name: 'orgName',
    label: i18n('entities.pledge.fields.orgName'),
    schema: schemas.enumerator(
      i18n('entities.pledge.fields.orgName'),
      {
        required: true,
      },
    ),
  },
  
  {
    name: 'link',
    label: i18n('entities.pledge.fields.link'),
    schema: schemas.string(
      i18n('entities.pledge.fields.string'),
      {
        required: false,
      },
    ),
  },
  
  {
    name: 'orgAccountNo',
    label: i18n('entities.pledge.fields.orgAccountNo'),
    schema: schemas.string(
      i18n('entities.pledge.fields.orgAccountNo'),
      {},
    ),
  },
  {
    name: 'anonymousMode',
    label: i18n('entities.pledge.fields.anonymousMode'),
    schema: schemas.boolean(
      i18n('entities.pledge.fields.anonymousMode'),
      {},
    ),
  },
  {
    name: 'currency',
    label: i18n('entities.pledge.fields.currency'),
    schema: schemas.enumerator(
      i18n('entities.pledge.fields.currency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'amount',
    label: i18n('entities.pledge.fields.amount'),
    schema: schemas.decimal(
      i18n('entities.pledge.fields.amount'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'remarks',
    label: i18n('entities.pledge.fields.remarks'),
    schema: schemas.string(
      i18n('entities.pledge.fields.remarks'),
      {},
    ),
  },
];
