import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.resources.fields.id'),
  },
  {
    name: 'resourcesPhoto',
    label: i18n('entities.resources.fields.resourcesPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'resourcesName',
    label: i18n('entities.resources.fields.resourcesName'),
  },
  {
    name: 'resourcesPhone',
    label: i18n('entities.resources.fields.resourcesPhone'),
  },
  {
    name: 'resourcesPhone2',
    label: i18n('entities.resources.fields.resourcesPhone2'),
  },
  {
    name: 'resourcesEmail',
    label: i18n('entities.resources.fields.resourcesEmail'),
  },
  {
    name: 'resourcesAddress',
    label: i18n('entities.resources.fields.resourcesAddress'),
  },
  {
    name: 'resourcesRemarks',
    label: i18n('entities.resources.fields.resourcesRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.resources.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.resources.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
