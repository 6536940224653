import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/pledge/importer/pledgeImporterSelectors';
import PledgeService from 'src/modules/pledge/pledgeService';
import fields from 'src/modules/pledge/importer/pledgeImporterFields';
import { i18n } from 'src/i18n';

const pledgeImporterActions = importerActions(
  'PLEDGE_IMPORTER',
  selectors,
  PledgeService.import,
  fields,
  i18n('entities.pledge.importer.fileName'),
);

export default pledgeImporterActions;