import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.otherAsset.fields.id'),
  },
  {
    name: 'othCategory',
    label: i18n('entities.otherAsset.fields.othCategory'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'othDescription',
    label: i18n('entities.otherAsset.fields.othDescription'),
  },
  {
    name: 'othLocation',
    label: i18n('entities.otherAsset.fields.othLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'othRemarks',
    label: i18n('entities.otherAsset.fields.othRemarks'),
  },
  {
    name: 'othValue',
    label: i18n('entities.otherAsset.fields.othValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'othCurrency',
    label: i18n('entities.otherAsset.fields.othCurrency'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'othBen',
    label: i18n('entities.otherAsset.fields.othBen'),
    render: exporterRenders.json(),
  },
  {
    name: 'othFile',
    label: i18n('entities.otherAsset.fields.othFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'othPhoto',
    label: i18n('entities.otherAsset.fields.othPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.otherAsset.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.otherAsset.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
