import actions from './workspacePageActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  record: null,
  activeTab: 1,
  openedTabs: [] as any,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_TAB_STARTED) {
    var active = Number(
      localStorage.getItem('currentActiveTab') || 1,
    );

    return {
      ...state,
      record: null,
      initLoading: true,
      activeTab: active,
      openedTabs: [active.toString()],
    };
  }

  if (type === actions.INIT_TAB_ERROR) {
    localStorage.setItem('currentActiveTab', '1');

    return {
      ...state,
      record: null,
      initLoading: false,
      activeTab: 1,
      openedTabs: ['1'],
    };
  }

  if (type === actions.CREATE_TAB_STARTED) {
    return {
      ...state,
      record: null,
      initLoading: true,
    };
  }

  if (type === actions.CREATE_TAB_SUCCESS) {
    var active = Number(
      localStorage.getItem('currentActiveTab'),
    );

    return {
      ...state,
      record: payload,
      initLoading: false,
      activeTab: active,
      openedTabs: [active.toString()],
    };
  }

  if (type === actions.CREATE_TAB_ERROR) {
    localStorage.setItem('currentActiveTab', '1');

    return {
      ...state,
      record: null,
      initLoading: false,
      activeTab: 1,
      openedTabs: ['1'],
    };
  }

  if (type === actions.UPDATE_TAB_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_TAB_SUCCESS) {
    var new_record = state.record;

    const max =
      Number(new_record[new_record.length - 1]?.id) + 1;

    const newTab = {
      id: max,
      label: payload?.label,
      // label: `${payload?.label} ${max}`, ///TODO: remember to update above
      component: payload?.component,
      closeable: payload?.closeable,
    };

    new_record.push(newTab);
    localStorage.setItem(
      'currentActiveTab',
      max.toString(),
    );

    var openedTabs = state.openedTabs;

    openedTabs.push(max.toString());

    return {
      ...state,
      record: new_record,
      activeTab: max,
      saveLoading: false,
      openedTabs: openedTabs,
    };
  }

  if (type === actions.UPDATE_ACTIVE_TAB_SUCCESS) {
    localStorage.setItem(
      'currentActiveTab',
      payload.toString(),
    );

    var openedTabs = state.openedTabs;

    openedTabs.push(payload.toString());

    return {
      ...state,
      activeTab: payload,
      openedTabs: openedTabs,
    };
  }

  if (type === actions.UPDATE_ACTIVE_TAB_ERROR) {
    localStorage.setItem('currentActiveTab', '1');

    return {
      ...state,
      activeTab: 1,
      openedTabs: ['1'],
    };
  }

  if (type === actions.UPDATE_TAB_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.REMOVE_TAB_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.REMOVE_TAB_SUCCESS) {
    var new_record = state.record.filter(
      (o) => o.id !== payload,
    );

    var activeTab = state.activeTab;

    if (activeTab === payload) {
      activeTab = 1;
    }

    localStorage.setItem(
      'currentActiveTab',
      activeTab.toString(),
    );

    var openedTabs = state.openedTabs.filter(
      (o) => o !== payload.toString(),
    );

    return {
      ...state,
      record: new_record,
      activeTab: activeTab,
      saveLoading: false,
      openedTabs: openedTabs,
    };
  }

  if (type === actions.REMOVE_TAB_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
