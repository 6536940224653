import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import UserSettingsService from 'src/modules/userSettings/userSettingsService';

const prefix = 'USER_SETTINGS';

const userSettingsActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  doInit: () => async (dispatch, getState) => {
    try {
      if (
        !authSelectors.selectSignedIn(getState()) ||
        !AuthCurrentTenant.get()
      ) {
        return;
      }

      dispatch({
        type: userSettingsActions.INIT_STARTED,
      });

      const userSettings = await UserSettingsService.find();

      dispatch({
        type: userSettingsActions.INIT_SUCCESS,
        payload: userSettings,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: userSettingsActions.INIT_ERROR,
      });

      getHistory().push('/');
    }
  },

  doInitOnly: () => async (dispatch, getState) => {
    try {
      if (
        !authSelectors.selectSignedIn(getState()) ||
        !AuthCurrentTenant.get()
      ) {
        return;
      }

      dispatch({
        type: userSettingsActions.INIT_STARTED,
      });

      const userSettings = await UserSettingsService.find();
      AuthCurrentTenant.setRemoteSetting(userSettings);
      dispatch({
        type: userSettingsActions.INIT_SUCCESS,
        payload: userSettings,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: userSettingsActions.INIT_ERROR,
      });
    }
  },

  doSave:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: userSettingsActions.SAVE_STARTED,
        });

        const userSettings = await UserSettingsService.save(
          values,
        );

        dispatch({
          type: userSettingsActions.SAVE_SUCCESS,
          payload: userSettings,
        });

        openSnackbar(
          i18n('settings.save.success2'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: userSettingsActions.SAVE_ERROR,
        });
      }
    },

  doDelete:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: userSettingsActions.SAVE_STARTED,
        });

        const PasswordMatch =
          await UserSettingsService.check_password(values);

        if (!PasswordMatch) {
          dispatch({
            type: userSettingsActions.SAVE_ERROR,
          });

          openSnackbar(
            i18n(
              'privacy_setting.delete.password_not_match',
            ),
            'error',
          );
        } else {
          await UserSettingsService.delete_account();

          getHistory().push('/auth/signin');

          openSnackbar(
            i18n('privacy_setting.delete.successful'),
            'success',
          );
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: userSettingsActions.SAVE_ERROR,
        });
      }
    },
};

export default userSettingsActions;
