import list from 'src/modules/advertisement/list/advertisementReducers';
import form from 'src/modules/advertisement/form/advertisementFormReducers';
import view from 'src/modules/advertisement/view/advertisementViewReducers';
import destroy from 'src/modules/advertisement/destroy/advertisementDestroyReducers';
// import importerReducer from 'src/modules/advertisement/importer/advertisementImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  // importer: importerReducer,
});
