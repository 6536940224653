import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/lastInstructions/importer/lastInstructionsImporterSelectors';
import LastInstructionsService from 'src/modules/lastInstructions/lastInstructionsService';
import fields from 'src/modules/lastInstructions/importer/lastInstructionsImporterFields';
import { i18n } from 'src/i18n';

const lastInstructionsImporterActions = importerActions(
  'LASTINSTRUCTIONS_IMPORTER',
  selectors,
  LastInstructionsService.import,
  fields,
  i18n('entities.lastInstructions.importer.fileName'),
);

export default lastInstructionsImporterActions;