import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.property.fields.id'),
  },
  {
    name: 'propType',
    label: i18n('entities.property.fields.propType'),
  },
  {
    name: 'propDesc',
    label: i18n('entities.property.fields.propDesc'),
  },
  {
    name: 'propTypeOfLandTitle',
    label: i18n('entities.property.fields.propTypeOfLandTitle'),
  },
  {
    name: 'propAddress',
    label: i18n('entities.property.fields.propAddress'),
  },
  {
    name: 'propLandTitle',
    label: i18n('entities.property.fields.propLandTitle'),
  },
  {
    name: 'propPtLotNo',
    label: i18n('entities.property.fields.propPtLotNo'),
  },
  {
    name: 'propMukim',
    label: i18n('entities.property.fields.propMukim'),
  },
  {
    name: 'propDaerah',
    label: i18n('entities.property.fields.propDaerah'),
  },
  {
    name: 'propAssetValue',
    label: i18n('entities.property.fields.propAssetValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'propCurrency',
    label: i18n('entities.property.fields.propCurrency'),
    render: exporterRenders.currency(),
  },
  {
    name: 'propFreeofEncumbrances',
    label: i18n('entities.property.fields.propFreeofEncumbrances'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'propBen',
    label: i18n('entities.property.fields.propBen'),
    render: exporterRenders.json(),
  },
  {
    name: 'propBen2',
    label: i18n('entities.property.fields.propBen2'),
    render: exporterRenders.json(),
  },
  {
    name: 'propPhoto',
    label: i18n('entities.property.fields.propPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'propFiles',
    label: i18n('entities.property.fields.propFiles'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'propFilesLocation',
    label: i18n('entities.property.fields.propFilesLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'propRemarks',
    label: i18n('entities.property.fields.propRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.property.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.property.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
