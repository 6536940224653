import {
  DashboardOutlined,
  FolderOutlined,
  Groups2Outlined,
  InfoOutlined,
  LogoutOutlined,
  PersonOutline,
  SchoolOutlined,
  SupportAgentOutlined,
  TimelineOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CSSObject,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Drawer as MuiDrawer,
  Theme,
  Tooltip,
  styled,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import actions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import { getHistory } from 'src/modules/store';
import { shortenTextToNLetters } from 'src/utils/functions';
import { drawerWidth } from './Layout';
import Ticket from './Ticket';
import { getConfig } from 'src/config';
import AppReleaseNoteModal from './AppReleaseNoteModal';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface ISideBar {
  isOpen: boolean;
  expend: React.Dispatch<React.SetStateAction<boolean>>;
  activeRoute: string;
}
const SideBar = ({
  isOpen,
  expend,
  activeRoute,
}: ISideBar) => {
  const dispatch = useDispatch();
  const { fullName, userRole } = useSelector(
    authSelectors.selectCurrentUser,
  );

  const isTrustee = userRole === 'trustee';

  const [toggle, setToggle] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] =
    useState(false);
  const handleCloseModal = () => {
    setShouldOpenModal(false); // Set shouldOpenModal to false here
  };
  return (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerHeader />
      <Ticket
        toggle={toggle}
        isToggle={() => setToggle(!toggle)}
      />
      <List
        sx={{ py: 0 }}
        subheader={
          isOpen ? (
            <ListSubheader>
              {i18n('menu.Dashboard')}
            </ListSubheader>
          ) : null
        }
      >
        <ListItemButton
          onClick={() => getHistory().push('/')}
          selected={activeRoute === '/'}
        >
          <Tooltip title={i18n('menu.home')}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <DashboardOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.home')} />
        </ListItemButton>
        {!isTrustee && (
          <ListItemButton
            onClick={() =>
              getHistory().push('/performance')
            }
            selected={activeRoute === '/performance'}
          >
            <Tooltip title={i18n('menu.Performance')}>
              <ListItemIcon sx={{ ml: 0.5 }}>
                <TimelineOutlined />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={i18n('menu.Performance')}
            />
          </ListItemButton>
        )}
        <ListItemButton
          onClick={() => getHistory().push('/customers')}
          selected={Boolean(
            activeRoute === '/customers' ||
              activeRoute?.includes('share-with-me'),
          )}
        >
          <Tooltip title={i18n('menu.Customers')}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <Groups2Outlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.Customers')} />
        </ListItemButton>
      </List>
      <List
        subheader={
          isOpen ? (
            <ListSubheader>
              {i18n('menu.forYou')}
            </ListSubheader>
          ) : (
            <Divider />
          )
        }
      >
        <ListItemButton
          onClick={() => getHistory().push('/academy')}
          selected={Boolean(activeRoute === '/academy')}
        >
          <Tooltip title={i18n('menu.academy')}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <SchoolOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.academy')} />
        </ListItemButton>
        <ListItemButton
          onClick={() => getHistory().push('/resources')}
          selected={Boolean(activeRoute === '/resources')}
        >
          <Tooltip title={i18n('menu.resources')}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <FolderOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.resources')} />
        </ListItemButton>
      </List>
      <Box display="flex" flex={1} />
      <List>
        <Divider />
        <ListItemButton
          onClick={() => getHistory().push('/my-account')}
          selected={activeRoute === '/my-account'}
        >
          <Tooltip title={i18n('menu.myProfile')}>
            <ListItemIcon>
              <Avatar
                alt="Profile picture"
                src=""
                sx={{ bgcolor: '#dff9fb' }}
              >
                <PersonOutline sx={{ color: '#535c68' }} />
              </Avatar>
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={i18n('menu.profile')}
            secondary={shortenTextToNLetters(
              fullName ?? '',
              14,
            )}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => dispatch(actions.doSignout())}
        >
          <Tooltip title={i18n('menu.Sign_Out')}>
            <ListItemIcon sx={{ ml: 1 }}>
              <LogoutOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.Sign_Out')} />
        </ListItemButton>
        <ListItemButton onClick={() => setToggle(true)}>
          <Tooltip title={i18n('menu.support')}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <SupportAgentOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={i18n('menu.support')} />
        </ListItemButton>
        <ListItemButton
          onClick={() => setShouldOpenModal(true)}
        >
          <Tooltip title={getConfig().REACT_APP_VERSION}>
            <ListItemIcon sx={{ ml: 0.5 }}>
              <InfoOutlined />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={`Koha ${
              getConfig().REACT_APP_VERSION
            }`}
            sx={{ color: '#868686' }}
          />
        </ListItemButton>
      </List>
      <AppReleaseNoteModal
        isOpen={shouldOpenModal}
        onClose={handleCloseModal}
      />
    </Drawer>
  );
};

export default memo(SideBar, () => {
  return false;
});
