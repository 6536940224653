import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import trusteeEnumerators from 'src/modules/trustee/trusteeEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'trusteeName',
    label: i18n('entities.trustee.fields.trusteeName'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'trusteeIDType',
    label: i18n('entities.trustee.fields.trusteeIDType'),
    schema: schemas.enumerator(
      i18n('entities.trustee.fields.trusteeIDType'),
      {
        options: trusteeEnumerators.trusteeIDType,
      },
    ),
  },
  {
    name: 'trusteeIdentification',
    label: i18n(
      'entities.trustee.fields.trusteeIdentification',
    ),
    schema: schemas.string(
      i18n(
        'entities.trustee.fields.trusteeIdentification',
      ),
      {},
    ),
  },
  {
    name: 'trusteeAddress',
    label: i18n('entities.trustee.fields.trusteeAddress'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeAddress'),
      {},
    ),
  },
  {
    name: 'trusteePhone',
    label: i18n('entities.trustee.fields.trusteePhone'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteePhone'),
      {},
    ),
  },
  {
    name: 'trusteePhone2',
    label: i18n('entities.trustee.fields.trusteePhone2'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteePhone2'),
      {},
    ),
  },
  {
    name: 'trusteeEmail',
    label: i18n('entities.trustee.fields.trusteeEmail'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeEmail'),
      {},
    ),
  },
  {
    name: 'trusteePhoto',
    label: i18n('entities.trustee.fields.trusteePhoto'),
    schema: schemas.images(
      i18n('entities.trustee.fields.trusteePhoto'),
      {},
    ),
  },
  {
    name: 'fee',
    label: i18n('entities.trustee.fields.fee'),
    schema: schemas.integer(
      i18n('entities.trustee.fields.fee'),
      {},
    ),
  },
  {
    name: 'trusteeCurrency',
    label: i18n(
      'entities.trustee.fields.trusteeCurrency',
    ),
    schema: schemas.enumerator(
      i18n('entities.trustee.fields.trusteeCurrency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'trusteeRemarks',
    label: i18n('entities.trustee.fields.trusteeRemarks'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeRemarks'),
      {},
    ),
  },
  {
    name: 'trusteeSequence',
    label: i18n(
      'entities.trustee.fields.trusteeSequence',
    ),
    schema: schemas.integer(
      i18n('entities.trustee.fields.trusteeSequence'),
      {},
    ),
  },
  {
    name: 'trusteeRelationship',
    label: i18n(
      'entities.trustee.fields.trusteeRelationship',
    ),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeRelationship'),
      {},
    ),
  },
  {
    name: 'trusteeStatus',
    label: i18n('entities.trustee.fields.trusteeStatus'),
    schema: schemas.string(
      i18n('entities.trustee.fields.trusteeStatus'),
      {},
    ),
  },
];
