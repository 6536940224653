import list from 'src/modules/account/list/accountListReducers';
import form from 'src/modules/account/form/accountFormReducers';
import view from 'src/modules/account/view/accountViewReducers';
import destroy from 'src/modules/account/destroy/accountDestroyReducers';
import importerReducer from 'src/modules/account/importer/accountImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
