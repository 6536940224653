import list from 'src/modules/debt/list/debtListReducers';
import form from 'src/modules/debt/form/debtFormReducers';
import view from 'src/modules/debt/view/debtViewReducers';
import destroy from 'src/modules/debt/destroy/debtDestroyReducers';
import importerReducer from 'src/modules/debt/importer/debtImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
