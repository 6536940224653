import list from 'src/modules/vehicle/list/vehicleListReducers';
import form from 'src/modules/vehicle/form/vehicleFormReducers';
import view from 'src/modules/vehicle/view/vehicleViewReducers';
import destroy from 'src/modules/vehicle/destroy/vehicleDestroyReducers';
import importerReducer from 'src/modules/vehicle/importer/vehicleImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
