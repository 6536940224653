/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      userProfileFile: {
        id: 'userProfileFile',
        //folder: 'tenant/:tenantId/settings/profileFile',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 100 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      resourcesPropPhoto: {
        id: 'resourcesPropPhoto',
        folder: 'tenant/:tenantId/resources/resourcesPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      resourcesFile: {
        id: 'resourcesFile',
        folder: 'tenant/:tenantId/resources/resourcesFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      accountAccFile: {
        id: 'accountAccFile',
        folder: 'tenant/:tenantId/account/accFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      contactListPropPhoto: {
        id: 'contactListPropPhoto',
        folder:
          'tenant/:tenantId/contactList/contactListPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      contactBasePropPhoto: {
        id: 'contactBasePropPhoto',
        folder:
          'tenant/:tenantId/contactBase/contactBasePhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      beneficiaryBenPhoto: {
        id: 'beneficiaryBenPhoto',
        folder: 'tenant/:tenantId/beneficiary/benPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      instructionFile: {
        id: 'instructionFile',
        folder:
          'tenant/:tenantId/lastInstructions/instructionFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      screenshotFile: {
        id: 'screenshotFile',
        folder:
          'tenant/:tenantId/lastInstructions/screenshotFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      otherAssetOthFile: {
        id: 'otherAssetOthFile',
        folder: 'tenant/:tenantId/otherAsset/othFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      otherAssetOthPhoto: {
        id: 'otherAssetOthPhoto',
        folder: 'tenant/:tenantId/otherAsset/othPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      propertyPropPhoto: {
        id: 'propertyPropPhoto',
        folder: 'tenant/:tenantId/property/propPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      propertyPropFiles: {
        id: 'propertyPropFiles',
        folder: 'tenant/:tenantId/property/propFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      taskPropFiles: {
        id: 'taskPropFiles',
        folder: 'tenant/:tenantId/task',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      insuranceInsFile: {
        id: 'insuranceInsFile',
        folder: 'tenant/:tenantId/insurance/insFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      debtDebtFile: {
        id: 'debtDebtFile',
        folder: 'tenant/:tenantId/debt/debtFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      vehiclePropPhoto: {
        id: 'vehiclePropPhoto',
        folder: 'tenant/:tenantId/vehicle/propPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      vehiclePropFiles: {
        id: 'vehiclePropFiles',
        folder: 'tenant/:tenantId/vehicle/propFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      Digital_File: {
        id: 'Digital_File',
        folder: 'tenant/:tenantId/digital/Digital_File',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      healthInfoProfilePhoto: {
        id: 'healthInfoProfilePhoto',
        folder:
          'tenant/:tenantId/healthInfo/healthInfoProfilePhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      healthInfoDonorDocument: {
        id: 'healthInfoDonorDocument',
        folder:
          'tenant/:tenantId/healthInfo/healthInfoDonorDocument',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      healthInfoMedicalInsuranceFile: {
        id: 'healthInfoMedicalInsuranceFile',
        folder:
          'tenant/:tenantId/healthInfo/healthInfoMedicalInsuranceFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      healthInfoContactPhoto: {
        id: 'healthInfoContactPhoto',
        folder:
          'tenant/:tenantId/healthInfo/healthInfoContactPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      petPhoto: {
        id: 'petPhoto',
        folder: 'tenant/:tenantId/pet/photo',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      executorExecutorPhoto: {
        id: 'executorExecutorPhoto',
        folder: 'tenant/:tenantId/executor/executorPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      witnessPhoto: {
        id: 'witnessPhoto',
        folder: 'tenant/:tenantId/witness/witnessPhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      lastInstructionFiles: {
        id: 'lastInstructionFiles',
        folder: 'tenant/:tenantId/lastinstruction/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      tmpWillFile: {
        id: 'tmpWillFile',
        folder:
          'tenant/:tenantId/documentation/tmpWillFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      Signed_Copy: {
        id: 'Signed_Copy',
        folder:
          'tenant/:tenantId/exporthistory/Signed_Copy',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      Custody_Card: {
        id: 'Custody_Card',
        folder:
          'tenant/:tenantId/exporthistory/Custody_Card',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      trusteePhoto: {
        id: 'trusteePhoto',
        folder: 'tenant/:tenantId/trustee/trusteePhoto',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      Attachments: {
        id: 'Attachments',
        folder: 'tenant/:tenantId/notes/Attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
    };
  }
}
