import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import { ConfigProvider } from 'antd';
import { getAntdLanguage } from 'src/i18n';
import {
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';

import ReactGA from 'react-ga4';
import { SnackbarProvider } from './hooks/useSnackbar';

ReactGA.initialize('G-76GHE9DLT7');
ReactGA.send('pageview');

const store = configureStore();

const THEME = createTheme({
  typography: {
    fontFamily: `"Noto Sans", sans-serif`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const App = (props) => {
  return (
    <MuiThemeProvider theme={THEME}>
      <ConfigProvider locale={getAntdLanguage()}>
        <Provider store={store}>
          <ConnectedRouter history={getHistory()}>
            <SnackbarProvider>
              <RoutesComponent />
            </SnackbarProvider>
          </ConnectedRouter>
        </Provider>
      </ConfigProvider>
    </MuiThemeProvider>
  );
};

export default App;
