import customerBaseService from 'src/modules/customerBase/customerBaseService';
import selectors from 'src/modules/customerBase/list/customerBaseSelectors'
import Errors from 'src/modules/shared/error/errors';

const prefix = 'CUSTOMER_BASE_LIST';

const customerBaseActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  SEND_MESSAGE_START: `${prefix}_SEND_MESSAGE_STARTED`,
  SEND_MESSAGE_SUCCESS: `${prefix}_SEND_MESSAGE_SUCCESS`,
  SEND_MESSAGE_ERROR: `${prefix}_SEND_MESSAGE_ERROR`,

  doChangeSelected(payload) {
    return {
      type: customerBaseActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: (id) => async (dispatch) => {
    dispatch({
      type: customerBaseActions.RESETED,
    });

    dispatch(customerBaseActions.doFetch(id));
  },

  doChangePaginationAndSort: (pagination, sorter) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: customerBaseActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: customerBaseActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(customerBaseActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(customerBaseActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (id, filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {

      dispatch({
        type: customerBaseActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await customerBaseService.list(
        id,
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: customerBaseActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: customerBaseActions.FETCH_ERROR,
      });
    }
  },

  doAgentFetch: (id, filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {

      dispatch({
        type: customerBaseActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await customerBaseService.listAgent(
        id,
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: customerBaseActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: customerBaseActions.FETCH_ERROR,
      });
    }
  },
};

export default customerBaseActions;
