import React from 'react';

import CloseIcon from 'src/images/dashboard/close_icon.svg';
import TrusteeClientForm from './TrusteeClientForm';

import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
} from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrusteeClientModal = (props) => {
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            minWidth: '60vw',
            padding: '5.55vh 4.08vw 4.63vh',
            borderRadius: '18px',
          },
        }}
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.onClose}
      >
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: '1.56vw',
            top: '2.78vh',
            padding: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box component="img" src={CloseIcon} />
        </IconButton>

        <DialogContent sx={{ padding: 0 }}>
          <TrusteeClientForm
            doUpdateTask={props.doUpdateTask}
            onClose={props.onClose}
            clientList={props.clientList}
            edit={props.edit}
            trusteeClientRow={props.trusteeClientRow}
            setIsLoading={props.setIsLoading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TrusteeClientModal;
