import LocationService from 'src/modules/location/locationService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const prefix = 'LOCATION_VIEW';

const locationViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: locationViewActions.FIND_STARTED,
      });

      const record = await TenantService.findLocationById(
        id,
      );

      dispatch({
        type: locationViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: locationViewActions.FIND_ERROR,
      });

      getHistory().push('/location');
    }
  },
};

export default locationViewActions;
