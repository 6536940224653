import Roles from 'src/security/roles';

const userEnumerators = {
  status: ['active', 'invited', 'empty-permissions'],
  roles: Object.keys(Roles.values),
  planExpired: [
    'Anytime',
    '3 days',
    '1 Week',
    '2 Weeks',
    '1 Month',
    '3 Months',
  ],
  trusteeClientStatus: [
    'All',
    'Subscribed',
    'Will Generated',
    'Document Received',
    'Completed',
  ],
};

export default userEnumerators;
