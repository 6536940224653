import list from 'src/modules/executor/list/executorListReducers';
import main from 'src/modules/executor/main/executorMainReducers';
import form from 'src/modules/executor/form/executorFormReducers';
import view from 'src/modules/executor/view/executorViewReducers';
import destroy from 'src/modules/executor/destroy/executorDestroyReducers';
import importerReducer from 'src/modules/executor/importer/executorImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  main,
  form,
  view,
  destroy,
  importer: importerReducer,
});
