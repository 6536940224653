import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import AuthService from 'src/modules/auth/authService';
import contactBaseListActions from 'src/modules/contactBase/list/contactBaseListActions';
import dependentFormActions from 'src/modules/dependent/form/dependentFormActions';
import executorMainActions from 'src/modules/executor/main/executorMainActions';
import globalActions from 'src/modules/global/globalActions';
import guardiansFormActions from 'src/modules/guardians/form/guardiansFormActions';
import petFormActions from 'src/modules/pet/form/petFormActions';
import residuaryFormActions from 'src/modules/residuary/form/residuaryFormActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';
import testamentaryTrustFormAction from 'src/modules/testamentaryTrust/form/testamentaryTrustFormActions';
import witnessMainActions from 'src/modules/witness/main/witnessMainActions';

const prefix = 'TENANT_FORM';

const tenantFormActions = {
  RESET: `${prefix}_RESET`,

  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  UPDATE_REMOTETENANT_STARTED: `${prefix}_UPDATE_REMOTETENANT_STARTED`,
  UPDATE_REMOTETENANT_SUCCESS: `${prefix}_UPDATE_REMOTETENANT_SUCCESS`,
  UPDATE_REMOTETENANT_ERROR: `${prefix}_UPDATE_REMOTETENANT_ERROR`,

  UPDATE_DASHBOARD_STARTED: `${prefix}_UPDATE_DASHBOARD_STARTED`,
  UPDATE_DASHBOARD_SUCCESS: `${prefix}_UPDATE_DASHBOARD_SUCCESS`,
  UPDATE_DASHBOARD_ERROR: `${prefix}_UPDATE_DASHBOARD_ERROR`,

  UPDATE_TRUSTEE_STARTED: `${prefix}_UPDATE_TRUSTEE_STARTED`,
  UPDATE_TRUSTEE_SUCCESS: `${prefix}_UPDATE_TRUSTEE_SUCCESS`,
  UPDATE_TRUSTEE_ERROR: `${prefix}_UPDATE_TRUSTEE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tenantFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);

      let record = {};

      if (isEdit) {
        record = await TenantService.find(id);
      }

      dispatch({
        type: tenantFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tenantFormActions.INIT_ERROR,
      });

      getHistory().push('/tenant');
    }
  },

  doCreate: (values, email) => async (dispatch) => {
    try {
      dispatch({
        type: tenantFormActions.CREATE_STARTED,
      });

      await TenantService.create(values);
      // const tenant = await TenantService.create(values);

      dispatch({
        type: tenantFormActions.CREATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tenantFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: tenantFormActions.UPDATE_STARTED,
        });

        const tenant = await TenantService.update(
          id,
          values,
        );

        dispatch({
          type: tenantFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('tenant.update.success'),
          'success',
        );
        await dispatch(authActions.doSelectTenant(tenant));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Update_Tenant_Data:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        var startActionType =
          tenantFormActions.UPDATE_STARTED;
        var endActionType =
          tenantFormActions.UPDATE_SUCCESS;
        var errActionType = tenantFormActions.UPDATE_ERROR;

        switch (values.record_type) {
          case 'Guardian': {
            startActionType =
              guardiansFormActions.UPDATE_STARTED;
            endActionType =
              guardiansFormActions.UPDATE_SUCCESS;
            errActionType =
              guardiansFormActions.UPDATE_ERROR;
            break;
          }
          case 'Pets': {
            startActionType = petFormActions.UPDATE_STARTED;
            endActionType = petFormActions.UPDATE_SUCCESS;
            errActionType = petFormActions.UPDATE_ERROR;
            break;
          }
          case 'Executor': {
            startActionType =
              executorMainActions.UPDATE_STARTED;
            endActionType =
              executorMainActions.UPDATE_SUCCESS;
            errActionType =
              executorMainActions.UPDATE_ERROR;
            break;
          }
          case 'Witness': {
            startActionType =
              witnessMainActions.UPDATE_STARTED;
            endActionType =
              witnessMainActions.UPDATE_SUCCESS;
            errActionType = witnessMainActions.UPDATE_ERROR;
            break;
          }
          case 'Residuary': {
            startActionType =
              residuaryFormActions.UPDATE_STARTED;
            endActionType =
              residuaryFormActions.UPDATE_SUCCESS;
            errActionType =
              residuaryFormActions.UPDATE_ERROR;
            break;
          }
          case 'Manage_User': {
            startActionType =
              tenantFormActions.UPDATE_DASHBOARD_STARTED;
            endActionType =
              tenantFormActions.UPDATE_DASHBOARD_SUCCESS;
            errActionType =
              tenantFormActions.UPDATE_DASHBOARD_ERROR;
            break;
          }
          case 'Trustee': {
            startActionType =
              tenantFormActions.UPDATE_TRUSTEE_STARTED;
            endActionType =
              tenantFormActions.UPDATE_TRUSTEE_SUCCESS;
            errActionType =
              tenantFormActions.UPDATE_TRUSTEE_ERROR;
            break;
          }
          case 'TestamentaryTrust': {
            startActionType =
              testamentaryTrustFormAction.UPDATE_STARTED;
            endActionType =
              testamentaryTrustFormAction.UPDATE_SUCCESS;
            errActionType =
              testamentaryTrustFormAction.UPDATE_ERROR;
            break;
          }
          case 'Dependent': {
            startActionType =
              dependentFormActions.UPDATE_STARTED;
            endActionType =
              dependentFormActions.UPDATE_SUCCESS;
            errActionType =
              dependentFormActions.UPDATE_ERROR;
          }
        }

        dispatch({
          type: startActionType,
          // type: tenantFormActions.UPDATE_STARTED,
        });

        const atmp = await TenantService.Update_Tenant_Data(
          values,
          true,
        );

        if (values.record_type === 'Contact_List') {
          if (values.record_from) {
            getHistory().push('/beneficiary');
          } else {
            getHistory().push('/contact-list');
          }
        } else if (values.record_type === 'Beneficiary') {
          getHistory().push('/beneficiary');
        } else if (values.record_type === 'Manage_User') {
          getHistory().push('/user');
        }
        // else if(values.record_type === 'Executor'){
        //   getHistory().push('/executors/new');
        // }
        // else if(values.record_type === 'Guardians'){
        //   getHistory().push('/legal-guardian/new');
        // }
        // else if(values.record_type === 'Residuary'){
        //   getHistory().push('/residuary/new');
        // }
        else if (values.record_type === 'Pets') {
          getHistory().push('/pet');
        }
        // else if(values.record_type === 'Witness'){
        //   getHistory().push('/witnesss/new');
        // }
        else if (values.record_type === 'Pledges') {
          getHistory().push('/pledge');
        } else if (
          values.record_type === 'Last_Instruction'
        ) {
          getHistory().push('/last-instructions');
        } else if (values.record_type === 'Contact_Base') {
          getHistory().push('/contact-base');
        } else if (values.record_type === 'Location') {
          getHistory().push('/location');
        } else if (
          values.record_type === 'Trustee' ||
          values.record_type === 'TestamentaryTrust'
        ) {
          dispatch({
            // type: tenantFormActions.UPDATE_TRUSTEE_SUCCESS,
            type: endActionType,
            payload: {
              TestamentaryTrust:
                (atmp.TestamentaryTrust ?? ([] as any))
                  .length > 0
                  ? atmp.TestamentaryTrust[0]
                  : null,
              tenant: atmp,
            },
          });
        }

        if (
          values.record_type !== 'Trustee' &&
          values.record_type !== 'TestamentaryTrust'
        ) {
          dispatch({
            type: endActionType,
            // type: tenantFormActions.UPDATE_SUCCESS,
          });
        }

        var remote = AuthCurrentTenant.getRemote();
        var update_remote = {
          isRemote: true,
          platform: remote.platform,
          tenant: atmp,
          user: remote.remoteUser,
          assistantPermission: remote.assistantPermission,
        };
        // console.log("atmp", atmp)
        AuthCurrentTenant.setRemote(update_remote);

        dispatch(globalActions.doEditForm());
        openSnackbar(
          i18n('tenant.update.success'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: errActionType,
          // type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Delete_Tenant_Data:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: tenantFormActions.UPDATE_STARTED,
        });

        var result = await TenantService.Delete_Tenant_Data(
          values,
        );

        if (values.record_type === 'Contact_Base') {
          dispatch(
            contactBaseListActions.doFetch(
              {
                contactBaseName: '',
                contactBaseIdentificationNo: '',
                contactBaseAddress: '',
                contactBaseGender: '',
              },
              {
                contactBaseName: '',
                contactBaseIdentificationNo: '',
                contactBaseAddress: '',
                contactBaseGender: '',
              },
            ),
          );

          getHistory().push('/contact-base');
        } else if (
          values.record_type === 'TestamentaryTrust'
        ) {
          var remote = AuthCurrentTenant.getRemote();
          var update_remote = {
            isRemote: true,
            platform: remote.platform,
            tenant: result,
            user: remote.remoteUser,
            assistantPermission: remote.assistantPermission,
          };

          AuthCurrentTenant.setRemote(update_remote);

          dispatch({
            type: testamentaryTrustFormAction.UPDATE_SUCCESS,
            payload: {
              TestamentaryTrust:
                (result.TestamentaryTrust ?? ([] as any))
                  .length > 0
                  ? result.TestamentaryTrust[0]
                  : null,
              tenant: result,
            },
          });
        }

        openSnackbar(
          i18n('tenant.destroy.success'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tenantFormActions.UPDATE_ERROR,
        });
      }
    },

  do_Renew_DashBoard_Couant:
    () => async (dispatch, getState) => {
      const currentUser = await AuthService.fetchMe();
      var tenant = currentUser.tenants[0].tenant;

      dispatch({
        type: tenantFormActions.UPDATE_DASHBOARD_SUCCESS,
        payload: {
          tenant: tenant,
        },
      });
    },

  do_Tenant_Update_Specific_Data:
    (values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: tenantFormActions.UPDATE_REMOTETENANT_STARTED,
        });

        const results =
          await TenantService.Update_Tenant_Specific_Data(
            values,
          );

        var remote = AuthCurrentTenant.getRemote();
        var update_remote = {
          isRemote: true,
          platform: remote.platform,
          tenant: results,
          user: remote.remoteUser,
          assistantPermission: remote.assistantPermission,
        };
        // console.log("atmp", atmp)
        AuthCurrentTenant.setRemote(update_remote);

        dispatch({
          type: tenantFormActions.UPDATE_REMOTETENANT_SUCCESS,
          payload: {
            tenant: results,
          },
        });

        openSnackbar(
          i18n('willSettings.save.success'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tenantFormActions.UPDATE_REMOTETENANT_ERROR,
        });
      }
    },
};

export default tenantFormActions;
