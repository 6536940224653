const config = require(`./${
  process.env.REACT_APP_ENVIRONMENT
}`).default;


export function getConfig(): any {
  return process.env;
}

export default config;
