import PledgeService from 'src/modules/pledge/pledgeService';
import TenantService from 'src/modules/tenant/tenantService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'PLEDGE_VIEW';

const pledgeViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: pledgeViewActions.FIND_STARTED,
      });

      // const record = await PledgeService.find(id);
      const record = await TenantService.tenant_Find('Pledges',id,tenantId);

      dispatch({
        type: pledgeViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pledgeViewActions.FIND_ERROR,
      });

      getHistory().push('/pledge');
    }
  },
};

export default pledgeViewActions;
