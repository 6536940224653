import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.witness.fields.id'),
  },
  {
    name: 'witnessPhoto',
    label: i18n('entities.witness.fields.witnessPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'witnessName',
    label: i18n('entities.witness.fields.witnessName'),
  },
  {
    name: 'witnessPhone',
    label: i18n('entities.witness.fields.witnessPhone'),
  },
  {
    name: 'witnessPhone2',
    label: i18n('entities.witness.fields.witnessPhone2'),
  },
  {
    name: 'witnessEmail',
    label: i18n('entities.witness.fields.witnessEmail'),
  },
  {
    name: 'witnessAddress',
    label: i18n('entities.witness.fields.witnessAddress'),
  },
  {
    name: 'witnessRemarks',
    label: i18n('entities.witness.fields.witnessRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.witness.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.witness.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
