import list from 'src/modules/trustee/list/trusteeListReducers';
import main from 'src/modules/trustee/main/trusteeMainReducers';
import form from 'src/modules/trustee/form/trusteeFormReducers';
import view from 'src/modules/trustee/view/trusteeViewReducers';
import destroy from 'src/modules/trustee/destroy/trusteeDestroyReducers';
import importerReducer from 'src/modules/trustee/importer/trusteeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  main,
  form,
  view,
  destroy,
  importer: importerReducer,
});
