import { i18n } from 'src/i18n';
import palladiumLogo from '../../images/plan/palladium-logo-with-words.svg';

const trusteeEnumerators = {
  trusteeIDType: [
    'NRIC',
    'Old IC',
    'Passport',
    'Army/Police',
  ],
  trusteeRelationship: [
    'Spouse',
    'Cohabitee',
    'UnmarriedPartner',
    'Partner',
    'Parent',
    'SpouseParent',
    'GrandParent',
    'Child',
    'ChildPrevious',
    'SpouseChildPrevious',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    'Relative',
    'Friend',
    'Solicitor',
    'NominatedSolicitor',
    'Charity',
  ],
  trusteeSequence: [0, 1, 2, 3, 4, 5, 6, 7],

  trusteeList: [
    {
      id: 'Palladium',
      label: 'Palladium',
      img: null,
      image: palladiumLogo,
      description: i18n(
        'entities.trustee.defaultList.Palladium.description',
      ),
      dialogContent: [
        i18n(
          'entities.trustee.defaultList.Palladium.dialogContent.p1',
        ),
        i18n(
          'entities.trustee.defaultList.Palladium.dialogContent.p2',
        ),
        i18n(
          'entities.trustee.defaultList.Palladium.dialogContent.p3',
        ),
        i18n(
          'entities.trustee.defaultList.Palladium.dialogContent.p4',
        ),
      ],
      listOfService: {
        conventional: {
          CoporateExecutorService: [
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.conventional_1',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.conventional_2',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.conventional_3',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.conventional_4',
            ),
          ],
          TestamentaryTrust: [
            i18n(
              'entities.trustee.settings.TestamentaryTrust.content.conventional_1',
            ),
            i18n(
              'entities.trustee.settings.TestamentaryTrust.content.conventional_2',
            ),
            i18n(
              'entities.trustee.settings.TestamentaryTrust.content.conventional_3',
            ),
          ],
          Custody: [
            i18n(
              'entities.trustee.settings.Custody.content.conventional_1',
            ),
            i18n(
              'entities.trustee.settings.Custody.content.conventional_2',
            ),
            i18n(
              'entities.trustee.settings.Custody.content.conventional_3',
            ),
          ],
        },
        wasiat: {
          CoporateExecutorService: [
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.wasiat_1',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.wasiat_2',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.wasiat_3',
            ),
            i18n(
              'entities.trustee.settings.CorporateExecutorService.content.wasiat_4',
            ),
          ],
          Custody: [
            i18n(
              'entities.trustee.settings.Custody.content.wasiat_1',
            ),
            i18n(
              'entities.trustee.settings.Custody.content.wasiat_2',
            ),
            i18n(
              'entities.trustee.settings.Custody.content.wasiat_3',
            ),
          ],
        },
      },
    },
    // {
    //   id: 'MyPremier',
    //   label: 'My Premier',
    //   img: null,
    //   image: KohaLogo,
    //   description: i18n(
    //     'entities.trustee.defaultList.MyPremier.description',
    //   ),
    //   dialogContent: i18n(
    //     'entities.trustee.defaultList.MyPremier.dialogContent',
    //   ),
    // },
  ],
};

export default trusteeEnumerators;
