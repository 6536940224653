import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Button,
  Modal,
  // Col, Row
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import FileUploader from 'src/modules/shared/fileUpload/fileUploader';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';

import 'src/view/shared/css/ModalDesign.css';
// import { getHistory } from 'src/modules/store';
import DocViewerPage from 'src/view/docViewer/DocViewerPage';
import mime from 'mime-types';
import { getConfig } from 'src/config';
import filesize from 'filesize';

const FilesUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [show_plan, set_show_plan] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFile, setFileSelected] = useState('empty');

  const [fileType, setFileType] = useState('');

  const value = () => {
    const { value } = props;

    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  };

  const fileList = () => {
    return value().map((item) => ({
      uid: item.id || undefined,
      name: props.hideName ? null : item.name,
      status: 'done' as 'done',
      url: item.downloadUrl,
      // url: `${item.privateUrl}`,
      type: item.type,
      size: item.size,
    }));
  };

  const handleSuccess = (file) => {
    setLoading(false);
    props.onChange([...value(), file]);
  };

  const handleError = (error) => {
    console.error(error);
    setLoading(false);
    Errors.showMessage(error);
  };

  const handleRemove = (id) => {
    props.onChange(
      value().filter((item) => item.id !== id),
    );
  };

  const handleChange = (file) => {
    if (!file || !file.file || !file.file.status) {
      return;
    }

    if (file.file.status === 'removed') {
      handleRemove(file.file.uid);
    } else {
      setLoading(true);
    }
  };

  const previewFileHandler = (file) => {
    // console.log('file uploader preview', file);

    // DocViewerPage(file.downloadUrl);
    // getHistory().push(`/docview/${file.url}`);
    validateExtensions(file);
    setFileSelected(file.url);
    doOpenModal();
  };

  const validateExtensions = async (file) => {
    // var exts = ['.mp4', '.WMV','.AVI', '.MKV','.mp3','.WAV']
    // var a = (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(file.name)
    // console.log("A",a)

    var videoExts = [
      'audio/mp3',
      'audio/mpeg',
      'video/mp4',
    ];
    var type = mime.lookup(file.url);
    if (videoExts.indexOf(type) >= 0) {
      setFileType('video');
    } else {
      setFileType('');
    }
  };

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const validate = (file) => {
    try {
      if (
        file.size > getConfig().REACT_APP_UPLOADFILE_LIMIT
      ) {
        throw new Error(
          i18n(
            'fileUploader.size',
            filesize(
              getConfig().REACT_APP_UPLOADFILE_LIMIT,
            ),
          ),
        );
      }

      if (max && fileList().length >= max) {
        set_show_plan(true);
        return false;
      } else {
        FileUploader.validate(file, {
          storage: props.storage,
          formats: props.formats,
        });
        return true;
      }
    } catch (error) {
      handleError(error);
      return false;
    }
  };

  const formats = () => {
    const { schema } = props;

    if (schema && schema.formats) {
      return schema.formats.join(', ');
    }

    return undefined;
  };

  const { max, readonly } = props;
  const uploadButton = (
    <Button>
      {loading ? (
        <LoadingOutlined rev={undefined} />
      ) : (
        <PlusOutlined rev={undefined} />
      )}
      {i18n('fileUploader.upload')}
    </Button>
  );
  return (
    <>
      <Upload
        accept={formats()}
        fileList={fileList()}
        disabled={readonly}
        listType={'picture'}
        customRequest={(request) => {
          FileUploader.uploadFromRequest(
            request,
            {
              storage: props.storage,
              formats: props.formats,
            },
            (file) => {
              handleSuccess(file);
            },
            (error) => {
              handleError(error);
            },
          );
        }}
        onChange={handleChange}
        beforeUpload={validate}
        onPreview={previewFileHandler}
      >
        {readonly || (max && fileList().length >= max)
          ? null
          : uploadButton}
        {/* {uploadButton} */}
      </Upload>

      <DocViewerPage
        visible={modalVisible}
        onCancel={doCloseModal}
        file={selectedFile}
        fileType={fileType}
      />
    </>
  );
};

FilesUploader.propTypes = {
  readonly: PropTypes.bool,
  max: PropTypes.number,
  storage: PropTypes.object,
  formats: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
  onChange: PropTypes.func,
  hideName: PropTypes.bool
};

export default FilesUploader;
