import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.beneficiary.fields.id'),
  },
  {
    name: 'benPhoto',
    label: i18n('entities.beneficiary.fields.benPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'benName',
    label: i18n('entities.beneficiary.fields.benName'),
  },
  {
    name: 'benIdentificationType',
    label: i18n('entities.beneficiary.fields.benIdentificationType'),
  },
  {
    name: 'benIdentificationNo',
    label: i18n('entities.beneficiary.fields.benIdentificationNo'),
  },
  {
    name: 'benRelationship',
    label: i18n('entities.beneficiary.fields.benRelationship'),
  },
  {
    name: 'benPhone',
    label: i18n('entities.beneficiary.fields.benPhone'),
  },
  {
    name: 'benEmail',
    label: i18n('entities.beneficiary.fields.benEmail'),
  },
  {
    name: 'benAddress',
    label: i18n('entities.beneficiary.fields.benAddress'),
  },
  {
    name: 'benRemarks',
    label: i18n('entities.beneficiary.fields.benRemarks'),
  },
  {
    name: 'benUnderage',
    label: i18n('entities.beneficiary.fields.benUnderage'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'benInheritAge',
    label: i18n('entities.beneficiary.fields.benInheritAge'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.beneficiary.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.beneficiary.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
