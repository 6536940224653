import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import executorEnumerators from 'src/modules/executor/executorEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'executorName',
    label: i18n('entities.executor.fields.executorName'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'executorType',
    label: i18n('entities.executor.fields.executorType'),
    schema: schemas.stringArray(
      i18n('entities.executor.fields.executorType'),
      {},
    ),
  },
  {
    name: 'executorIDType',
    label: i18n('entities.executor.fields.executorIDType'),
    schema: schemas.enumerator(
      i18n('entities.executor.fields.executorIDType'),
      {
        options: executorEnumerators.executorIDType,
      },
    ),
  },
  {
    name: 'executorIdentification',
    label: i18n(
      'entities.executor.fields.executorIdentification',
    ),
    schema: schemas.string(
      i18n(
        'entities.executor.fields.executorIdentification',
      ),
      {},
    ),
  },
  {
    name: 'executorAddress',
    label: i18n('entities.executor.fields.executorAddress'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorAddress'),
      {},
    ),
  },
  {
    name: 'executorPhone',
    label: i18n('entities.executor.fields.executorPhone'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorPhone'),
      {},
    ),
  },
  {
    name: 'executorPhone2',
    label: i18n('entities.executor.fields.executorPhone2'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorPhone2'),
      {},
    ),
  },
  {
    name: 'executorEmail',
    label: i18n('entities.executor.fields.executorEmail'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorEmail'),
      {},
    ),
  },
  {
    name: 'executorPhoto',
    label: i18n('entities.executor.fields.executorPhoto'),
    schema: schemas.images(
      i18n('entities.executor.fields.executorPhoto'),
      {},
    ),
  },
  {
    name: 'fee',
    label: i18n('entities.executor.fields.fee'),
    schema: schemas.integer(
      i18n('entities.executor.fields.fee'),
      {},
    ),
  },
  {
    name: 'executorCurrency',
    label: i18n(
      'entities.executor.fields.executorCurrency',
    ),
    schema: schemas.enumerator(
      i18n('entities.executor.fields.executorCurrency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'executorRemarks',
    label: i18n('entities.executor.fields.executorRemarks'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorRemarks'),
      {},
    ),
  },
  {
    name: 'executorSequence',
    label: i18n(
      'entities.executor.fields.executorSequence',
    ),
    schema: schemas.integer(
      i18n('entities.executor.fields.executorSequence'),
      {},
    ),
  },
  {
    name: 'executorRelationship',
    label: i18n(
      'entities.executor.fields.executorRelationship',
    ),
    schema: schemas.string(
      i18n('entities.executor.fields.executorRelationship'),
      {},
    ),
  },
  {
    name: 'executorStatus',
    label: i18n('entities.executor.fields.executorStatus'),
    schema: schemas.string(
      i18n('entities.executor.fields.executorStatus'),
      {},
    ),
  },
];
