const notificationEnumerators = {
  status: [
    {
      value: 'all',
      label: 'All Notification',
    },
    {
      value: 'new',
      label: 'New',
    },
    {
      value: 'unread',
      label: 'Unread',
    },
  ],
};

export default notificationEnumerators;

export type TNotificationStatus =
  (typeof notificationEnumerators.status)[number]['value'];
