import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.executor.fields.id'),
  },
  {
    name: 'executorName',
    label: i18n('entities.executor.fields.executorName'),
  },
  {
    name: 'executorType',
    label: i18n('entities.executor.fields.executorType'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'executorIDType',
    label: i18n('entities.executor.fields.executorIDType'),
  },
  {
    name: 'executorIdentification',
    label: i18n(
      'entities.executor.fields.executorIdentification',
    ),
  },
  {
    name: 'executorAddress',
    label: i18n('entities.executor.fields.executorAddress'),
  },
  {
    name: 'executorPhone',
    label: i18n('entities.executor.fields.executorPhone'),
  },
  {
    name: 'executorEmail',
    label: i18n('entities.executor.fields.executorEmail'),
  },
  {
    name: 'executorPhoto',
    label: i18n('entities.executor.fields.executorPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'fee',
    label: i18n('entities.executor.fields.fee'),
  },
  {
    name: 'executorCurrency',
    label: i18n('entities.executor.fields.executorCurrency'),
    render: exporterRenders.relationToOne(),
  },
{
    name: 'executorRemarks',
    label: i18n('entities.executor.fields.executorRemarks'),
  },
  {
    name: 'executorSequence',
    label: i18n(
      'entities.executor.fields.executorSequence',
    ),
  },
  {
    name: 'executorRelationship',
    label: i18n(
      'entities.executor.fields.executorRelationship',
    ),
  },
  {
    name: 'executorStatus',
    label: i18n('entities.executor.fields.executorStatus'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.executor.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.executor.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
