import WalletService from 'src/modules/wallet/walletService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'WALLET_VIEW';

const walletViewActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doFetch: (id) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: walletViewActions.FETCH_STARTED,
      });

      const response = await WalletService.list(id);

      dispatch({
        type: walletViewActions.FETCH_SUCCESS,
        payload: {
          record: response,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: walletViewActions.FETCH_ERROR,
      });
    }
  },
};

export default walletViewActions;
