const pledgeEnumerators = {
  orgCategory: [],
  orgList: [{ name: '', sublist: [] }],
  //orgList: [{name: "", sublist:[{sublist_name: "", link: ""}]}];
};

//BC17012022-Remove unused variable const tmp_elements = [{sublist:[]}];
//const tmp_pleadgeelements = [{name: "", org_list: tmp_elements}];
const tmp_pleadgeelements = [{ name: '' }];

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

const request = async () => {
  try {
    const response = await fetch(
      'https://koha.digital/wp-json/wp/v2/avada_portfolio',
    );
    const data = await response.json();

    const imagePromises = Object.keys(data).map(
      async (ws_data) => {
        if (data[ws_data].acf.category[0] !== undefined) {
          const category = data[ws_data].acf.category[0];
          let ws_found = tmp_pleadgeelements.findIndex(
            (p) => p.name === category,
          );

          if (ws_found === -1) {
            const source_link =
              data[ws_data]._links['wp:featuredmedia'][0]
                .href;

            try {
              const imgUrl = await getImage(source_link);
              const article = {
                sublist_name: entities.decode(
                  data[ws_data].title.rendered,
                ),
                link: data[ws_data].acf.website,
                desc: data[ws_data].content.rendered,
                image: imgUrl,
                registration_number:
                  data[ws_data].acf.registration_number,
              };

              tmp_pleadgeelements.push({ name: category });
              pledgeEnumerators.orgCategory.push(category);
              pledgeEnumerators.orgList.push({
                name: category,
                sublist: [article],
              });
            } catch (error) {
              console.error('Error fetching image:', error);
            }
          } else {
            pledgeEnumerators.orgList[
              ws_found
            ].sublist.push({
              sublist_name: entities.decode(
                data[ws_data].title.rendered,
              ),
              link: data[ws_data].acf.website,
              desc: data[ws_data].content.rendered,
            });
          }
        }
      },
    );

    await Promise.all(imagePromises);

    pledgeEnumerators.orgList.sort();
  } catch (exception) {
    console.error('PledgeEnum Error: ', exception);
  }
};

request();

const getImage = async (url) => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.guid?.rendered;
  } catch (error) {
    console.error('Error fetching image:', error);
    return '';
  }
};

export default pledgeEnumerators;
