import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/account/importer/accountImporterSelectors';
import AccountService from 'src/modules/account/accountService';
import fields from 'src/modules/account/importer/accountImporterFields';
import { i18n } from 'src/i18n';

const accountImporterActions = importerActions(
  'ACCOUNT_IMPORTER',
  selectors,
  AccountService.import,
  fields,
  i18n('entities.account.importer.fileName'),
);

export default accountImporterActions;