import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import vehicleEnumerators from 'src/modules/vehicle/vehicleEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'type',
    label: i18n('entities.vehicle.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.vehicle.fields.type'),
      {
        options: vehicleEnumerators.type,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.vehicle.fields.description'),
    schema: schemas.string(
      i18n('entities.vehicle.fields.description'),
      {},
    ),
  },
  {
    name: 'assetValue',
    label: i18n('entities.vehicle.fields.assetValue'),
    schema: schemas.decimal(
      i18n('entities.vehicle.fields.assetValue'),
      {},
    ),
  },
  {
    name: 'currency',
    label: i18n('entities.vehicle.fields.currency'),
    schema: schemas.enumerator(
      i18n('entities.vehicle.fields.currency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'freeofEncumbrances',
    label: i18n(
      'entities.vehicle.fields.freeofEncumbrances',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.vehicle.fields.freeofEncumbrances',
      ),
      {},
    ),
  },
  {
    name: 'ben',
    label: i18n('entities.vehicle.fields.ben'),
    schema: schemas.relationToMany(
      i18n('entities.vehicle.fields.ben'),
      {},
    ),
  },
  {
    name: 'photo',
    label: i18n('entities.vehicle.fields.photo'),
    schema: schemas.images(
      i18n('entities.vehicle.fields.photo'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.vehicle.fields.files'),
    schema: schemas.files(
      i18n('entities.vehicle.fields.files'),
      {},
    ),
  },
  {
    name: 'remarks',
    label: i18n('entities.vehicle.fields.remarks'),
    schema: schemas.string(
      i18n('entities.vehicle.fields.remarks'),
      {},
    ),
  },
];
