import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/legalGuardian/importer/legalGuardianImporterSelectors';
import LegalGuardianService from 'src/modules/legalGuardian/legalGuardianService';
import fields from 'src/modules/legalGuardian/importer/legalGuardianImporterFields';
import { i18n } from 'src/i18n';

const legalGuardianImporterActions = importerActions(
  'LEGALGUARDIAN_IMPORTER',
  selectors,
  LegalGuardianService.import,
  fields,
  i18n('entities.legalGuardian.importer.fileName'),
);

export default legalGuardianImporterActions;