import { i18n } from 'src/i18n';
import AccessListService from 'src/modules/accessList/accessListService';
import listActions from 'src/modules/accessList/list/accessListActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'ACCESSLIST_DESTROY';

const accessListDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, openSnackbar, type?) => async (dispatch) => {
      try {
        dispatch({
          type: accessListDestroyActions.DESTROY_STARTED,
        });

        await AccessListService.destroyAll([id]);

        dispatch({
          type: accessListDestroyActions.DESTROY_SUCCESS,
        });

        openSnackbar(
          i18n('entities.accessForm.destroy.success'),
          'success',
        );

        dispatch(listActions.doFetchCurrentFilter());
        getHistory().push('/accessList');
      } catch (error) {
        Errors.handle(error);

        dispatch(listActions.doFetchCurrentFilter());

        dispatch({
          type: accessListDestroyActions.DESTROY_ERROR,
        });
      }
    },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: accessListDestroyActions.DESTROY_ALL_STARTED,
      });

      await AccessListService.destroyAll(ids);

      dispatch({
        type: accessListDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.accessForm.destroyAll.success'),
        'success',
      );

      getHistory().push('/accessList');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: accessListDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default accessListDestroyActions;
