import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'othCategory',
    label: i18n('entities.otherAsset.fields.othCategory'),
    schema: schemas.relationToOne(
      i18n('entities.otherAsset.fields.othCategory'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'othDescription',
    label: i18n('entities.otherAsset.fields.othDescription'),
    schema: schemas.string(
      i18n('entities.otherAsset.fields.othDescription'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'othLocation',
    label: i18n('entities.otherAsset.fields.othLocation'),
    schema: schemas.relationToOne(
      i18n('entities.otherAsset.fields.othLocation'),
      {},
    ),
  },
  {
    name: 'othRemarks',
    label: i18n('entities.otherAsset.fields.othRemarks'),
    schema: schemas.string(
      i18n('entities.otherAsset.fields.othRemarks'),
      {},
    ),
  },
  {
    name: 'othValue',
    label: i18n('entities.otherAsset.fields.othValue'),
    schema: schemas.decimal(
      i18n('entities.otherAsset.fields.othValue'),
      {},
    ),
  },
  {
    name: 'othCurrency',
    label: i18n('entities.otherAsset.fields.othCurrency'),
    schema: schemas.enumerator(
      i18n('entities.otherAsset.fields.othCurrency'),
      {
        "required": true,
        "options": currencyEnumerators.cashCurrency
      },
    ),
  },
  {
    name: 'othBen',
    label: i18n('entities.otherAsset.fields.othBen'),
    schema: schemas.relationToMany(
      i18n('entities.otherAsset.fields.othBen'),
      {},
    ),
  },
  {
    name: 'othFile',
    label: i18n('entities.otherAsset.fields.othFile'),
    schema: schemas.files(
      i18n('entities.otherAsset.fields.othFile'),
      {},
    ),
  },
  {
    name: 'othPhoto',
    label: i18n('entities.otherAsset.fields.othPhoto'),
    schema: schemas.images(
      i18n('entities.otherAsset.fields.othPhoto'),
      {},
    ),
  },
];
