import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
// import { TransitionProps } from '@mui/material/transitions';
// import React from 'react';
// import { i18n } from 'src/i18n';
// import actions from 'src/modules/auth/authActions';
// import { useDispatch } from 'react-redux';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CustomDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: 12,
    padding: '16px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    animation: 'fadeIn 0.3s ease-in-out',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'scale(0.95)' },
    to: { opacity: 1, transform: 'scale(1)' },
  },
});

// Custom Illustration
const Illustration = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 0',
});

const EKYCVerificationDialog = ({
  open,
  onClose,
  onVerify,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      {/* Header */}
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <WarningAmberIcon
            color="warning"
            fontSize="large"
          />
          <Typography variant="h6">
            Verify Your Identity
          </Typography>
        </Box>
      </DialogTitle>

      {/* Illustration */}
      <Illustration>
        <VerifiedUserIcon
          color="success"
          style={{ fontSize: 80 }}
        />
      </Illustration>

      {/* Content */}
      <DialogContent>
        <Typography variant="body1" align="center">
          Complete the eKYC verification process to unlock
          all features and ensure account security.
        </Typography>
      </DialogContent>

      {/* Actions */}
      <DialogActions
        sx={{ justifyContent: 'center', pb: 2 }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Remind Me Later
        </Button>
        <Button
          onClick={onVerify}
          variant="contained"
          color="success"
        >
          Complete Verification
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const EKYCModal = (props) => {
//   const dispatch = useDispatch();

//   return (
//     <Dialog
//       PaperProps={{
//         style: {
//           minWidth: '31.25vw',
//           // minHeight: props.edit ? '60.74vh' : '69.35vh',
//           padding: '5.55vh 4.08vw 4.63vh',
//           borderRadius: '18px',
//         },
//       }}
//       open={props.visible}
//       TransitionComponent={Transition}
//       onClose={props.handleModalVisible}
//     >
//       <DialogTitle align="center">
//         <Cancel sx={{ color: 'red', fontSize: 50 }} />
//       </DialogTitle>
//       <DialogContent>
//         <DialogContentText id="alert-dialog-slide-description">
//           <Typography variant="h6" sx={{ mt: 2 }}>
//             {i18n('eKYC.unverifiedEKYCModalTitle')}
//           </Typography>
//           <Typography variant="body2" sx={{ mt: 1 }}>
//             {i18n('eKYC.unverifiedEKYCModalDesc')}
//           </Typography>
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button
//           variant="contained"
//           color="error"
//           onClick={props.handleModalVisible}
//         >
//           {i18n('eKYC.dismiss')}
//         </Button>
//         <Button
//           variant="contained"
//           color="success"
//           startIcon={<CheckCircle />}
//           onClick={() => {
//             dispatch(actions.doVerifyKenaleKYC());
//           }}
//         >
//           {i18n('eKYC.verifyNow')}
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default EKYCVerificationDialog;
