import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class WalletService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/wallet/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(id) {
    const params = {
      id,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/wallet`,
      {
        params,
      },
    );

    return response.data;
  }
  
  static async list(id) {

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/wallet/${id}`,
    );

    return response.data;
  }
}
