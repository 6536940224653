import { i18n } from 'src/i18n';
import listActions from 'src/modules/paymentHistory/list/paymentHistoryListActions';
import PaymentHistoryService from 'src/modules/paymentHistory/paymentHistoryService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'PAYMENT_HISTORY_DESTROY';

const paymentHistoryDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_STARTED,
      });

      await PaymentHistoryService.destroyAll([id]);

      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.paymentHistory.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/payment-history');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_ALL_STARTED,
      });

      await PaymentHistoryService.destroyAll(ids);

      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.paymentHistory.destroyAll.success'),
        'success',
      );

      getHistory().push('/payment-history');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: paymentHistoryDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default paymentHistoryDestroyActions;
