import list from 'src/modules/legalGuardian/list/legalGuardianListReducers';
import form from 'src/modules/legalGuardian/form/legalGuardianFormReducers';
import view from 'src/modules/legalGuardian/view/legalGuardianViewReducers';
import destroy from 'src/modules/legalGuardian/destroy/legalGuardianDestroyReducers';
import importerReducer from 'src/modules/legalGuardian/importer/legalGuardianImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
