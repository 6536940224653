import { i18n } from 'src/i18n';
import DigitalService from 'src/modules/digital/digitalService';
import globalActions from 'src/modules/global/globalActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'DIGITAL_FORM';

const digitalFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: digitalFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await DigitalService.find(id);
      }

      dispatch({
        type: digitalFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: digitalFormActions.INIT_ERROR,
      });

      getHistory().push('/digital');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: digitalFormActions.CREATE_STARTED,
      });

      await DigitalService.create(values);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: digitalFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.digital.create.success'),
        'success',
      );

      getHistory().push('/digital');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: digitalFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: digitalFormActions.UPDATE_STARTED,
        });

        await DigitalService.update(id, values);
        dispatch(globalActions.doEditForm());
        dispatch({
          type: digitalFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.digital.update.success'),
          'success',
        );

        getHistory().push('/digital');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: digitalFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default digitalFormActions;
