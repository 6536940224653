import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class ExecutorService {
  static async update(id, data, mainList = false) {
    const body = {
      id,
      data,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/executor/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.delete(
      `/tenant/${newTenantId}/executor`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data, mainList = false) {
    const body = {
      data,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/executor`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/executor/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/executor/${id}`,
    );

    return response.data;
  }

  static async list(
    filter,
    orderBy,
    limit,
    offset,
    withFileBuffer = false,
    mainList = false,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      withFileBuffer,
      mainList,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/executor`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/executor/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async invite(data, para) {
    const body = {
      data,
      para,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/executorInvite`,
      body,
    );

    return response.data;
  }

  //#region MainList
  static async findMain(id) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/executors/${id}`,
    );

    return response.data;
  }

  //#endregion
}
