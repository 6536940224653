import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class ExportHistoryServie {
  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/export-history`,
      body,
    );

    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/export-history/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/export-history`,
      {
        params,
      },
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/export-history/${id}`,
    );

    return response.data;
  }

  static async list(filter?, orderBy?, limit?, offset?) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/export-history`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listRemote(
    filter?,
    orderBy?,
    limit?,
    offset?,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/export-history`,
      {
        params,
      },
    );

    return response.data;
  }

  static async checkExistsByTenantId(
    filter?,
    orderBy?,
    limit?,
    offset?,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/export-history/checkExistsByTenantId`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAll(filter?, orderBy?, limit?, offset?) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/export-history/all`,
      {
        params,
      },
    );

    return response.data;
  }
}
