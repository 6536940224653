import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import beneficiaryEnumerators from 'src/modules/beneficiary/beneficiaryEnumerators';

export default [
  {
    name: 'benPhoto',
    label: i18n('entities.beneficiary.fields.benPhoto'),
    schema: schemas.images(
      i18n('entities.beneficiary.fields.benPhoto'),
      {},
    ),
  },
  {
    name: 'benName',
    label: i18n('entities.beneficiary.fields.benName'),
    schema: schemas.string(
      i18n('entities.beneficiary.fields.benName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'benIdentificationType',
    label: i18n(
      'entities.beneficiary.fields.benIdentificationType',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.beneficiary.fields.benIdentificationType',
      ),
      {
        required: true,
        options:
          beneficiaryEnumerators.benIdentificationType,
      },
    ),
  },
  {
    name: 'benIdentificationNo',
    label: i18n(
      'entities.beneficiary.fields.benIdentificationNo',
    ),
    schema: schemas.string(
      i18n(
        'entities.beneficiary.fields.benIdentificationNo',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'benRelationship',
    label: i18n(
      'entities.beneficiary.fields.benRelationship',
    ),
    schema: schemas.enumerator(
      i18n('entities.beneficiary.fields.benRelationship'),
      {
        required: true,
        options: beneficiaryEnumerators.benRelationship,
      },
    ),
  },
  {
    name: 'benPhone',
    label: i18n('entities.beneficiary.fields.benPhone'),
    schema: schemas.string(
      i18n('entities.beneficiary.fields.benPhone'),
      {},
    ),
  },
  {
    name: 'benEmail',
    label: i18n('entities.beneficiary.fields.benEmail'),
    schema: schemas.string(
      i18n('entities.beneficiary.fields.benEmail'),
      {},
    ),
  },
  {
    name: 'benAddress',
    label: i18n('entities.beneficiary.fields.benAddress'),
    schema: schemas.string(
      i18n('entities.beneficiary.fields.benAddress'),
      {},
    ),
  },
  {
    name: 'benRemarks',
    label: i18n('entities.beneficiary.fields.benRemarks'),
    schema: schemas.string(
      i18n('entities.beneficiary.fields.benRemarks'),
      {},
    ),
  },
];