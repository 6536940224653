import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import {
  default as viewActions,
  default as walletViewActions,
} from 'src/modules/wallet/view/walletViewActions';
import WalletService from 'src/modules/wallet/walletService';
const prefix = 'WALLET_DESTROY';

const walletDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: walletDestroyActions.DESTROY_STARTED,
      });

      await WalletService.destroyAll(id);

      dispatch({
        type: walletDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.wallet.destroy.success'),
        'success',
      );

      dispatch(walletViewActions.doFetch(id));

      getHistory().push('/wallet');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: viewActions.FETCH_STARTED,
      });

      dispatch({
        type: walletDestroyActions.DESTROY_ERROR,
      });
    }
  },
};

export default walletDestroyActions;
