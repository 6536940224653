const vehicleEnumerators = {
  type: [
    "Airplane",
    "Car",
    "Boat",
    "Motorcycle",
    "Truck",
    "Bicycle",
    "Other",],
};

export default vehicleEnumerators;
