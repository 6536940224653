import list from 'src/modules/guardians/list/guardiansListReducers';
import form from 'src/modules/guardians/form/guardiansFormReducers';
import view from 'src/modules/guardians/view/guardiansViewReducers';
import destroy from 'src/modules/guardians/destroy/guardiansDestroyReducers';

import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
