import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import propertyEnumerators from 'src/modules/property/propertyEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'propType',
    label: i18n('entities.property.fields.propType'),
    schema: schemas.enumerator(
      i18n('entities.property.fields.propType'),
      {
        options: propertyEnumerators.propType,
      },
    ),
  },
  {
    name: 'propDesc',
    label: i18n('entities.property.fields.propDesc'),
    schema: schemas.enumerator(
      i18n('entities.property.fields.propDesc'),
      {
        options: propertyEnumerators.propDesc,
      },
    ),
  },
  {
    name: 'propAddress',
    label: i18n('entities.property.fields.propAddress'),
    schema: schemas.string(
      i18n('entities.property.fields.propAddress'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'propLandTitle',
    label: i18n('entities.property.fields.propLandTitle'),
    schema: schemas.string(
      i18n('entities.property.fields.propLandTitle'),
      {},
    ),
  },
  {
    name: 'propPtLotNo',
    label: i18n('entities.property.fields.propPtLotNo'),
    schema: schemas.string(
      i18n('entities.property.fields.propPtLotNo'),
      {},
    ),
  },
  {
    name: 'propMukim',
    label: i18n('entities.property.fields.propMukim'),
    schema: schemas.string(
      i18n('entities.property.fields.propMukim'),
      {},
    ),
  },
  {
    name: 'propDaerah',
    label: i18n('entities.property.fields.propDaerah'),
    schema: schemas.string(
      i18n('entities.property.fields.propDaerah'),
      {},
    ),
  },
  {
    name: 'propAssetValue',
    label: i18n('entities.property.fields.propAssetValue'),
    schema: schemas.decimal(
      i18n('entities.property.fields.propAssetValue'),
      {},
    ),
  },
  {
    name: 'propCurrency',
    label: i18n('entities.property.fields.propCurrency'),
    schema: schemas.enumerator(
      i18n('entities.property.fields.propCurrency'),
      {
        required: true,
        options: currencyEnumerators.cashCurrency,
      },
    ),
  },
  {
    name: 'propFreeofEncumbrances',
    label: i18n(
      'entities.property.fields.propFreeofEncumbrances',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.property.fields.propFreeofEncumbrances',
      ),
      {},
    ),
  },
  {
    name: 'propBen',
    label: i18n('entities.property.fields.propBen'),
    schema: schemas.relationToMany(
      i18n('entities.property.fields.propBen'),
      {},
    ),
  },
  {
    name: 'propPhoto',
    label: i18n('entities.property.fields.propPhoto'),
    schema: schemas.images(
      i18n('entities.property.fields.propPhoto'),
      {},
    ),
  },
  {
    name: 'propFiles',
    label: i18n('entities.property.fields.propFiles'),
    schema: schemas.files(
      i18n('entities.property.fields.propFiles'),
      {},
    ),
  },
  {
    name: 'propRemarks',
    label: i18n('entities.property.fields.propRemarks'),
    schema: schemas.string(
      i18n('entities.property.fields.propRemarks'),
      {},
    ),
  },
];
