import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.trustee.fields.id'),
  },
  {
    name: 'trusteeName',
    label: i18n('entities.trustee.fields.trusteeName'),
  },
  {
    name: 'trusteeIDType',
    label: i18n('entities.trustee.fields.trusteeIDType'),
  },
  {
    name: 'trusteeIdentification',
    label: i18n(
      'entities.trustee.fields.trusteeIdentification',
    ),
  },
  {
    name: 'trusteeAddress',
    label: i18n('entities.trustee.fields.trusteeAddress'),
  },
  {
    name: 'trusteePhone',
    label: i18n('entities.trustee.fields.trusteePhone'),
  },
  {
    name: 'trusteeEmail',
    label: i18n('entities.trustee.fields.trusteeEmail'),
  },
  {
    name: 'trusteePhoto',
    label: i18n('entities.trustee.fields.trusteePhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'fee',
    label: i18n('entities.trustee.fields.fee'),
  },
  {
    name: 'trusteeCurrency',
    label: i18n('entities.trustee.fields.trusteeCurrency'),
    render: exporterRenders.relationToOne(),
  },
{
    name: 'trusteeRemarks',
    label: i18n('entities.trustee.fields.trusteeRemarks'),
  },
  {
    name: 'trusteeSequence',
    label: i18n(
      'entities.trustee.fields.trusteeSequence',
    ),
  },
  {
    name: 'trusteeRelationship',
    label: i18n(
      'entities.trustee.fields.trusteeRelationship',
    ),
  },
  {
    name: 'trusteeStatus',
    label: i18n('entities.trustee.fields.trusteeStatus'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.trustee.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.trustee.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
