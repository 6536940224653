/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      starter: 'starter',
      comprehensive: 'comprehensive',
      legal_document_bundle: 'legal_document_bundle',
      legal_services: 'legal_services',
      Advisory: 'Advisory',
      Advisory_Comprehensive: 'Advisory_Comprehensive',
      GE_Advisory_Comprehensive:
        'GE_Advisory_Comprehensive',
      legalFirmMembership: 'legal_firm_membership',
      palladiumExecutor: 'palladiumExecutor',
      palladiumCustodian: 'palladiumCustodian',
      palladiumTestamentary: 'palladiumTestamentary',
      mypExecutor: 'mypExecutor',
      mypCustodian: 'mypCustodian',
      mypTestamentary: 'mypTestamentary',
      Allianz_Advisory_Comprehensive:
        'Allianz_Advisory_Comprehensive',
      harta_Sepencarian: 'harta_Sepencarian',
      hibah: 'hibah',
      hibahRevocation: 'hibah_revocation'
    };
  }

  static get lists() {
    var planPackages = Plans.values;
    var data = [] as any;

    Object.keys(planPackages).forEach(function (key) {
      data.push(planPackages[key]);
    });

    return data;
  }
}

export default Plans;
