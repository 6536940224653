import CryptoJS from 'crypto-js';

const mySecKey = 'kohaliveagoodlife2021';

export default class dataEncryption {
  static encrypt(data) {
    let encryptedData = data
      ? CryptoJS.AES.encrypt(
          JSON.stringify({ data }),
          mySecKey,
        ).toString()
      : null;

    return encryptedData;
  }

  static decrypt(data) {
    try {
      let decryptedData = data
        ? CryptoJS.AES.decrypt(data, mySecKey).toString(
            CryptoJS.enc.Utf8,
          )
        : null;
      const result = JSON.parse(decryptedData);
      return result?.data;
    } catch (error) {
      console.error('Decryption failed:', error);
      localStorage.clear();
      return null;
    }
  }
}
