const currencyEnumerators = {
  cashCurrency: [
    'uAEDirham',
    'afghanAfghani',
    'albanianLek',
    'armenianDram',
    'netherlandsAntilleanGulden',
    'angolanKwanza',
    'argentinePeso',
    'australianDollar',
    'arubanFlorin',
    'azerbaijaniManat',
    'bosniaAndHerzegovinaKonvertibilnaMarka',
    'barbadianDollar',
    'bangladeshiTaka',
    'bulgarianLev',
    'bahrainiDinar',
    'burundiFranc',
    'bermudianDollar',
    'bruneiDollar',
    'bolivianBoliviano',
    'brazilianReal',
    'bahamianDollar',
    'bhutaneseNgultrum',
    'botswanaPula',
    'belarusianRuble',
    'belizeDollar',
    'canadianDollar',
    'congoleseFranc',
    'swissFranc',
    'chileanPeso',
    'chineseYuanRenminbi',
    'colombianPeso',
    'costaRicanColon',
    'cubanPeso',
    'capeVerdeanEscudo',
    'czechKoruna',
    'djiboutianFranc',
    'danishKrone',
    'dominicanPeso',
    'algerianDinar',
    'estonianKroon',
    'egyptianPound',
    'eritreanNakfa',
    'ethiopianBirr',
    'europeanEuro',
    'fijianDollar',
    'falklandIslandsPound',
    'britishPound',
    'georgianLari',
    'ghanaianCedi',
    'gibraltarPound',
    'gambianDalasi',
    'guineanFranc',
    'centralAfricanCFAFranc',
    'guatemalanQuetzal',
    'guyaneseDollar',
    'hongKongDollar',
    'honduranLempira',
    'croatianKuna',
    'haitianGourde',
    'hungarianForint',
    'indonesianRupiah',
    'israeliNewSheqel',
    'indianRupee',
    'iraqiDinar',
    'iranianRial',
    'icelandicKróna',
    'jamaicanDollar',
    'jordanianDinar',
    'japaneseYen',
    'kenyanShilling',
    'kyrgyzstaniSom',
    'cambodianRiel',
    'comorianFranc',
    'northKoreanWon',
    'southKoreanWon',
    'kuwaitiDinar',
    'caymanIslandsDollar',
    'kazakhstaniTenge',
    'laoKip',
    'lebaneseLira',
    'sriLankanRupee',
    'liberianDollar',
    'lesothoLoti',
    'lithuanianLitas',
    'latvianLats',
    'libyanDinar',
    'moroccanDirham',
    'moldovanLeu',
    'malagasyAriary',
    'macedonianDenar',
    'myanmaKyat',
    'mongolianTugrik',
    'macanesePataca',
    'mauritanianOuguiya',
    'mauritianRupee',
    'maldivianRufiyaa',
    'malawianKwacha',
    'mexicanPeso',
    'malaysianRinggit',
    'mozambicanMetical',
    'namibianDollar',
    'nigerianNaira',
    'nicaraguanCardoba',
    'norwegianKrone',
    'nepaleseRupee',
    'newZealandDollar',
    'omaniRial',
    'panamanianBalboa',
    'peruvianNuevoSol',
    'papuaNewGuineanKina',
    'philippinePeso',
    'pakistaniRupee',
    'polishZloty',
    'paraguayanGuarani',
    'qatariRiyal',
    'romanianLeu',
    'serbianDinar',
    'russianRuble',
    'saudiRiyal',
    'solomonIslandsDollar',
    'seychelloisRupee',
    'sudanesePound',
    'swedishKrona',
    'singaporeDollar',
    'saintHelenaPound',
    'sierraLeoneanLeone',
    'somaliShilling',
    'surinameseDollar',
    'syrianPound',
    'swaziLilangeni',
    'thaiBaht',
    'tajikistaniSomoni',
    'turkmenManat',
    'tunisianDinar',
    'turkishNewLira',
    'trinidadAndTobagoDollar',
    'newTaiwanDollar',
    'tanzanianShilling',
    'ukrainianHryvnia',
    'ugandanShilling',
    'unitedStatesDollar',
    'uruguayanPeso',
    'uzbekistaniSom',
    'venezuelanBolivar',
    'vietnameseDong',
    'vanuatuVatu',
    'samoanTala',
    'centralAfricanCFAFrancXAF',
    'eastCaribbeanDollar',
    'specialDrawingRights',
    'westAfricanCFAFranc',
    'cFPFranc',
    'yemeniRial',
    'southAfricanRand',
    'zambianKwacha',
    'zimbabweanDollar',
  ],
};

export default currencyEnumerators;
