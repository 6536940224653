import actions from 'src/modules/global/globalActions';
import EncryptionTool from 'src/modules/encryption/dataEncryption';

const initialData = {
  initLoading: false,
  saveLoading: false,
  global: null,
  isEditForm: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      initLoading: true,
    };
  }

  if (type === actions.EDITFORM_SUCCESS) {
    localStorage.setItem(
      'isEditForm',
      EncryptionTool.encrypt(JSON.stringify(payload.isEdit)),
    );
    return {
      ...state,
      isEditForm: payload.isEdit,
    };
  }


  return state;
};
