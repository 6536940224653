import service from 'src/modules/auth/authService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { AuthToken } from 'src/modules/auth/authToken';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import { tenantSubdomain } from '../tenant/tenantSubdomain';
import SettingsService from '../settings/settingsService';
import AssistantService from 'src/modules/assistant/assistantService';
import UserSettingsService from 'src/modules/userSettings/userSettingsService';
// import UserService from 'src/modules/user/userService';
import TenantService from '../tenant/tenantService';
import AuthService from 'src/modules/auth/authService';
import accessListService from '../accessList/accessListService';
import UserService from '../user/userService';
import _ from 'lodash';
import globalActions from '../global/globalActions';
import Task_Service from 'src/modules/tasks/tasksService';
import notificationService, {
  PUSH_TYPE,
} from 'src/modules/notification/notificationService';
import moment from 'moment';
import eventActions from 'src/modules/widgets/event/eventActions';

const prefix = 'AUTH';

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  CLONE_PROFILE_START: `${prefix}_CLONE_PROFILE_START`,
  CLONE_PROFILE_SUCCESS: `${prefix}_CLONE_PROFILE_SUCCESS`,
  CLONE_PROFILE_ERROR: `${prefix}_CLONE_PROFILE_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  CREATE_ACCOUNT_START: `${prefix}_CREATE_ACCOUNT_START`,
  CREATE_ACCOUNT_SUCCESS: `${prefix}_CREATE_ACCOUNT_SUCCESS`,
  CREATE_ACCOUNT_ERROR: `${prefix}_CREATE_ACCOUNT_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,

  EMAIL_VERIFY_START: `${prefix}_EMAIL_VERIFY_START`,
  EMAIL_VERIFY_SUCCESS: `${prefix}_EMAIL_VERIFY_SUCCESS`,
  EMAIL_VERIFY_ERROR: `${prefix}_EMAIL_VERIFY_ERROR`,

  EMAIL_CONFIRMATION_START: `${prefix}_EMAIL_CONFIRMATION_START`,
  EMAIL_CONFIRMATION_SUCCESS: `${prefix}_EMAIL_CONFIRMATION_SUCCESS`,
  EMAIL_CONFIRMATION_ERROR: `${prefix}_EMAIL_CONFIRMATION_ERROR`,

  MFA_CHANGE_START: `${prefix}_MFA_CHANGE_START`,
  MFA_CHANGE_SUCCESS: `${prefix}_MFA_CHANGE_SUCCESS`,
  MFA_CHANGE_ERROR: `${prefix}_MFA_CHANGE_ERROR`,

  MFA_REQUEST_START: `${prefix}_MFA_REQUEST_START`,
  MFA_REQUEST_SUCCESS: `${prefix}_MFA_REQUEST_SUCCESS`,
  MFA_REQUEST_ERROR: `${prefix}_MFA_REQUEST_ERROR`,

  UNLOCK_REQUEST_EMAIL_START: `${prefix}_UNLOCK_REQUEST_EMAIL_START`,
  UNLOCK_REQUEST_EMAIL_SUCCESS: `${prefix}_UNLOCK_REQUEST_EMAIL_SUCCESS`,
  UNLOCK_REQUEST_EMAIL_ERROR: `${prefix}_UNLOCK_REQUEST_EMAIL_ERROR`,

  REFRESH_TENANT_START: `${prefix}_REFRESH_TENANT_START`,
  REFRESH_TENANT_SUCCESS: `${prefix}_REFRESH_TENANT_SUCCESS`,
  REFRESH_TENANT_ERROR: `${prefix}_REFRESH_TENANT_ERROR`,

  REMOTE_USER_START: `${prefix}_REMOTE_USER_START`,
  REMOTE_USER_SUCCESS: `${prefix}_REMOTE_USER_SUCCESS`,
  REMOTE_USER_ERROR: `${prefix}_REMOTE_USER_ERROR`,

  REFRESH_REMOTE_START: `${prefix}_REFRESH_REMOTE_START`,
  REFRESH_REMOTE_SUCCESS: `${prefix}_REFRESH_REMOTE_SUCCESS`,
  REFRESH_REMOTE_ERROR: `${prefix}_REFRESH_REMOTE_ERROR`,

  REFRESH_EKYC: `${prefix}_REFRESH_EKYC`,

  doClearErrorMessage() {
    return {
      type: authActions.ERROR_MESSAGE_CLEARED,
    };
  },

  doSendEmailConfirmation:
    () => async (dispatch, getState) => {
      try {
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_START,
        });
        await service.sendEmailVerification();
        Message.success(
          i18n('auth.verificationEmailSuccess'),
        );
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_SUCCESS,
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: authActions.EMAIL_CONFIRMATION_ERROR,
        });
      }
    },

  doSendPasswordResetEmail: (email) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_START,
      });
      await service.sendPasswordResetEmail(email);
      Message.success(
        i18n('auth.passwordResetEmailSuccess'),
      );
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_ERROR,
      });
    }
  },

  doRegisterEmailAndPassword:
    (
      email,
      password,
      newsletter,
      referralCode,
      target_type,
      fullAuthorize = false,
      currentUser = null,
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });
        const token =
          await service.registerWithEmailAndPassword(
            email,
            password,
            newsletter,
            referralCode,
            target_type,
            fullAuthorize,
          );

        // AuthToken.set(token, true);
        // getHistory().push('/auth/email-verification/' + email);
        Message.success(
          i18n('user.new_invitation.success'),
        );
        // const currentUser = await service.fetchMe();
        // dispatch({
        //   type: authActions.AUTH_SUCCESS,
        //   payload: {
        //     email,
        //   },
        // });
      } catch (error) {
        await service.signout();
        console.log('Invitation error', error);

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doRegisterWithData:
    (
      data,
      newsletter,
      referralCode,
      target_type,
      record_id,
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });
        const token = await service.registerWithData(
          data,
          newsletter,
          referralCode,
          target_type,
          record_id,
        );

        let tenantId = AuthCurrentTenant.get();

        // var request = {
        //   record_id: record_id,
        //   record_type: 'Contact_Base',
        //   record_data: {
        //     contactBaseEmail: data?.email,
        //     contactBaseName: data?.fullName,
        //     contactBaseIdentificationType: data?.idType,
        //     contactBaseIdentificationNo: data?.idNo,
        //     contactBasePhone: data?.phoneNumber,
        //     contactBasePhoneCountryCode:
        //       data?.phoneNumberCountryCode,
        //     contactBaseAddress: data?.address,
        //     convertedToUser: true,
        //   },
        //   tenantId: tenantId,
        // };

        // await TenantService.Update_Tenant_Data(request);

        if (data.prospectId)
          getHistory().push('/customers');
        else getHistory().push('/user');

        Message.success(
          i18n('user.new_invitation.success'),
        );

        window.location.reload();
      } catch (error) {
        await service.signout();
        console.log('Invitation error', error);

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSigninWithEmailAndPassword:
    (email, password, rememberMe) => async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });
        AuthToken.setLandingToken('true');
        let currentUser = null;

        const response =
          await service.signinWithEmailAndPassword(
            email,
            password,
          );

        if (response.success) {
          localStorage.setItem(
            'wpLearning',
            JSON.stringify(response.data.wpLearning),
          );
        }
        AuthToken.set(response.data.jwt, rememberMe);
        AuthCurrentTenant.removeRemote();

        currentUser = await service.fetchMe();
        sessionStorage.setItem('seenEKYCModal', 'true');

        if (currentUser.isLock === true) {
          await service.signout();
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: Message.error(
              i18n('auth.signIn.isLockAccount'),
            ),
          });
          // }
          //  else if (
          //   currentUser.userRole === null ||
          //   currentUser.userRole === undefined ||
          //   currentUser.userRole === 'testator'
          // ) {
          //   await service.signout();
          //   dispatch({
          //     type: authActions.AUTH_ERROR,
          //     payload: Message.error(
          //       i18n('auth.signIn.notAgentRole'),
          //     ),
          //   });
        } else {
          dispatch({
            type: authActions.AUTH_SUCCESS,
            payload: {
              currentUser,
            },
          });

          const currentTenant = AuthCurrentTenant.get();
          const advisor = await service.fetchMe();

          // tasks
          const dateQuery = {
            date: true,
            status: ['To-Do', 'InProgress'],
          };

          let tasks = await Task_Service.getAllTasks(
            dateQuery,
          );
          if (tasks && tasks?.count > 0) {
            tasks = tasks.rows;
            for (let key in tasks) {
              const formattedDate = moment(
                tasks[key].dueDate,
              ).format('YYYY-MM-DD');
              notificationService.Push_Notification(
                `Your task ${tasks[key].title} will due at ${formattedDate}, let's complete it before it's too late.`,
                '',
                false,
                PUSH_TYPE.USER_ID,
                advisor.id,
              );
            }
          }

          //events
          let events = await TenantService.tenant_List(
            'Events',
            null,
            null,
            null,
            null,
            currentTenant,
          );
          const now = moment();
          events.rows = events.rows?.filter((row) => {
            const startDatePart = moment(row.StartDate)
              .utc()
              .format('YYYY-MM-DD');
            const startTimePart = moment(row.StartTime)
              .utc()
              .format('HH:mm:ss.SSS');
            const combinedStartDateTime = `${startDatePart}T${startTimePart}Z`;
            const utcStartDateTime = moment.utc(
              combinedStartDateTime,
            );

            const endDatePart = moment(row.EndDate)
              .utc()
              .format('YYYY-MM-DD');
            const endTimePart = moment(row.EndTime)
              .utc()
              .format('HH:mm:ss.SSS');
            const combinedEndDateTime = `${endDatePart}T${endTimePart}Z`;
            const utcEndDateTime = moment.utc(
              combinedEndDateTime,
            );
            // return now.isSameOrBefore(utcStartDateTime, null);
            if (
              now.isSameOrBefore(utcStartDateTime, null)
            ) {
              const eventDate = moment(
                row.StartDate,
              ).startOf('day');
              const fiveDaysFromToday = moment()
                .add(5, 'days')
                .startOf('day');
              const threeDaysFromToday = moment()
                .add(3, 'days')
                .startOf('day');
              const oneDayFromToday = moment()
                .add(1, 'days')
                .startOf('day');

              return (
                eventDate.isSame(fiveDaysFromToday) ||
                eventDate.isSame(threeDaysFromToday) ||
                eventDate.isSame(oneDayFromToday)
              );
            }
          });

          if (events.rows && events.rows.length > 0) {
            events = events.rows;
            for (let key in events) {
              const formattedStartDate = moment(
                events[key].startDate,
              ).format('YYYY-MM-DD HH:mm:ss');
              const formattedStartTime = moment(
                events[key].StartTime,
              ).format('MMMM Do YYYY, h:mm:ss a');
              notificationService.Push_Notification(
                `Your event ${events[key].Title} will start on ${formattedStartDate} at ${formattedStartTime}! Be ready.`,
                '',
                false,
                PUSH_TYPE.USER_ID,
                advisor.id,
              );
            }
          }
        }
      } catch (error) {
        await service.signout();

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSendEmailAuthToken: (email) => async (dispatch) => {
    try {
      dispatch({ type: authActions.MFA_REQUEST_START });

      const token = await service.sendEmailAuthToken(email);

      dispatch({
        type: authActions.MFA_REQUEST_SUCCESS,
      });
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.MFA_REQUEST_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSigninWithSocial: (data) => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });

      let currentUser = null;
      let result = true;
      let queries = {};
      let user_queries = {};

      if (data.facebook_account !== null) {
        queries = {
          facebook_account: data.facebook_account,
        };
        user_queries = { email: data.facebook_account };
      }

      if (data.google_account !== null) {
        queries = { google_account: data.google_account };
        user_queries = { email: data.google_account };
      }

      let is_social_user = await Promise.all([
        service.list(queries, null, null, null),
      ]).then((response) => {
        return response[0].count > 0;
      });

      if (is_social_user) {
        const token = await service.doSigninWithSocial(
          data,
        );

        AuthToken.set(token, true);

        await dispatch(authActions.doRefreshCurrentUser());
        currentUser = await service.fetchMe();
      } else {
        result = false;
      }

      if (result) {
        dispatch({
          type: authActions.AUTH_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } else {
        dispatch({
          type: authActions.AUTH_ERROR,
          payload: null,
        });

        Message.error(
          'Account not created, please proceed to register.',
        );

        getHistory().push('/auth/signup');
      }
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSignout: () => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });
      await service.signout();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_ERROR,
      });
    }
  },

  doInit: () => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();
      }

      let remoteTenant,
        remoteUser,
        assistantPermission = null;
      if (
        window.location.pathname.length !== 1 &&
        !window.location.pathname.includes('auth')
      ) {
        let remote = AuthCurrentTenant.getRemote();

        if (remote?.isRemote === true) {
          let tenantNewRecord = await TenantService.find(
            remote.remoteTenant._id,
          );
          let tenantNewUser =
            await AuthService.authFetchByID(
              remote.remoteUser?._id,
            );

          remoteTenant = tenantNewRecord;
          remoteUser =
            tenantNewUser.rows ?? remote.remoteUser;

          let remotePlan = remoteTenant.plan;
          let accessList =
            await accessListService.listAllRemote(
              null,
              null,
              null,
              null,
              remote.remoteTenant._id,
            );

          let access = accessList.rows.filter((x) => {
            return (
              x.planPackage
                .toString()
                .trim()
                .toLowerCase() ===
              remotePlan.toString().trim().toLowerCase()
            );
          });

          if (access.length > 0) {
            assistantPermission = access[0];
          }
          let update_remote = {
            isRemote: true,
            platform: remote.platform,
            tenant: tenantNewRecord,
            user: remoteUser,
            assistantPermission: assistantPermission,
          };

          AuthCurrentTenant.setRemote(update_remote);
        }
      }

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
          remoteTenant,
          remoteUser,
          assistantPermission,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshRemote: (redirect) => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();
      }

      let remoteTenant,
        remoteUser,
        assistantPermission = null;
      if (
        window.location.pathname.length !== 1 &&
        !window.location.pathname.includes('auth')
      ) {
        let remote = AuthCurrentTenant.getRemote();

        if (remote?.isRemote === true) {
          let tenantNewRecord = await TenantService.find(
            remote.remoteTenant._id,
          );
          let tenantNewUser =
            await AuthService.authFetchByID(
              remote.remoteUser?._id,
            );

          remoteTenant = tenantNewRecord;
          remoteUser =
            tenantNewUser.rows ?? remote.remoteUser;

          let remotePlan = remoteTenant.plan;
          let accessList =
            await accessListService.listAllRemote(
              null,
              null,
              null,
              null,
              remote.remoteTenant._id,
            );

          let access = accessList.rows.filter((x) => {
            return (
              x.planPackage
                .toString()
                .trim()
                .toLowerCase() ===
              remotePlan.toString().trim().toLowerCase()
            );
          });

          if (access.length > 0) {
            assistantPermission = access[0];
          }
          let update_remote = {
            isRemote: true,
            platform: remote.platform,
            tenant: tenantNewRecord,
            user: remoteUser,
            assistantPermission: assistantPermission,
          };
          AuthCurrentTenant.setRemote(update_remote);
        }
      }

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
          remoteTenant,
          remoteUser,
          assistantPermission,
        },
      });

      if (redirect) {
        getHistory().push(redirect.path, redirect.params);
      }
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CURRENT_USER_REFRESH_START,
      });

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  do_Update_Profile:
    (data, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.UPDATE_PROFILE_START,
        });

        const response = await service.update_Profile(data);

        if (response?.error) {
          Message.error(response?.error, 'bottomRight');
          return response;
        }

        const currentUser = await service.fetchMe();

        dispatch({
          type: authActions.UPDATE_PROFILE_SUCCESS,
          payload: {
            currentUser,
          },
        });

        Message.success(i18n('auth.profile.success'));

        const remote = AuthCurrentTenant.getRemote();

        // if (remote) {
        //   getHistory().push(
        //     `/share-with-me/${remote.remoteTenant._id}`,
        //   );
        // } else {
        //   await dispatch(authActions.doRefreshCurrentUser());
        //   getHistory().push('/');
        // }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.UPDATE_PROFILE_ERROR,
        });
      }
    },

  do_Clone_Profile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CLONE_PROFILE_START,
      });

      const remote = AuthCurrentTenant.getRemote();
      const exisitng_email = await UserService.findByEmail(
        data.email,
      );

      if (exisitng_email) {
        Message.error(
          i18n('user.errors.userAlreadyExists'),
        );
      } else {
        const result = await service.clone_profile(data);

        if (result) {
          Message.success(
            i18n('auth.Clone.Success', data.fullName),
          );
        } else {
          Message.error(
            i18n('auth.Clone.Fail', data.fullName),
          );
        }
      }

      getHistory().push(
        `/share-with-me/${remote.remoteTenant._id}`,
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.CLONE_PROFILE_ERROR,
      });
    }
  },

  doUpdateProfile:
    (data, isRemote = false, openSnackbar) =>
    async (dispatch) => {
      try {
        dispatch({
          type: authActions.UPDATE_PROFILE_START,
        });

        await service.update_Profile(data);

        openSnackbar(
          i18n('auth.profile.success'),
          'success',
        );
        // Message.success(i18n('auth.profile.success'));

        if (!isRemote) {
          const currentUser = await service.fetchMe();
          dispatch(globalActions.doEditForm());
          dispatch({
            type: authActions.UPDATE_PROFILE_SUCCESS,
            payload: {
              currentUser,
            },
          });

          await dispatch(
            authActions.doRefreshCurrentUser(),
          );
          getHistory().push('/');
        } else {
          const remote = AuthCurrentTenant.getRemote();

          getHistory().push(
            `/share-with-me/${remote.remoteTenant._id}`,
          );
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.UPDATE_PROFILE_ERROR,
        });
      }
    },

  doUpdateLanding: (data) => async (dispatch) => {
    try {
      // await service.updateProfile(data);
      await service.changeLanding(data);

      // await dispatch(authActions.doRefreshCurrentUser());
    } catch (error) {
      Errors.handle(error);
    }
  },

  doUpdateFirstTimeComplete: (data) => async (dispatch) => {
    try {
      await service.updateProfile(data);
      // await dispatch(authActions.doRefreshCurrentUser());

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  doChangePassword:
    (oldPassword, newPassword) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.PASSWORD_CHANGE_START,
        });

        await service.changePassword(
          oldPassword,
          newPassword,
        );

        dispatch({
          type: authActions.PASSWORD_CHANGE_SUCCESS,
        });
        await dispatch(authActions.doRefreshCurrentUser());
        Message.success(
          i18n('auth.passwordChange.success'),
        );
        getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.PASSWORD_CHANGE_ERROR,
        });
      }
    },

  doSocialAccount: (newPassword) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CREATE_ACCOUNT_START,
      });

      await service.create_social_account(newPassword);

      dispatch({
        type: authActions.CREATE_ACCOUNT_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      Message.success(
        i18n('auth.Create_Local_Account.success'),
      );
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.CREATE_ACCOUNT_ERROR,
      });
    }
  },

  doVerifyEmail:
    (token, fullAuthorize, assist, signup_mode) =>
    async (dispatch, getState) => {
      try {
        const isLoading =
          authSelectors.selectLoadingVerifyEmail(
            getState(),
          );

        if (isLoading) {
          return;
        }

        dispatch({
          type: authActions.EMAIL_VERIFY_START,
        });

        await service.verifyEmail(
          token,
          fullAuthorize,
          assist,
          signup_mode,
        );

        await dispatch(authActions.doRefreshCurrentUser());

        dispatch({
          type: authActions.EMAIL_VERIFY_SUCCESS,
        });

        //getHistory().push('/');
        await service.signout();
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: authActions.EMAIL_VERIFY_ERROR,
        });
        getHistory().push('/');
      }
    },

  doResetPassword:
    (token, password) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.PASSWORD_RESET_START,
        });

        await service.passwordReset(token, password);

        Message.success(i18n('auth.passwordResetSuccess'));
        dispatch({
          type: authActions.PASSWORD_RESET_SUCCESS,
        });
        getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.PASSWORD_RESET_ERROR,
        });

        dispatch(authActions.doSignout());
        getHistory().push('/');
      }
    },

  doSelectTenant: (tenant) => async (dispatch) => {
    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url);
      return;
    }

    const currentUser = await service.fetchMe();

    if (currentUser.id !== tenant.createdBy) {
      let assistant =
        await AssistantService.findByUserIDAndTestatorID(
          currentUser.id,
          tenant.createdBy,
        );
      assistant['currentUser'] = currentUser;
      assistant['tenant'] = tenant;
      AuthCurrentTenant.setAssistant(assistant);

      const userSetting =
        await UserSettingsService.findById(
          tenant.createdBy,
        );
      AuthCurrentTenant.setRemoteSetting(userSetting);
    } else if (currentUser.id === tenant.createdBy) {
      AuthCurrentTenant.clearAssistant();
      AuthCurrentTenant.cleaRemoteSetting();
    }

    AuthCurrentTenant.set(tenant);
    await dispatch(
      authActions.doRefreshTenant(
        AuthCurrentTenant.getTenant(),
      ),
    );
    await dispatch(authActions.doRefreshCurrentUser());
    SettingsService.applyThemeFromTenant();

    getHistory().push(
      currentUser.id !== tenant.createdBy
        ? '/willListPage'
        : '/',
    );

    window.location.reload();
  },

  doResetTenant: (currentUser) => async (dispatch) => {
    const tenant = await TenantService.find(
      currentUser.tenants[0].tenant?.id,
    );

    AuthCurrentTenant.clearAssistant();
    AuthCurrentTenant.cleaRemoteSetting();
    AuthCurrentTenant.set(tenant);
    await dispatch(
      authActions.doRefreshTenant(
        AuthCurrentTenant.getTenant(),
      ),
    );

    await dispatch(authActions.doRefreshCurrentUser());
    SettingsService.applyThemeFromTenant();
    getHistory().push(
      currentUser.id !== tenant.createdBy
        ? '/willListPage'
        : '/',
    );
  },

  doChangeMfaSetting:
    (id, value, email) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.MFA_CHANGE_START,
        });

        await service.changeMfaSetting(id, value, email);

        dispatch({
          type: authActions.MFA_CHANGE_SUCCESS,
        });
        await dispatch(authActions.doRefreshCurrentUser());
        Message.success(i18n('auth.security.success'));
        // getHistory().push('/');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.MFA_CHANGE_ERROR,
        });
      }
    },

  doSendUnlockRequest: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_START,
      });

      let result = await service.vault_send_unlock_request(
        data,
      );

      if (result.new_request === true) {
        Message.success(
          i18n('auth.unlockRequestEmailSuccess'),
        );
      } else if (result.can_open_vault === true) {
        Message.success(
          i18n('auth.vault.unlock_successful'),
        );
        // getHistory().go(0);
      } else {
        Message.success(
          i18n(
            'auth.vault.unlock_inprogress',
            result.remaining_days,
          ),
        );
      }

      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: authActions.UNLOCK_REQUEST_EMAIL_ERROR,
      });
    }
  },

  doRefreshTenant: (tenant) => async (dispatch) => {
    dispatch({
      type: authActions.REFRESH_TENANT_START,
    });

    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url);
      return;
    }

    const currentUser = await service.fetchMe();

    if (currentUser.id !== tenant.createdBy) {
      let assistant =
        await AssistantService.findByUserIDAndTestatorID(
          currentUser.id,
          tenant.createdBy,
        );
      assistant['currentUser'] = currentUser;
      assistant['tenant'] = tenant;
      AuthCurrentTenant.setAssistant(assistant);
    }

    // AuthCurrentTenant.set(tenant);
    // await dispatch(authActions.doRefreshCurrentUser());
    // SettingsService.applyThemeFromTenant();

    let currentMode = localStorage.getItem('willMode');

    //BC18042024 - whats the purpose of this?
    // if (currentUser.id !== tenant.createdBy) {
    //   if (currentMode !== tenant.userSettingsWillMode) {
    //     localStorage.setItem(
    //       'willMode',
    //       tenant.userSettingsWillMode,
    //     );
    //   }
    // } else {
    //   var userTenant =
    //     currentUser.tenants[0].tenant ?? ({} as any);
    //   if (currentMode !== userTenant.userSettingsWillMode) {
    //     localStorage.setItem(
    //       'willMode',
    //       userTenant.userSettingsWillMode,
    //     );
    //   }
    // }

    dispatch({
      type: authActions.REFRESH_TENANT_SUCCESS,
      payload: {
        user: currentUser,
        tenant: tenant,
      },
    });
  },
  doResendEmail:
    (email, target_type, method) => async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START });

        await service.sendEmail(email, target_type, method);
        switch (method) {
          case 'invite':
            Message.success(
              i18n('user.new_invitation.resend'),
            );
            break;
          case 'fullaccess':
            Message.success(
              i18n('user.new_invitation.unlockRequest'),
            );
            break;
        }
      } catch (error) {
        await service.signout();
        console.log('Invitation error', error);

        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: authActions.AUTH_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },

  doSelectRemoteTenant:
    (tenantId, testator_id = null, remain_page = false) =>
    async (dispatch) => {
      dispatch({ type: authActions.REMOTE_USER_START });
      let tenant = await TenantService.find(tenantId);

      let currentUser = await AuthService.fetchMe();
      let user = await AuthService.fetchUser(
        tenant.id,
        tenant.name,
      );

      let remotePlan = tenant.plan;
      let assistantPermission = null;

      localStorage.setItem(
        'willMode',
        tenant?.userSettingsWillMode
          ? tenant?.userSettingsWillMode
          : 'conventional',
      );
      if (tenant?.id) {
        let accessList =
          await accessListService.listAllRemote(
            null,
            null,
            null,
            null,
            tenant.id,
          );

        let access = accessList.rows.filter((x) => {
          return (
            x.planPackage
              .toString()
              .trim()
              .toLowerCase() ===
            remotePlan.toString().trim().toLowerCase()
          );
        });

        if (access.length > 0) {
          assistantPermission = access[0];
        }

        let assistant =
          await AssistantService.findByUserIDAndTestatorID(
            currentUser.id,
            testator_id ? testator_id : tenant.createdBy,
          );

        assistant['currentUser'] = currentUser;
        assistant['tenant'] = tenant;
        AuthCurrentTenant.setAssistant(assistant);
      }

      var oldremote = AuthCurrentTenant.getRemote();

      let remote = {
        isRemote: true,
        tenant: tenant,
        user: user.count > 0 ? user.rows[0] : null,
        assistantPermission: assistantPermission,

        platform: oldremote?.platform
          ? oldremote?.platform
          : tenant?.userSettingsWillMode === 'wasiat'
          ? 'Wasiat'
          : i18n('app.willTestament'),
      };
      AuthCurrentTenant.setRemote(remote);

      getHistory().push(`/share-with-me/${tenant.id}`);

      dispatch({
        type: authActions.REMOTE_USER_SUCCESS,
        payload: {
          user: user.count > 0 ? user.rows[0] : null,
          tenant: tenant,
          assistantPermission: assistantPermission,
        },
      });
    },

  doUpdateProfileOnly: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PROFILE_START,
      });

      await service.updateProfile(data);
      const currentUser = await service.fetchMe();

      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
        payload: {
          currentUser,
        },
      });

      Message.success(i18n('auth.profile.success'));

      const remote = AuthCurrentTenant.getRemote();
      if (remote) {
      } else {
        await dispatch(authActions.doRefreshCurrentUser());
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doRefreshRemoteTenant:
    (tenantId, testator_id = null) =>
    async (dispatch) => {
      let tenant = await TenantService.find(tenantId);

      let currentUser = await AuthService.fetchMe();
      let user = await AuthService.fetchUser(
        tenant.id,
        tenant.name,
      );

      let remotePlan = tenant.plan;
      let assistantPermission = null;

      if (tenant?.id) {
        //#region AccessList
        let accessList =
          await accessListService.listAllRemote(
            null,
            null,
            null,
            null,
            tenant.id,
          );

        let access = accessList.rows.filter((x) => {
          return (
            x.planPackage
              .toString()
              .trim()
              .toLowerCase() ===
            remotePlan.toString().trim().toLowerCase()
          );
        });

        if (access.length > 0) {
          assistantPermission = access[0];
        }
        //#endregion

        let assistant =
          await AssistantService.findByUserIDAndTestatorID(
            currentUser.id,
            testator_id ? testator_id : tenant.createdBy,
          );

        assistant['currentUser'] = currentUser;
        assistant['tenant'] = tenant;
        AuthCurrentTenant.setAssistant(assistant);
      }

      var oldremote = AuthCurrentTenant.getRemote();
      let remote = {
        isRemote: true,
        tenant: tenant,
        user: user.count > 0 ? user.rows[0] : null,
        assistantPermission: assistantPermission,
        platform: oldremote.platform
          ? oldremote.platform
          : tenant?.userSettingsWillMode === 'wasiat'
          ? 'Wasiat'
          : i18n('app.willTestament'),
      };
      AuthCurrentTenant.setRemote(remote);

      dispatch({
        type: authActions.REMOTE_USER_SUCCESS,
        payload: {
          user: user.count > 0 ? user.rows[0] : null,
          tenant: tenant,
          assistantPermission: assistantPermission,
        },
      });
      // }
    },

  doUpdateProfileSigning:
    (data, tenantId) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.UPDATE_PROFILE_START,
        });

        var response = await service.update_Profile(data);

        Message.success(i18n('auth.profile.success'));

        await dispatch(
          authActions.doRefreshRemoteTenant(
            tenantId,
            data.id,
          ),
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.UPDATE_PROFILE_ERROR,
        });
      }
    },

  doVerifyKenaleKYC: () => async (dispatch) => {
    try {
      var response = await service.verifyKenalEKYC();

      if (response)
        window.open(
          response.fullURL,
          '_blank',
          'noopener,noreferrer',
        );
    } catch (error) {
      Errors.handle(error);
    }
  },

  doRefreshKenaleKYC:
    (openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: authActions.REFRESH_EKYC,
          payload: {
            eKYCVerificationStatus: 'In Progress',
          },
        });

        var response = await service.getKenalEKYCStatus();

        if (response) {
          dispatch({
            type: authActions.REFRESH_EKYC,
            payload: {
              eKYCVerificationStatus:
                response.eKYCVerificationStatus,
            },
          });
        } else {
          dispatch({
            type: authActions.REFRESH_EKYC,
            payload: {
              eKYCVerificationStatus: 'Invalid',
            },
          });
        }

        openSnackbar(
          i18n('eKYC.refreshProcessFinishMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: authActions.REFRESH_EKYC,
          payload: {
            eKYCVerificationStatus: 'Invalid',
          },
        });
      }
    },
};

export default authActions;
