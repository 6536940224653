import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import tenantFormActions from 'src/modules/tenant/form/tenantFormActions';

const tenantId = AuthCurrentTenant.get();

const prefix = 'WIDGETS_NOTE';

const noteActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  DELETE_STARTED: `${prefix}_DELETE_STARTED`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,

  doInit: (remoteUserId, showAll?) => async (dispatch) => {
    try {
      dispatch({
        type: noteActions.INIT_STARTED,
      });

      const record = await TenantService.tenant_List(
        'Notes',
        null,
        null,
        null,
        null,
        tenantId,
      );

      if (remoteUserId) {
        record.rows = record.rows?.filter(
          (row) => row.Client.id === remoteUserId,
        );
      }
      if (!showAll) {
        record.rows = record.rows?.slice(0, 10);
      }

      dispatch({
        type: noteActions.INIT_SUCCESS,
        payload: record.rows,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: noteActions.INIT_ERROR,
      });
    }
  },

  doCreate:
    (values, openSnackbar, showAll?) =>
    async (dispatch) => {
      try {
        dispatch({
          type: noteActions.CREATE_STARTED,
        });

        let record = await TenantService.Update_Tenant_Data(
          values,
          false,
        );

        if (values.remoteUserId) {
          record = record.filter(
            (row) => row.Client.id === values.remoteUserId,
          );
        }

        if (!showAll) {
          record = record?.slice(0, 10);
        }

        dispatch({
          type: noteActions.CREATE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('widgets.note.createMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: noteActions.CREATE_ERROR,
        });
      }
    },

  doUpdate:
    (values, openSnackbar, showAll?) =>
    async (dispatch) => {
      try {
        dispatch({
          type: noteActions.UPDATE_STARTED,
        });

        let record = await TenantService.Update_Tenant_Data(
          values,
          false,
        );

        if (values.remoteUserId) {
          record = record.filter(
            (row) => row.Client.id === values.remoteUserId,
          );
        }

        if (!showAll) {
          record = record?.slice(0, 10);
        }

        dispatch({
          type: noteActions.UPDATE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('widgets.note.updateMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: noteActions.UPDATE_ERROR,
        });
      }
    },

  doDelete:
    (values, openSnackbar, showAll?) =>
    async (dispatch) => {
      try {
        dispatch({
          type: noteActions.DELETE_STARTED,
        });

        let record = await TenantService.Delete_Tenant_Data(
          values,
        );

        if (!showAll) {
          record = record?.slice(0, 10);
        }

        dispatch({
          type: noteActions.DELETE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('widgets.note.deleteMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: noteActions.DELETE_ERROR,
        });
      }
    },
};

export default noteActions;
