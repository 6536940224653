// import config from 'src/config';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  // {
  //   path: '/',
  //   // loader: () => import('src/view/dashboard/DashboardPage'),
  //   loader: () =>
  //     import(
  //       'src/view/contactBase/list/ContactBaseListPage'
  //     ),
  //   permissionRequired: null,
  //   exact: true,
  //   section: 'contactBase',
  // },
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/task/listing',
    loader: () =>
      import('src/view/dashboard/task/Task_New_Listing'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/signup-report',
    loader: () =>
      import('src/view/referralReport/SignUpReportPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/subscription-report',
    loader: () =>
      import(
        'src/view/referralReport/SubscriptionReportPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/my-income',
    loader: () =>
      import('src/view/finance/income/list/Income_Page'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/income_statement_list',
    loader: () =>
      import(
        'src/view/finance/income/list/Income_Statement_List'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/income_statement',
    loader: () =>
      import(
        'src/view/finance/income/list/Income_Statement'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/bank-accounts',
    loader: () =>
      import(
        'src/view/finance/bank_accounts/list/Bank_Accounts_Page'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sales-report',
    loader: () =>
      import('src/view/referralReport/SalesReportPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sales-performance',
    loader: () =>
      import('src/view/performance/salesPerformance'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/docview',
    loader: () =>
      import('src/view/docViewer/DocViewerPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/wallet',
    loader: () =>
      import('src/view/wallet/view/WalletViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/wallet/:id',
    loader: () =>
      import('src/view/wallet/form/WalletFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/customer-base',
    loader: () =>
      import(
        'src/view/customerBase/list/CustomerBaseListPage'
      ),
    permissionRequired: null,
    exact: true,
    section: 'customerBase',
  },
  {
    path: '/customer-assetslist',
    loader: () =>
      import('src/view/customerBase/list/AssetListPage'),
    permissionRequired: null,
    exact: true,
    section: 'customerBase',
  },
  {
    path: '/contact-base',
    loader: () =>
      import(
        'src/view/contactBase/list/ContactBaseListPage'
      ),
    permissionRequired: null,
    exact: true,
    section: 'contactBase',
  },
  {
    path: '/contact-base/new',
    loader: () =>
      import(
        'src/view/contactBase/form/ContactBaseFormPage'
      ),
    exact: true,
    section: 'contactBase',
  },
  {
    path: '/contact-base/:id/edit',
    loader: () =>
      import(
        'src/view/contactBase/form/ContactBaseFormPage'
      ),
    exact: true,
    section: 'contactBase',
  },
  {
    path: '/contact-base/:id',
    loader: () =>
      import(
        'src/view/contactBase/view/ContactBaseViewPage'
      ),
    exact: true,
    section: 'contactBase',
  },
  {
    path: '/coupon-generator',
    loader: () =>
      import(
        'src/view/couponGenerator/list/CouponGeneratorListPage'
      ),
    permissionRequired: null,
    exact: true,
    section: 'couponGenerator',
  },
  {
    path: '/coupon-generator/new',
    loader: () =>
      import(
        'src/view/couponGenerator/form/CouponGeneratorFormPage'
      ),
    exact: true,
    section: 'couponGenerator',
  },
  {
    path: '/coupon-generator/:id/edit',
    loader: () =>
      import(
        'src/view/couponGenerator/form/CouponGeneratorFormPage'
      ),
    exact: true,
    section: 'couponGenerator',
  },
  {
    path: '/coupon-generator/:id',
    loader: () =>
      import(
        'src/view/couponGenerator/view/CouponGeneratorViewPage'
      ),
    exact: true,
    section: 'couponGenerator',
  },
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
    section: 'profile',
  },
  {
    path: '/profile/:id',
    loader: () =>
      import('src/view/remote/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/user-settings',
    loader: () =>
      import('src/view/userSettings/UserSettingsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/customers',
    loader: () =>
      import('src/view/user/view2/UserViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/notes',
    loader: () =>
      import('src/view/widgets/note/AllNotesPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/events',
    loader: () =>
      import('src/view/widgets/event/AllEventsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/user',
    loader: () =>
      import('src/view/user/view2/UserViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/user/new-invitation',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/prospect/user/new-invitation/:id',
    loader: () =>
      import(
        'src/view/contactBase/form/ContactBaseNewUserPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/prospect/user/new-invitation',
    loader: () =>
      import(
        'src/view/contactBase/form/ContactBaseNewUserPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: null,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: null,
  },

  {
    path: '/notification',
    loader: () =>
      import('src/view/notification/list/NotificationPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/subscription',
    loader: () =>
      import('src/view/subscription/Subscription_Page'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/resources',
    loader: () =>
      import('src/view/resources/ResourcesPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/academy',
    loader: () => import('src/view/academy/AcademyPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/share-with-me',
    loader: () =>
      import('src/view/tenant/list/SharedWithMeListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/share-with-me/:id/edit',
    loader: () =>
      import('src/view/tenant/list/SharedWithMeListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/share-with-me/:id',
    loader: () =>
      import('src/view/remote/workspace/WorkspacePage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/my-vault',
    loader: () =>
      import(
        'src/view/remote/assistant/list/MyVaultListPage'
      ),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/custody-view',
    loader: () =>
      import(
        'src/view/remote/custody/view/CustodyViewPage'
      ),
  },
  {
    path: '/my-vault/:id/edit',
    loader: () =>
      import(
        'src/view/remote/assistant/list/MyVaultListPage'
      ),
    permissionRequired: permissions.userRead,
    //permissionRequired: permissions.assistantEdit,
    exact: true,
  },
  {
    path: '/my-vault/:id/view',
    loader: () =>
      import(
        'src/view/remote/assistant/view/MyVaultViewPage'
      ),
    permissionRequired: permissions.userRead,
    //permissionRequired: permissions.assistantEdit,
    exact: true,
  },

  //#region Account
  {
    path: '/account',
    loader: () =>
      import(
        'src/view/remote/account/list/AccountListPage'
      ),
    permissionRequired: permissions.accountRead,
    exact: true,
    // step: 4,
    section: 'account',
  },
  {
    path: '/account/new',
    loader: () =>
      import(
        'src/view/remote/account/form/AccountFormPage'
      ),
    permissionRequired: permissions.accountCreate,
    exact: true,
    section: 'account',
  },
  {
    path: '/account/:id/edit',
    loader: () =>
      import(
        'src/view/remote/account/form/AccountFormPage'
      ),
    permissionRequired: permissions.accountEdit,
    exact: true,
    section: 'account',
  },
  {
    path: '/account/:id',
    loader: () =>
      import(
        'src/view/remote/account/view/AccountViewPage'
      ),
    permissionRequired: permissions.accountRead,
    exact: true,
    section: 'account',
  },
  //#endregion

  //#region Insurance
  {
    path: '/insurance',
    loader: () =>
      import(
        'src/view/remote/insurance/list/InsuranceListPage'
      ),
    permissionRequired: permissions.insuranceRead,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/new',
    loader: () =>
      import(
        'src/view/remote/insurance/form/InsuranceFormPage'
      ),
    permissionRequired: permissions.insuranceCreate,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/:id/edit',
    loader: () =>
      import(
        'src/view/remote/insurance/form/InsuranceFormPage'
      ),
    permissionRequired: permissions.insuranceEdit,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/:id',
    loader: () =>
      import(
        'src/view/remote/insurance/view/InsuranceViewPage'
      ),
    permissionRequired: permissions.insuranceRead,
    exact: true,
    section: 'insurance',
  },

  //#endregion

  //#region Property
  {
    path: '/property',
    loader: () =>
      import(
        'src/view/remote/property/list/PropertyListPage'
      ),
    permissionRequired: permissions.propertyRead,
    exact: true,
    // step: 6,
    section: 'property',
  },
  {
    path: '/property/new',
    loader: () =>
      import(
        'src/view/remote/property/form/PropertyFormPage'
      ),
    permissionRequired: permissions.propertyCreate,
    exact: true,
    section: 'property',
  },
  {
    path: '/property/:id/edit',
    loader: () =>
      import(
        'src/view/remote/property/form/PropertyFormPage'
      ),
    permissionRequired: permissions.propertyEdit,
    exact: true,
    section: 'property',
  },
  {
    path: '/property/:id',
    loader: () =>
      import(
        'src/view/remote/property/view/PropertyViewPage'
      ),
    permissionRequired: permissions.propertyRead,
    exact: true,
    section: 'property',
  },
  //#endregion

  //#region Location

  {
    path: '/location',
    loader: () =>
      import(
        'src/view/remote/location/list/LocationListPage'
      ),
    permissionRequired: permissions.locationRead,
    exact: true,
  },

  {
    path: '/location/new',
    loader: () =>
      import(
        'src/view/remote/location/form/LocationFormPage'
      ),
    permissionRequired: permissions.locationCreate,
    exact: true,
  },
  {
    path: '/location/:id/edit',
    loader: () =>
      import(
        'src/view/remote/location/form/LocationFormPage'
      ),
    permissionRequired: permissions.locationEdit,
    exact: true,
  },
  {
    path: '/location/:id',
    loader: () =>
      import(
        'src/view/remote/location/view/LocationViewPage'
      ),
    permissionRequired: permissions.locationRead,
    exact: true,
  },

  //#endregion

  //#region Vehicle
  {
    path: '/vehicle',
    loader: () =>
      import(
        'src/view/remote/vehicle/list/VehicleListPage'
      ),
    permissionRequired: permissions.vehicleRead,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/new',
    loader: () =>
      import(
        'src/view/remote/vehicle/form/VehicleFormPage'
      ),
    permissionRequired: permissions.vehicleCreate,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/:id/edit',
    loader: () =>
      import(
        'src/view/remote/vehicle/form/VehicleFormPage'
      ),
    permissionRequired: permissions.vehicleEdit,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/:id',
    loader: () =>
      import(
        'src/view/remote/vehicle/view/VehicleViewPage'
      ),
    permissionRequired: permissions.vehicleRead,
    exact: true,
    section: 'vehicle',
  },
  //#endregion

  //#region Other Asset
  {
    path: '/other-asset',
    loader: () =>
      import(
        'src/view/remote/otherAsset/list/OtherAssetListPage'
      ),
    permissionRequired: permissions.otherAssetRead,
    exact: true,
    // step: 7,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/new',
    loader: () =>
      import(
        'src/view/remote/otherAsset/form/OtherAssetFormPage'
      ),
    permissionRequired: permissions.otherAssetCreate,
    exact: true,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/:id/edit',
    loader: () =>
      import(
        'src/view/remote/otherAsset/form/OtherAssetFormPage'
      ),
    permissionRequired: permissions.otherAssetEdit,
    exact: true,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/:id',
    loader: () =>
      import(
        'src/view/remote/otherAsset/view/OtherAssetViewPage'
      ),
    permissionRequired: permissions.otherAssetRead,
    exact: true,
    section: 'otherAsset',
  },
  //#endregion

  //#region Digital
  {
    path: '/digital',
    loader: () =>
      import(
        'src/view/remote/digital/list/DigitalListPage'
      ),
    permissionRequired: permissions.digitalRead,
    exact: true,
    // step: 7,
    section: 'digital',
  },
  {
    path: '/digital/new',
    loader: () =>
      import(
        'src/view/remote/digital/form/DigitalFormPage'
      ),
    permissionRequired: permissions.digitalCreate,
    exact: true,
    section: 'digital',
  },
  {
    path: '/digital/:id/edit',
    loader: () =>
      import(
        'src/view/remote/digital/form/DigitalFormPage'
      ),
    permissionRequired: permissions.digitalEdit,
    exact: true,
    section: 'digital',
  },
  {
    path: '/digital/:id',
    loader: () =>
      import(
        'src/view/remote/digital/view/DigitalViewPage'
      ),
    permissionRequired: permissions.digitalRead,
    exact: true,
    section: 'digital',
  },

  //#endregion

  //#region Debt
  {
    path: '/debt',
    loader: () =>
      import('src/view/remote/debt/list/DebtListPage'),
    permissionRequired: permissions.debtRead,
    exact: true,
    // step: 5,
    section: 'debt',
  },
  {
    path: '/debt/new',
    loader: () =>
      import('src/view/remote/debt/form/DebtFormPage'),
    permissionRequired: permissions.debtCreate,
    exact: true,
    section: 'debt',
  },
  {
    path: '/debt/:id/edit',
    loader: () =>
      import('src/view/remote/debt/form/DebtFormPage'),
    permissionRequired: permissions.debtEdit,
    exact: true,
    section: 'debt',
  },
  {
    path: '/debt/:id',
    loader: () =>
      import('src/view/remote/debt/view/DebtViewPage'),
    permissionRequired: permissions.debtRead,
    exact: true,
    section: 'debt',
  },
  //#endregion

  //#region Pledge
  {
    path: '/pledge',
    loader: () =>
      import('src/view/remote/pledge/list/PledgeListPage'),
    permissionRequired: permissions.pledgeRead,
    exact: true,
    step: 8,
    section: 'pledge',
  },
  {
    path: '/pledge/new',
    loader: () =>
      import('src/view/remote/pledge/form/PledgeFormPage'),
    permissionRequired: permissions.pledgeCreate,
    exact: true,
    section: 'pledge',
  },
  {
    path: '/pledge/:id/edit',
    loader: () =>
      import('src/view/remote/pledge/form/PledgeFormPage'),
    permissionRequired: permissions.pledgeEdit,
    exact: true,
    section: 'pledge',
  },
  {
    path: '/pledge/:id',
    loader: () =>
      import('src/view/remote/pledge/view/PledgeViewPage'),
    permissionRequired: permissions.pledgeRead,
    exact: true,
    section: 'pledge',
  },

  //#endregion

  //#region Health Info
  {
    path: '/health-info',
    loader: () =>
      import(
        'src/view/remote/healthInfo/list/HealthInfoListPage'
      ),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/new',
    loader: () =>
      import(
        'src/view/remote/healthInfo/form/HealthInfoFormPage'
      ),
    permissionRequired: permissions.healthInfoCreate,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id/edit',
    loader: () =>
      import(
        'src/view/remote/healthInfo/form/HealthInfoFormPage'
      ),
    permissionRequired: permissions.healthInfoEdit,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id',
    loader: () =>
      import(
        'src/view/remote/healthInfo/view/HealthInfoViewPage'
      ),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id/qr-code',
    loader: () =>
      import(
        'src/view/remote/emergencyCards/public/EmergencyCardQRCode'
      ),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },

  // #region Asset Distribution
  {
    path: '/asset-distribution',
    loader: () =>
      import(
        'src/view/remote/assetDistribution/DistributionFormPage'
      ),
    permissionRequired: false,
    exact: true,
    section: 'assetsDistribution',
  },

  //#endregion

  {
    path: '/profile-assistant',
    loader: () =>
      import(
        'src/view/remote/auth/ProfileAssistantFormPage'
      ),
    permissionRequired: null,
    exact: true,
    section: 'profile',
  },

  {
    path: '/willListPage',
    loader: () =>
      import('src/view/remote/dashboard/BasicWillListPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/will-preview',
    loader: () =>
      import('src/view/remote/dashboard/WillPreview'),
    permissionRequired: null,
    exact: true,
  },

  //#region  Benefiaciary
  {
    path: '/beneficiary',
    loader: () =>
      import(
        'src/view/remote/beneficiary/list/BeneficiaryListPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/new',
    loader: () =>
      import(
        'src/view/remote/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryCreate,
    exact: true,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/:id',
    loader: () =>
      import(
        'src/view/remote/beneficiary/view/BeneficiaryViewPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    section: 'beneficiary',
  },

  //#endregion

  //#region Contact List
  {
    path: '/contact-list',
    loader: () =>
      import(
        'src/view/remote/contactList/list/ContactListListPage'
      ),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/new',
    loader: () =>
      import(
        'src/view/remote/contactList/form/ContactListFormPage'
      ),
    permissionRequired: permissions.contactListCreate,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/:id/edit',
    loader: () =>
      import(
        'src/view/remote/contactList/form/ContactListFormPage'
      ),
    permissionRequired: permissions.contactListEdit,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/:id',
    loader: () =>
      import(
        'src/view/remote/contactList/view/ContactListViewPage'
      ),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'contactList',
  },

  //#endregion

  //#region Pet
  {
    path: '/pet',
    loader: () =>
      import('src/view/remote/pet/list/PetListPage'),
    permissionRequired: permissions.petRead,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/new',
    loader: () =>
      import('src/view/remote/pet/form/PetFormPage'),
    permissionRequired: permissions.petCreate,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/:id/edit',
    loader: () =>
      import('src/view/remote/pet/form/PetFormPage'),
    permissionRequired: permissions.petEdit,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/:id',
    loader: () =>
      import('src/view/remote/pet/view/PetViewPage'),
    permissionRequired: permissions.petRead,
    exact: true,
    section: 'pet',
  },
  //#endregion

  //#region Legal Guardian
  {
    path: '/legal-guardian/new',
    loader: () =>
      import(
        'src/view/remote/guardians/form/GuardiansFormPage'
      ),
    permissionRequired: permissions.legalGuardianCreate,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },
  {
    path: '/legal-guardian/:id/edit',
    loader: () =>
      import(
        'src/view/remote/guardians/form/GuardiansFormPage'
      ),
    permissionRequired: permissions.legalGuardianEdit,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },
  //#endregion

  //#region Executor
  {
    path: '/executors/new',
    loader: () =>
      import(
        'src/view/remote/executor/main/ExecutorsFormPage'
      ),
    permissionRequired: permissions.executorCreate,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executors/:id/edit',
    loader: () =>
      import(
        'src/view/remote/executor/main/ExecutorsFormPage'
      ),
    permissionRequired: permissions.executorEdit,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor/:id/edit',
    loader: () =>
      import(
        'src/view/remote/executor/form/ExecutorFormPage'
      ),
    permissionRequired: permissions.executorEdit,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor/:id',
    loader: () =>
      import(
        'src/view/remote/executor/view/ExecutorViewPage'
      ),
    permissionRequired: permissions.executorRead,
    exact: true,
    section: 'executor',
  },

  //#endregion

  //#region Residuary
  {
    path: '/residuary/new',
    loader: () =>
      import(
        'src/view/remote/residuary/form/ResiduaryFormPage'
      ),
    permissionRequired: permissions.residuaryCreate,
    exact: true,
    step: 3,
    section: 'distribution',
  },
  {
    path: '/residuary/:id/edit',
    loader: () =>
      import(
        'src/view/remote/residuary/form/ResiduaryFormPage'
      ),
    permissionRequired: permissions.residuaryEdit,
    exact: true,
    step: 3,
    section: 'distribution',
  },
  //#endregion

  //#region Last Instruction
  {
    path: '/last-instructions',
    loader: () =>
      import(
        'src/view/remote/lastInstructions/list/LastInstructionsListPage'
      ),
    permissionRequired: permissions.lastInstructionsRead,
    exact: true,
    step: 7,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/new',
    loader: () =>
      import(
        'src/view/remote/lastInstructions/form/LastInstructionsFormPage'
      ),
    permissionRequired: permissions.lastInstructionsCreate,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/:id/edit',
    loader: () =>
      import(
        'src/view/remote/lastInstructions/form/LastInstructionsFormPage'
      ),
    permissionRequired: permissions.lastInstructionsEdit,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/:id',
    loader: () =>
      import(
        'src/view/remote/lastInstructions/view/LastInstructionsViewPage'
      ),
    permissionRequired: permissions.lastInstructionsRead,
    exact: true,
    section: 'lastInstructions',
  },

  //#endregion

  //#region Witness
  {
    path: '/witnesss/new',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witnesss/:id/edit',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness/:id',
    loader: () =>
      import(
        'src/view/remote/witness/view/WitnessViewPage'
      ),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  //#endregion
  {
    path: '/financial-planning/:id/edit',
    loader: () =>
      import(
        'src/view/remote/financialPlanning/form/FinancialPlanningFormPage'
      ),
    permissionRequired: permissions.financialPlanningEdit,
    exact: true,
    section: 'financialPlanning',
  },
  {
    path: '/financial-planning',
    loader: () =>
      import(
        'src/view/remote/financialPlanning/form/FinancialPlanningFormPage'
      ),
    permissionRequired: permissions.financialPlanningCreate,
    exact: true,
    section: 'financialPlanning',
  },
  {
    path: '/specific-gift',
    loader: () =>
      import(
        'src/view/remote/specificGift/list/SpecificGiftListPage'
      ),
    permissionRequired: permissions.specificGiftRead,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift/new',
    loader: () =>
      import(
        'src/view/remote/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftCreate,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift/:id/edit',
    loader: () =>
      import(
        'src/view/remote/specificGift/form/SpecificGiftFormPage'
      ),
    // import('src/view/remote/specificGift/main/SpecificGiftFormPage'),
    permissionRequired: permissions.specificGiftEdit,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/my-family-tree',
    loader: () =>
      import(
        'src/view/remote/familyTree/page/FamilyTreePages'
      ),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'myFamilyTree',
  },
  {
    path: '/family-tree',
    loader: () =>
      import('src/view/remote/dashboard/shared/FamilyTree'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/faraid-calculator',
    loader: () =>
      import(
        'src/view/remote/faraidCalculator/page/FaraidCalculatorPage'
      ),
    exact: true,
  },
  {
    path: '/my-heir',
    loader: () =>
      import('src/view/remote/heir/list/HeirListPage'),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'myHeir',
  },
  {
    path: '/my-account',
    loader: () => import('src/view/auth/MyAccountPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/performance',
    loader: () =>
      import('src/view/performance/PerformancePage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/payout-reconciliation',
    loader: () =>
      import(
        'src/view/performance/PayoutReconciliationReportPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/trustee/:id',
    loader: () =>
      import('src/view/remote/trustee/TrusteeFormPage'),
    permissionRequired: permissions.trusteeRead,
    exact: true,
    section: 'Trustee',
  },

  //#region Hibah -Witness
  {
    path: '/witnesss_hibah/new',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witnesss_hibah/:id/edit',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_hibah/:id',
    loader: () =>
      import(
        'src/view/remote/witness/view/WitnessViewPage'
      ),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  //#endregion
   //#region Executor-Hibah
   {
    path: '/executors_hibah/new',
    loader: () =>
      import(
        'src/view/remote/executor/main/ExecutorsFormPage'
      ),
    permissionRequired: permissions.executorCreate,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executors_hibah/:id/edit',
    loader: () =>
      import(
        'src/view/remote/executor/main/ExecutorsFormPage'
      ),
    permissionRequired: permissions.executorEdit,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor_hibah/:id/edit',
    loader: () =>
      import(
        'src/view/remote/executor/form/ExecutorFormPage'
      ),
    permissionRequired: permissions.executorEdit,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor_hibah/:id',
    loader: () =>
      import(
        'src/view/remote/executor/view/ExecutorViewPage'
      ),
    permissionRequired: permissions.executorRead,
    exact: true,
    section: 'executor',
  },

  //#endregion
  //#region Harta -Witness
  {
    path: '/witnesss_harta/new',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witnesss_harta/:id/edit',
    loader: () =>
      import(
        'src/view/remote/witness/main/WitnesssFormPage'
      ),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_harta/:id',
    loader: () =>
      import(
        'src/view/remote/witness/view/WitnessViewPage'
      ),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  //#endregion

  {
    path: '/dependents/:id',
    loader: () =>
      import(
        'src/view/remote/dependent/DependentsFormPage'
      ),
    permissionRequired: permissions.dependentEdit,
    exact: true,
    section: 'dependents',
  },

].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
  {
    path: '/auth/signinmfa',
    loader: () => import('src/view/auth/SigninMfaPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/SigninPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('src/view/auth/SigninPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emailUnverifiedRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  simpleRoutes,
};
