import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n'; import accountEnumerators from 'src/modules/account/accountEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'accType',
    label: i18n('entities.account.fields.accType'),
    schema: schemas.enumerator(
      i18n('entities.account.fields.accType'),
      {
        "required": true,
        "options": accountEnumerators.accType
      },
    ),
  },
  {
    name: 'accInstitution',
    label: i18n('entities.account.fields.accInstitution'),
    schema: schemas.string(
      i18n('entities.account.fields.accInstitution'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'accNumber',
    label: i18n('entities.account.fields.accNumber'),
    schema: schemas.string(
      i18n('entities.account.fields.accNumber'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'accValue',
    label: i18n('entities.account.fields.accValue'),
    schema: schemas.decimal(
      i18n('entities.account.fields.accValue'),
      {},
    ),
  },
  {
    name: 'accCurrency',
    label: i18n('entities.account.fields.accCurrency'),
    schema: schemas.enumerator(
      i18n('entities.account.fields.accCurrency'),
      {
        "required": true,
        // "options": accountEnumerators.accCurrency
        "options": currencyEnumerators.cashCurrency
      },
    ),
  },
  // {
  //   name: 'accBen',
  //   label: i18n('entities.account.fields.accBen'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.account.fields.accBen'),
  //     {
  //     },
  //   ),
  // },
  {
    name: 'accFile',
    label: i18n('entities.account.fields.accFile'),
    schema: schemas.files(
      i18n('entities.account.fields.accFile'),
      {},
    ),
  },
  {
    name: 'accRemarks',
    label: i18n('entities.account.fields.accRemarks'),
    schema: schemas.string(
      i18n('entities.account.fields.accRemarks'),
      {},
    ),
  },
];
