import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/contactList/importer/contactListImporterSelectors';
import ContactListService from 'src/modules/contactList/contactListService';
import fields from 'src/modules/contactList/importer/contactListImporterFields';
import { i18n } from 'src/i18n';

const contactListImporterActions = importerActions(
  'CONTACTLIST_IMPORTER',
  selectors,
  ContactListService.import,
  fields,
  i18n('entities.contactList.importer.fileName'),
);

export default contactListImporterActions;