import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import lastInstructionsEnumerators from 'src/modules/lastInstructions/lastInstructionsEnumerators';

export default [
  {
    name: 'instructionType',
    label: i18n('entities.lastInstructions.fields.instructionType'),
    schema: schemas.enumerator(
      i18n('entities.lastInstructions.fields.instructionType'),
      {
        "required": true,
        "options": lastInstructionsEnumerators.instructionType
      },
    ),
  },
  {
    name: 'instructionReligion',
    label: i18n('entities.lastInstructions.fields.instructionReligion'),
    schema: schemas.enumerator(
      i18n('entities.lastInstructions.fields.instructionReligion'),
      {
        "options": lastInstructionsEnumerators.instructionReligion
      },
    ),
  },
  {
    name: 'instructions',
    label: i18n('entities.lastInstructions.fields.instructions'),
    schema: schemas.enumerator(
      i18n('entities.lastInstructions.fields.instructions'),
      {
        "options": lastInstructionsEnumerators.instructions
      },
    ),
  },
  {
    name: 'instructionAttentionTo',
    label: i18n('entities.lastInstructions.fields.instructionAttentionTo'),
    schema: schemas.enumerator(
      i18n('entities.lastInstructions.fields.instructionAttentionTo'),
      {},
    ),
  },
  {
    name: 'instructionText',
    label: i18n('entities.lastInstructions.fields.instructionText'),
    schema: schemas.string(
      i18n('entities.lastInstructions.fields.instructionText'),
      {},
    ),
  },
  {
    name: 'instructionFile',
    label: i18n('entities.account.fields.instructionFile'),
    schema: schemas.files(
      i18n('entities.account.fields.instructionFile'),
      {},
    ),
  },

];
