import { i18n } from 'src/i18n';
import defaultUser from 'src/images/defaultUser.jpg';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import notificationService from 'src/modules/notification/notificationService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'NOTIFICATION_FORM';

const notificationFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  MARKALLASREAD_STARTED: `${prefix}_MARKALLASREAD_STARTED`,
  MARKALLASREAD_SUCCESS: `${prefix}_MARKALLASREAD_SUCCESS`,
  MARKALLASREAD_ERROR: `${prefix}_MARKALLASREAD_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: notificationFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await notificationService.find(id);
      }

      dispatch({
        type: notificationFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificationFormActions.INIT_ERROR,
      });

      // getHistory().push('/notification');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: notificationFormActions.CREATE_STARTED,
      });

      await notificationService.create(values);

      dispatch({
        type: notificationFormActions.CREATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificationFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: notificationFormActions.UPDATE_STARTED,
      });

      await notificationService.update(id, values);

      dispatch({
        type: notificationFormActions.UPDATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificationFormActions.UPDATE_ERROR,
      });
    }
  },

  doMarkAllAsRead: (openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: notificationFormActions.MARKALLASREAD_STARTED,
      });

      await notificationService.markAllRead();
      let records = await notificationService.refresh(
        null,
        null,
        null,
        null,
      );

      if (records.length > 0) {
        let tmp = records;
        tmp.map((item) => {
          item.image = defaultUser;
          return item;
        });
        AuthCurrentTenant.setNotification(tmp);
      }

      openSnackbar(
        i18n('entities.notification.update.success'),
        'success',
      );

      window.location.reload();
      dispatch({
        type: notificationFormActions.MARKALLASREAD_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificationFormActions.MARKALLASREAD_ERROR,
      });
    }
  },
};

export default notificationFormActions;
