import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import debtEnumerators from 'src/modules/debt/debtEnumerators';
import currencyEnumerators from 'src/modules/Currency';

export default [
  {
    name: 'debtCategory',
    label: i18n('entities.debt.fields.debtCategory'),
    schema: schemas.enumerator(
      i18n('entities.debt.fields.debtCategory'),
      {
        "options": debtEnumerators.debtCategory
      },
    ),
  },
  {
    name: 'debtDescription',
    label: i18n('entities.debt.fields.debtDescription'),
    schema: schemas.string(
      i18n('entities.debt.fields.debtDescription'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'debtInstitution',
    label: i18n('entities.debt.fields.debtInstitution'),
    schema: schemas.string(
      i18n('entities.debt.fields.debtInstitution'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'debtAccountNo',
    label: i18n('entities.debt.fields.debtAccountNo'),
    schema: schemas.string(
      i18n('entities.debt.fields.debtAccountNo'),
      {},
    ),
  },
  {
    name: 'debtValue',
    label: i18n('entities.debt.fields.debtValue'),
    schema: schemas.decimal(
      i18n('entities.debt.fields.debtValue'),
      {},
    ),
  },
  {
    name: 'debtCurrency',
    label: i18n('entities.debt.fields.debtCurrency'),
    schema: schemas.enumerator(
      i18n('entities.debt.fields.debtCurrency'),
      {
        "required": true,
        "options": currencyEnumerators.cashCurrency
      },
    ),
  },
  {
    name: 'debtFile',
    label: i18n('entities.debt.fields.debtFile'),
    schema: schemas.files(
      i18n('entities.debt.fields.debtFile'),
      {},
    ),
  },
  {
    name: 'debtRemarks',
    label: i18n('entities.debt.fields.debtRemarks'),
    schema: schemas.string(
      i18n('entities.debt.fields.debtRemarks'),
      {},
    ),
  },
];
