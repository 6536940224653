import list from 'src/modules/digital/list/digitalListReducers';
import form from 'src/modules/digital/form/digitalFormReducers';
import view from 'src/modules/digital/view/digitalViewReducers';
import destroy from 'src/modules/digital/destroy/digitalDestroyReducers';
//import importerReducer from 'src/modules/digital/importer/insuranceImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  //importer: importerReducer,
});
