import list from 'src/modules/lastInstructions/list/lastInstructionsListReducers';
import form from 'src/modules/lastInstructions/form/lastInstructionsFormReducers';
import view from 'src/modules/lastInstructions/view/lastInstructionsViewReducers';
import destroy from 'src/modules/lastInstructions/destroy/lastInstructionsDestroyReducers';
import importerReducer from 'src/modules/lastInstructions/importer/lastInstructionsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
