import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/testamentaryTrust/importer/testamentaryTrustImporterSelectors';
import TestamentaryTrustService from 'src/modules/testamentaryTrust/testamentaryTrustService';
import fields from 'src/modules/testamentaryTrust/importer/testamentaryTrustImporterFields';
import { i18n } from 'src/i18n';

const testamentaryTrustImporterActions = importerActions(
  'TESTAMENTARY_TRUST_IMPORTER',
  selectors,
  TestamentaryTrustService.import,
  fields,
  i18n('entities.testamentaryTrust.importer.fileName'),
);

export default testamentaryTrustImporterActions;