import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TrusteeService from 'src/modules/trustee/trusteeService';

const prefix = 'TRUSTEE_FORM';

const trusteeFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  INVITE_STARTED: `${prefix}_INVITE_STARTED`,
  INVITE_SUCCESS: `${prefix}_INVITE_SUCCESS`,
  INVITE_ERROR: `${prefix}_INVITE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: trusteeFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TrusteeService.find(id);
      }

      dispatch({
        type: trusteeFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trusteeFormActions.INIT_ERROR,
      });

      getHistory().push('/trustee');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: trusteeFormActions.CREATE_STARTED,
      });

      await TrusteeService.create(values);

      dispatch({
        type: trusteeFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.trustee.create.success'),
        'success',
      );

      getHistory().push('/trustee');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trusteeFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: trusteeFormActions.UPDATE_STARTED,
        });

        await TrusteeService.update(id, values);

        dispatch({
          type: trusteeFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.trustee.update.success'),
          'success',
        );

        getHistory().push(`/trustee/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trusteeFormActions.UPDATE_ERROR,
        });
      }
    },

  doUpdateMultiple:
    (values, silent, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: trusteeFormActions.UPDATE_STARTED,
        });
        await values.map((item, index) => {
          let tempData = {
            index: index,
            trusteeName: item.trusteeName,
            trusteeIDType: item.trusteeIDType,
            trusteeIdentification:
              item.trusteeIdentification,
            trusteeAddress: item.trusteeAddress,
            trusteePhone: item.trusteePhone,
            trusteePhone2: item.trusteePhone2,
            trusteeEmail: item.trusteeEmail,
            trusteePhoto: item.trusteePhoto || [],
            fee: item.fee,
            trusteeCurrency:
              item.trusteeCurrency || 'malaysianRinggit',
            trusteeRemarks: item.trusteeRemarks,
            trusteeSequence: index,
            trusteeRelationship: item.trusteeRelationship,
            trusteeStatus: item.trusteeStatus,
          };

          TrusteeService.update(item.id, tempData);
          //BC19012022 - bypass array-callback-warning
          return index;
        });

        dispatch({
          type: trusteeFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n(
            `entities.trustee.update.${
              silent ? 'silent' : 'success'
            }`,
          ),
          'success',
        );

        getHistory().push('/trustee');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trusteeFormActions.UPDATE_ERROR,
        });
      }
    },

  doInvite:
    (values, currentTenant, openSnackbar) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trusteeFormActions.INVITE_STARTED,
        });

        var para = {
          email: values.trusteeEmail,
          token: values.trusteeToken,
          currentTenant: currentTenant,
        };

        await TrusteeService.invite(values, para);

        dispatch({
          type: trusteeFormActions.INVITE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.trustee.invite.success'),
          'success',
        );

        getHistory().push('/trustee');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trusteeFormActions.INVITE_ERROR,
        });
      }
    },

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: trusteeFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TrusteeService.findMain(id);
      }

      dispatch({
        type: trusteeFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trusteeFormActions.INIT_ERROR,
      });

      getHistory().push('/trustee');
    }
  },

  doCreateMain:
    (values, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: trusteeFormActions.CREATE_STARTED,
        });

        await TrusteeService.create(values, true);

        dispatch({
          type: trusteeFormActions.CREATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.trustee.create.success'),
          'success',
        );

        getHistory().push('/trustee');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trusteeFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdateMain:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: trusteeFormActions.UPDATE_STARTED,
        });

        await TrusteeService.update(id, values, true);

        dispatch({
          type: trusteeFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.trustee.update.success'),
          'success',
        );

        getHistory().push(`/trustee/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trusteeFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default trusteeFormActions;
