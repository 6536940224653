const lastInstructionsEnumerators = {
  instructionType: [
    'Funeral',
    'Messages',
    // 'VideoMessages',
    // 'AudioMessages',
    'Others',
  ],
  instructionReligion: [
    'Buddhist',
    'Christian',
    'Catholic',
    'Hindu',
    'Protestant',
    'Toaist',
    'Others',
  ],
  instructions: [
    'Buried',
    'CrematedAndTheAshesScattered',
    'CrematedAndTheAshesStored',
    'Others',
  ],
};

export default lastInstructionsEnumerators;
