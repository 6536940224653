import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import DefaultTab, {
  GenerateTabByPageName,
} from 'src/view/remote/workspace/DefaultTab';

const prefix = 'WORKSPACE';

const workspaceActions = {
  INIT_TAB_STARTED: `${prefix}_INIT_TAB_STARTED`,
  INIT_TAB_ERROR: `${prefix}_INIT_TAB_ERROR`,

  CREATE_TAB_STARTED: `${prefix}_CREATE_TAB_STARTED`,
  CREATE_TAB_SUCCESS: `${prefix}_CREATE_TAB_SUCCESS`,
  CREATE_TAB_ERROR: `${prefix}_CREATE_TAB_ERROR`,

  UPDATE_TAB_STARTED: `${prefix}_UPDATE_TAB_STARTED`,
  UPDATE_TAB_SUCCESS: `${prefix}_UPDATE_TAB_SUCCESS`,
  UPDATE_TAB_ERROR: `${prefix}_UPDATE_TAB_ERROR`,

  UPDATE_ACTIVE_TAB_SUCCESS: `${prefix}_UPDATE_ACTIVE_TAB_SUCCESS`,
  UPDATE_ACTIVE_TAB_ERROR: `${prefix}_UPDATE_ACTIVE_TAB_ERROR`,

  REMOVE_TAB_STARTED: `${prefix}_REMOVE_TAB_STARTED`,
  REMOVE_TAB_SUCCESS: `${prefix}_REMOVE_TAB_SUCCESS`,
  REMOVE_TAB_ERROR: `${prefix}_REMOVE_TAB_ERROR`,

  doInitTab: () => async (dispatch) => {
    try {
      dispatch({
        type: workspaceActions.INIT_TAB_STARTED,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: workspaceActions.INIT_TAB_ERROR,
      });
    }
  },

  doCreateTab:
    (platform, remoteTenant, remoteUser) =>
    async (dispatch) => {
      try {
        dispatch({
          type: workspaceActions.CREATE_TAB_STARTED,
        });

        var defaultTab = DefaultTab(
          platform,
          remoteTenant,
          remoteUser,
        );

        dispatch({
          type: workspaceActions.CREATE_TAB_SUCCESS,
          payload: defaultTab,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: workspaceActions.CREATE_TAB_ERROR,
        });
      }
    },

  doUpdateTab:
    (id, pageName, openSnackbar, activetabs) =>
    async (dispatch) => {
      try {
        dispatch({
          type: workspaceActions.UPDATE_TAB_STARTED,
        });

        var duplicateTab = [] as any;
        var tabRecord = GenerateTabByPageName(pageName);

        var jsonString =
          localStorage.getItem('activeTab') || null;

        var tab: {
          id?: string;
          tabs?: string[];
        } = {
          id: '',
          tabs: [],
        };

        if (jsonString !== null) {
          const hist_tab: {
            id?: string;
            tabs?: string[];
          } = JSON.parse(jsonString);

          tab = hist_tab;

          duplicateTab = hist_tab.tabs.filter(
            (o) => o === tabRecord.label,
          );
        } else {
          tab.id = id;
        }
        if (duplicateTab.length <= 0) {
          tab.tabs.push(tabRecord.label);

          localStorage.setItem(
            'activeTab',
            JSON.stringify(tab),
          );

          dispatch({
            type: workspaceActions.UPDATE_TAB_SUCCESS,
            payload: tabRecord,
          });

          openSnackbar(
            i18n(
              'workspace.tab.addTabMessage',
              tabRecord.label,
            ),
            'success',
          );
        } else {
          var tabSection = activetabs.find(
            (x) => x.label === tabRecord.label,
          );

          if (tabSection) {
            dispatch({
              type: workspaceActions.UPDATE_ACTIVE_TAB_SUCCESS,
              payload: tabSection.id,
            });
          } else {
            openSnackbar(
              i18n(
                'workspace.tab.duplicateTabMessage',
                tabRecord.label,
              ),
              'warning',
            );
          }
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: workspaceActions.UPDATE_TAB_ERROR,
        });
      }
    },

  doUpdateActiveTab: (record) => async (dispatch) => {
    try {
      dispatch({
        type: workspaceActions.UPDATE_ACTIVE_TAB_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: workspaceActions.UPDATE_ACTIVE_TAB_ERROR,
      });
    }
  },

  doRemoveTab:
    (record, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: workspaceActions.REMOVE_TAB_STARTED,
        });

        var jsonString =
          localStorage.getItem('activeTab') || null;

        var tab: {
          id?: string;
          tabs?: string[];
        } = {
          id: '',
          tabs: [],
        };

        if (jsonString !== null) {
          const hist_tab: {
            id?: string;
            tabs?: string[];
          } = JSON.parse(jsonString);

          tab = hist_tab;
          tab.tabs = tab.tabs.filter(
            (o) => o !== record.label,
          );

          localStorage.setItem(
            'activeTab',
            JSON.stringify(tab),
          );
        }

        dispatch({
          type: workspaceActions.REMOVE_TAB_SUCCESS,
          payload: record.id,
        });

        openSnackbar(
          i18n(
            'workspace.tab.removeTabMessage',
            record.label,
          ),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: workspaceActions.REMOVE_TAB_ERROR,
        });
      }
    },
};

export default workspaceActions;
