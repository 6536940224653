import list from 'src/modules/financialPlanning/list/financialPlanningListReducers';
import form from 'src/modules/financialPlanning/form/financialPlanningFormReducers';
import view from 'src/modules/financialPlanning/view/financialPlanningViewReducers';
import destroy from 'src/modules/financialPlanning/destroy/financialPlanningDestroyReducers';

import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
