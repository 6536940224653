import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { convertLanguageCodeToSettings, getLanguageCode } from 'src/i18n';

export default class UserSettingsService {

  static async find() {
    const tenantId = AuthCurrentTenant.get();
    const params = { language: convertLanguageCodeToSettings(getLanguageCode())}
    const response = await authAxios.get(
      `/tenant/${tenantId}/user-settings`,
      {
        params,
      },
    );

    return response.data;
  }

  static async save(userSettings) {
    const body = {
      userSettings,
    };

    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(
      `/tenant/${tenantId}/user-settings`,
      body,
    );
    return response.data;
  }

  static async check_password(password) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user_privacy_check_password`,
      {
        password,
      },
    );

    return response.data;
  }

  static async delete_account() {

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/delete_account`,
    );

    return response.status; //BC17012022
  }

  static async findById(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user-settings/${id}`,
    );

    return response.data;
  }
}
