import TrusteeService from 'src/modules/trustee/trusteeService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'TRUSTEE_VIEW';

const trusteeViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: trusteeViewActions.FIND_STARTED,
      });

      const record = await TrusteeService.find(id);

      dispatch({
        type: trusteeViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trusteeViewActions.FIND_ERROR,
      });

      getHistory().push('/trustee');
    }
  },
};

export default trusteeViewActions;
