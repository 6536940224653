import { getConfig } from 'src/config';
import { i18n } from 'src/i18n';
import EmergencyCardOrderService from 'src/modules/emergencyCardOrder/emergencyCardOrderService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'EMERGENCY_CARD_ORDER_FORM';

const emergencyCardOrderFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  CHECKOUT_STARTED: `${prefix}_CHECKOUT_STARTED`,
  CHECKOUT_SUCCESS: `${prefix}_CHECKOUT_SUCCESS`,
  CHECKOUT_ERROR: `${prefix}_CHECKOUT_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: emergencyCardOrderFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await EmergencyCardOrderService.find(id);
      }

      dispatch({
        type: emergencyCardOrderFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: emergencyCardOrderFormActions.INIT_ERROR,
      });

      getHistory().push('/emergency-card/order');
    }
  },

  doCreate: (values) => async (dispatch) => {
    let createdRecord;
    try {
      dispatch({
        type: emergencyCardOrderFormActions.CREATE_STARTED,
      });

      createdRecord =
        await EmergencyCardOrderService.create(values);
      dispatch({
        type: emergencyCardOrderFormActions.CREATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: emergencyCardOrderFormActions.CREATE_ERROR,
      });
    }

    //Do checkout after placing emergency card order
    try {
      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_STARTED,
      });
      const sessionId =
        await EmergencyCardOrderService.fetchCheckoutSessionId(
          createdRecord,
        );

      const stripe = (window as any).Stripe(
        getConfig()
          .REACT_APP_EMERGENCY_CARD_ORDER_PUBLISHABLE_KEY,
      );

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error.message) {
        throw new Error(result.error.message);
      }

      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_SUCCESS,
      });
    } catch (error) {
      Errors.showMessage(error);

      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: emergencyCardOrderFormActions.UPDATE_STARTED,
        });

        await EmergencyCardOrderService.update(id, values);

        dispatch({
          type: emergencyCardOrderFormActions.UPDATE_SUCCESS,
        });
        openSnackbar(
          i18n(
            'entities.emergencyCardOrder.update.success',
          ),
          'success',
        );

        getHistory().push(`/emergency-card/order`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: emergencyCardOrderFormActions.UPDATE_ERROR,
        });
      }
    },

  doCheckout: (values) => async (dispatch) => {
    try {
      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_STARTED,
      });

      const sessionId =
        await EmergencyCardOrderService.fetchCheckoutSessionId(
          values,
        );

      const stripe = (window as any).Stripe(
        getConfig()
          .REACT_APP_EMERGENCY_CARD_ORDER_PUBLISHABLE_KEY,
      );

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error.message) {
        throw new Error(result.error.message);
      }

      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_SUCCESS,
      });
    } catch (error) {
      Errors.showMessage(error);

      dispatch({
        type: emergencyCardOrderFormActions.CHECKOUT_ERROR,
      });
    }
  },
};

export default emergencyCardOrderFormActions;
