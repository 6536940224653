import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'resourcesPhoto',
    label: i18n(
      'entities.resources.fields.resourcesPhoto',
    ),
    schema: schemas.images(
      i18n('entities.resources.fields.resourcesPhoto'),
      {},
    ),
  },
  {
    name: 'resourcesName',
    label: i18n(
      'entities.resources.fields.resourcesName',
    ),
    schema: schemas.string(
      i18n('entities.resources.fields.resourcesName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'resourcesPhone',
    label: i18n(
      'entities.resources.fields.resourcesPhone',
    ),
    schema: schemas.string(
      i18n('entities.resources.fields.resourcesPhone'),
      {},
    ),
  },
  {
    name: 'resourcesPhone2',
    label: i18n(
      'entities.resources.fields.resourcesPhone2',
    ),
    schema: schemas.string(
      i18n('entities.resources.fields.resourcesPhone2'),
      {},
    ),
  },
  {
    name: 'resourcesEmail',
    label: i18n(
      'entities.resources.fields.resourcesEmail',
    ),
    schema: schemas.string(
      i18n('entities.resources.fields.resourcesEmail'),
      {},
    ),
  },
  {
    name: 'resourcesAddress',
    label: i18n(
      'entities.resources.fields.resourcesAddress',
    ),
    schema: schemas.string(
      i18n(
        'entities.resources.fields.resourcesAddress',
      ),
      {},
    ),
  },
  {
    name: 'resourcesRemarks',
    label: i18n(
      'entities.resources.fields.resourcesRemarks',
    ),
    schema: schemas.string(
      i18n(
        'entities.resources.fields.resourcesRemarks',
      ),
      {},
    ),
  },
];