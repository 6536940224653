import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/debt/importer/debtImporterSelectors';
import DebtService from 'src/modules/debt/debtService';
import fields from 'src/modules/debt/importer/debtImporterFields';
import { i18n } from 'src/i18n';

const debtImporterActions = importerActions(
  'DEBT_IMPORTER',
  selectors,
  DebtService.import,
  fields,
  i18n('entities.debt.importer.fileName'),
);

export default debtImporterActions;