import authAxios from 'src/modules/shared/axios/authAxios';
import { tenantSubdomain } from './tenantSubdomain';
import AuthCurrentTenant from '../auth/authCurrentTenant';
import { getConfig } from 'src/config';
import SettingsService from '../settings/settingsService';

export default class TenantService {
  static async fetchAndApply() {
    const tenantUrl = tenantSubdomain.fromLocationHref();

    if (
      tenantSubdomain.isEnabled &&
      tenantSubdomain.isRootDomain
    ) {
      AuthCurrentTenant.clear();
      return;
    }

    // If there is a subdomain with the tenant url,
    // it must fetch the settings form that subdomain no matter
    // which one is on local storage
    if (tenantUrl) {
      let currentTenant;
      try {
        currentTenant = await this.findByUrl(tenantUrl);
      } catch (error) {
        console.error(error);
      }

      AuthCurrentTenant.set(currentTenant);

      if (!currentTenant) {
        window.location.href = `${
          getConfig().REACT_APP_FRONTEND_PROTOCOL_AFFILIATE
        }://${
          getConfig().REACT_APP_FRONTEND_HOST_AFFILIATE
        }`;
        return;
      }
    }

    const tenantId = AuthCurrentTenant.get();
    if (tenantId && !tenantUrl) {
      try {
        const currentTenant = await this.find(tenantId);

        AuthCurrentTenant.set(currentTenant);
      } catch (error) {
        console.error(error);
      }
    }

    SettingsService.applyThemeFromTenant();
  }

  static async find(id) {
    const response = await authAxios.get(`/tenant/${id}`);
    return response.data;
  }

  static async findByTenantAndUserID(id, user_id) {
    const response = await authAxios.get(
      `/tenant/${id}/find_remote_user/${user_id}`,
    );
    return response.data;
  }

  static async findByUrl(url) {
    const response = await authAxios.get(`/tenant/url`, {
      params: { url },
    });
    return response.data;
  }

  static async fetchAllTenants(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/full_tenant`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/tenant`, body);

    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/tenant/${id}`,
      body,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/tenant`, {
      params,
    });

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/tenant`, {
      params,
    });

    return response.data;
  }

  static async acceptInvitation(
    token,
    forceAcceptOtherEmail = false,
  ) {
    const response = await authAxios.post(
      `/tenant/invitation/${token}/accept`,
      {
        forceAcceptOtherEmail,
      },
    );

    return response.data;
  }

  static async declineInvitation(token) {
    const params = null;

    const response = await authAxios.delete(
      `/tenant/invitation/${token}/decline`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_List(
    type,
    filter,
    orderBy,
    limit,
    offset,
    tenantId,
  ) {
    const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const params = {
      type,
      filter,
      orderBy,
      limit,
      offset,
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenant_List`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_List_Platform(
    type,
    filter,
    orderBy,
    limit,
    offset,
    tenantId,
  ) {
    const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const platform = window.location.pathname.includes(
      'hibah',
    )
      ? 'Hibah'
      : remote.platform;

    const params = {
      type,
      filter,
      orderBy,
      limit,
      offset,
      tenantId: newTenantId,
      platform: platform,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenant_List`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Will_Warning_Check(tenant_id) {
    const response = await authAxios.post(
      `/tenant/${tenant_id}/Will_Warning_Check`,
      {
        tenant_id,
      },
    );

    return response.data;
  }

  static async Get_Asset_Distribution() {
    const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const params = {
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Asset_Distribution`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Tenant_Data(report_type) {
    const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    var newUserId = remote?.isRemote
      ? remote.remoteUser?._id
      : id;

    const params = {
      tenantId: newTenantId,
      userId: newUserId,
      reportType: report_type,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Tenant_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listLocation(
    filter,
    orderBy,
    limit,
    offset,
    tenantId,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      tenantId,
    };
    const id = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${id}/tenantLocation`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listLocationAutoComplete() {
    const tenantId = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      tenant_id: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenantLocationAutoComplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findLocationById(id) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      id,
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenantLocationById`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listContact(
    filter,
    orderBy,
    limit,
    offset,
    tenantId,
  ) {
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      filter,
      orderBy,
      limit,
      offset,
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenantContact`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listContactAutoComplete(
    query,
    limit,
    filter = [],
    tenantId,
  ) {
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      query,
      limit,
      filter,
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenantContactAutoComplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findContactById(id, tenantId) {
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      id,
      tenantId: newTenantId,
    };
    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenantContactById`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_Find_Type(type, tenantId, platform) {
    // const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const params = {
      type,
      tenantId: newTenantId,
      platform: platform,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenant_Find_Type`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_Find(type, arrayId, tenantId) {
    const id = AuthCurrentTenant.get();

    const remote = AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const params = {
      type,
      arrayId,
      tenantId: newTenantId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/tenant_Find`,
      {
        params,
      },
    );

    return response.data;
  }

  static async UpdateWillData(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${id}/tenantUpdateWill`,
      body,
    );

    return response;
  }

  static async Update_Tenant_Data(data, is_Remote) {
    const id = AuthCurrentTenant.get();
    const remote = AuthCurrentTenant.getRemote();

    var newTenantId = is_Remote
      ? remote.remoteTenant?._id
      : id;

    data.tenantId = newTenantId;

    const body = {
      data,
    };

    const response = await authAxios.put(
      `/tenant/${newTenantId}/tenant_Update`,
      body,
    );

    return response.data;
  }

  static async Update_Tenant_Specific_Data(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();
    const remote = AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/tenant_Update_Specific`,
      body,
    );

    return response.data;
  }

  static async Delete_Tenant_Data(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();

    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : id;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/tenant_Delete`,
      body,
    );

    return response.data;
  }

  static async Get_Will_Data(newTenantId, newUserId) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Will_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Estate_Data(newTenantId, newUserId) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Estate_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Pengisytiharan_Harta_Sepencarian(
    newTenantId,
    newUserId,
    spouseId,
  ) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
      spouseId: spouseId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Pengisytiharan_Harta_Sepencarian`,
      {
        params,
      },
    );

    return response.data;
  }
}
