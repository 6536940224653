import { createSelector } from 'reselect';

const selectRaw = (state) => state.global;

const selectGlobal = createSelector(
  [selectRaw],
  (raw) => raw,
);

const selectIsEditForm = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.isEditForm),
);

const selectInitLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.initLoading),
);

const selectSaveLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.saveLoading),
);

const globalSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectGlobal,
  selectIsEditForm,
  selectRaw,
};

export default globalSelectors;
