import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.lastInstructions.fields.id'),
  },
  {
    name: 'instructionType',
    label: i18n('entities.lastInstructions.fields.instructionType'),
  },
  {
    name: 'instructionReligion',
    label: i18n('entities.lastInstructions.fields.instructionReligion'),
  },
  {
    name: 'instructions',
    label: i18n('entities.lastInstructions.fields.instructions'),
  },
  {
    name: 'instructionAttentionTo',
    label: i18n('entities.lastInstructions.fields.instructionAttentionTo'),
  },
  {
    name: 'instructionText',
    label: i18n('entities.lastInstructions.fields.instructionText'),
  },
  {
    name: 'instructionFile',
    label: i18n('entities.account.fields.instructionFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.lastInstructions.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.lastInstructions.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
