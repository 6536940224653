import actions from './workspaceRemoteActions';

const initialData = {
  remoteLoading: false,
  remoteCustomerLoading: false,
  remoteTenant: null,
  remoteUser: null,
  remotePlatform: '',
  disableLandingList: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.REMOTE_CUSTOMER_STARTED) {
    return {
      ...state,
      remoteTenant: null,
      remoteUser: null,
      remotePlatform: '',
      remoteLoading: true,
    };
  }

  if (type === actions.REMOTE_CUSTOMER_SUCCESS) {
    return {
      ...state,
      remoteLoading: false,
    };
  }

  if (type === actions.REMOTE_CUSTOMER_ERROR) {
    return {
      ...state,
      remoteLoading: false,
    };
  }

  if (
    type ===
    actions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_STARTED
  ) {
    return {
      ...state,
      remoteCustomerLoading: true,
    };
  }

  if (
    type ===
    actions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_SUCCESS
  ) {
    var tenant = payload.tenant;
    var user = payload.user;
    var platform = payload.platform;

    return {
      ...state,
      remoteTenant: tenant,
      remoteUser: user,
      remotePlatform: platform,
      remoteCustomerLoading: false,
      disableLandingList: [],
    };
  }

  if (
    type ===
    actions.RETRIEVE_REMOTE_CUSTOMER_INFORMATION_ERROR
  ) {
    return {
      ...state,
      remoteTenant: null,
      remoteUser: null,
      remotePlatform: '',
      remoteCustomerLoading: false,
    };
  }

  if (type === actions.DISABLE_LANDING_PROCESS) {
    var disableLandingList = state.disableLandingList;
    disableLandingList.push(payload.platform);

    return {
      ...state,
      disableLandingList: disableLandingList,
    };
  }

  return state;
};
