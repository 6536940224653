import { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { i18n } from 'src/i18n';
import { Download } from '@mui/icons-material';
import { isMobile, isAndroid } from 'react-device-detect';

const PWAButton = (props) => {
  const [loading, setLoading] = useState(false);

  const [deferredPrompt, setDeferredPrompt] =
    useState<Event | null>(null);
  const [isInstallable, setIsInstallable] =
    useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener(
      'beforeinstallprompt',
      handleBeforeInstallPrompt,
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();

      (deferredPrompt as any).userChoice.then(
        (choiceResult: { outcome: string }) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          setDeferredPrompt(null);
        },
      );
    }
  };

  return (
    <div>
      {isInstallable && isMobile && (
        <Tooltip title={i18n('common.installKoha')}>
          <IconButton onClick={handleInstallClick}>
            <Download htmlColor="rgba(5, 101, 189, 1)" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default PWAButton;
