import {
  convertSettingsToLanguageCode,
  getLanguageCode,
} from 'src/i18n';
import actions from 'src/modules/userSettings/userSettingsActions';
import languageActions from 'src/modules/layout/layoutActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  userSettings: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    let reload = false;
    if (
      convertSettingsToLanguageCode(
        payload.userSettingsLanguage,
      ) !== getLanguageCode()
    ) {
      languageActions.doChangeLanguage(
        convertSettingsToLanguageCode(
          payload.userSettingsLanguage,
        ),
      );
      reload = true;
    }
    if (
      payload.userSettingsWillMode &&
      localStorage.getItem('willMode') !==
        payload.userSettingsWillMode
    ) {
      localStorage.setItem(
        'willMode',
        payload.userSettingsWillMode,
      );
      reload = true;
    }
    if (reload) {
      window.location.reload();
    }
    return {
      ...state,
      userSettings: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      userSettings: null,
      initLoading: false,
    };
  }

  if (type === actions.SAVE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.SAVE_SUCCESS) {
    let languageID = convertSettingsToLanguageCode(
      payload.userSettingsLanguage,
    );
    if (languageID !== getLanguageCode())
      languageActions.doChangeLanguage(languageID);
    if (payload.userSettingsWillMode)
      localStorage.setItem(
        'willMode',
        payload.userSettingsWillMode,
      );
    window.location.reload();
    return {
      ...state,
      userSettings: payload,
      saveLoading: false,
    };
  }

  if (type === actions.SAVE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
