import list from 'src/modules/healthInfo/list/healthInfoListReducers';
import form from 'src/modules/healthInfo/form/healthInfoFormReducers';
import view from 'src/modules/healthInfo/view/healthInfoViewReducers';
import destroy from 'src/modules/healthInfo/destroy/healthInfoDestroyReducers';
import importerReducer from 'src/modules/healthInfo/importer/healthInfoImporterReducers'
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
