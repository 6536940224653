import { i18n } from 'src/i18n';
import guardiansService from 'src/modules/guardians/guardiansService';
import listActions from 'src/modules/guardians/list/guardiansListActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'GUARDIANS_DESTROY';

const guardiansDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: guardiansDestroyActions.DESTROY_STARTED,
      });

      await guardiansService.destroyAll([id]);

      dispatch({
        type: guardiansDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.guardians.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: guardiansDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: guardiansDestroyActions.DESTROY_ALL_STARTED,
      });

      await guardiansService.destroyAll(ids);

      dispatch({
        type: guardiansDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.guardians.destroyAll.success'),
        'success',
      );

      getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: guardiansDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default guardiansDestroyActions;
