import list from 'src/modules/assetCategory/list/assetCategoryListReducers';
import form from 'src/modules/assetCategory/form/assetCategoryFormReducers';
import view from 'src/modules/assetCategory/view/assetCategoryViewReducers';
import destroy from 'src/modules/assetCategory/destroy/assetCategoryDestroyReducers';
import importerReducer from 'src/modules/assetCategory/importer/assetCategoryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
