import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class PetService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };
    // console.log("body",body)
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/pet/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.delete(
      `/tenant/${newTenantId}/pet`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/pet`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/pet/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/pet/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset,withFileBuffer = false) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      withFileBuffer,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/pet`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/pet/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete_guardian_category(query, is_child, limit) {
    const params = {
      query,
      is_child,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/pet/autocomplete_guardian_category`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocompleteFilter(
    query,
    limit,
    filter,
    cur_val,
  ) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/pet/autocomplete`,
      {
        params,
      },
    );

    var result = [];
    var filter_result = [];

    response.data.forEach((item) => {
      if (!this.Check_IsExists(filter_result, item.label)) {
        result.push(item);
      } else {
      }
    });

    return result;
  }

  static Check_IsExists = (list, comparer) => {
    var result = false;

    list.forEach((item) => {
      if (item === comparer) {
        result = true;
      }
    });

    return result;
  };
}
