import { Button } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import updateActions from 'src/modules/appReleaseNote/form/appReleaseNoteFormActions';
import actions from 'src/modules/appReleaseNote/view/appReleaseNoteViewActions';
import selectors from 'src/modules/appReleaseNote/view/appReleaseNoteViewSelectors';
import authSelectors from 'src/modules/auth/authSelectors';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface IAppReleaseNoteModal {
  onClose?: () => void;
  isOpen?: boolean;
}

const AppReleaseNoteModal = ({
  onClose,
  isOpen,
}: IAppReleaseNoteModal) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(
    isOpen !== undefined ? isOpen : false,
  );

  const [content, setContent] =
    useState<any>('Stay Tuned..');

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const appReleaseNote = useSelector(
    selectors.selectRecord,
  );

  const handleClose = () => {
    setModalOpen(false);
    dispatch(
      updateActions.doUpdate(currentTenant.id, {
        appReleaseNoteRead: true,
      }),
    );
    currentTenant.appReleaseNoteRead = true;
    onClose?.();
  };

  useEffect(() => {
    dispatch(
      actions.doFind({
        type: currentUser.userRole || 'advisor',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const releaseNote =
      appReleaseNote?.filter(
        (item) =>
          item.type === currentUser.userRole && item.active,
      ) || [];
    if (
      (!currentTenant.appReleaseNoteRead || isOpen) &&
      releaseNote &&
      releaseNote.length > 0
    ) {
      setContent(releaseNote[0].content);
      setModalOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={modalOpen}
      onClose={() => handleClose()}
      aria-labelledby={i18n('common.appReleaseNote')}
    >
      <DialogTitle id="scroll-dialog-title">
        {i18n('common.appReleaseNote')}
      </DialogTitle>
      <DialogContent> {parse(content)}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginTop: '20px',
            width: '180px',
            height: '36.5px',
            borderRadius: '64px',
            background:
              'linear-gradient(to right, rgba(0, 74, 158, 1), rgba(0, 120, 255, 1))',
            textTransform: 'none',
          }}
          onClick={handleClose}
        >
          {i18n('common.close').toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppReleaseNoteModal;
