import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import moment from 'moment';

const tenantId = AuthCurrentTenant.get();

const prefix = 'WIDGETS_EVENT';

const eventActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  DELETE_STARTED: `${prefix}_DELETE_STARTED`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,

  doInit: (remoteUserId, showAll?) => async (dispatch) => {
    try {
      dispatch({
        type: eventActions.INIT_STARTED,
      });

      const record = await TenantService.tenant_List(
        'Events',
        null,
        null,
        null,
        null,
        tenantId,
      );

      if (!showAll) {
        const now = moment().startOf('day');

        record.rows = record.rows?.filter((row) => {
          const startDatePart = moment(
            row.StartDate,
          ).startOf('day');

          return startDatePart.isSameOrAfter(now, null);
        });

        // const now = moment();
        // record.rows = record.rows?.filter((row) => {
        //   const startDatePart = moment(row.StartDate)
        //     .utc()
        //     .format('YYYY-MM-DD');
        //   const startTimePart = moment(row.StartTime)
        //     .utc()
        //     .format('HH:mm:ss.SSS');
        //   const combinedStartDateTime = `${startDatePart}T${startTimePart}Z`;
        //   const utcStartDateTime = moment.utc(
        //     combinedStartDateTime,
        //   );

        //   const endDatePart = moment(row.EndDate)
        //     .utc()
        //     .format('YYYY-MM-DD');
        //   const endTimePart = moment(row.EndTime)
        //     .utc()
        //     .format('HH:mm:ss.SSS');
        //   const combinedEndDateTime = `${endDatePart}T${endTimePart}Z`;
        //   const utcEndDateTime = moment.utc(
        //     combinedEndDateTime,
        //   );

        //   // return now.isBetween(
        //   //   utcStartDateTime,
        //   //   utcEndDateTime,
        //   //   null,
        //   //   '[]',
        //   // );

        //   return now.isSameOrBefore(utcStartDateTime, null);
        // });

        record.rows = record.rows?.slice(0, 10);
      }

      if (remoteUserId) {
        record.rows = record.rows?.filter(
          (row) => row.Client.id === remoteUserId,
        );
      }

      dispatch({
        type: eventActions.INIT_SUCCESS,
        payload: record.rows,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: eventActions.INIT_ERROR,
      });
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: eventActions.CREATE_STARTED,
      });

      let record = await TenantService.Update_Tenant_Data(
        values,
        false,
      );
      const now = moment().startOf('day');

      record = record?.filter((row) => {
        const startDatePart = moment(row.StartDate).startOf(
          'day',
        );

        return startDatePart.isSameOrAfter(now, null);
      });

      // const now = moment();

      // record = record?.filter((row) => {
      //   const startDatePart = moment(row.StartDate)
      //     .utc()
      //     .format('YYYY-MM-DD');
      //   const startTimePart = moment(row.StartTime)
      //     .utc()
      //     .format('HH:mm:ss.SSS');
      //   const combinedStartDateTime = `${startDatePart}T${startTimePart}Z`;
      //   const utcStartDateTime = moment.utc(
      //     combinedStartDateTime,
      //   );

      //   const endDatePart = moment(row.EndDate)
      //     .utc()
      //     .format('YYYY-MM-DD');
      //   const endTimePart = moment(row.EndTime)
      //     .utc()
      //     .format('HH:mm:ss.SSS');
      //   const combinedEndDateTime = `${endDatePart}T${endTimePart}Z`;
      //   const utcEndDateTime = moment.utc(
      //     combinedEndDateTime,
      //   );

      //   // return now.isBetween(
      //   //   utcStartDateTime,
      //   //   utcEndDateTime,
      //   //   null,
      //   //   '[]',
      //   // );

      //   return now.isSameOrBefore(utcStartDateTime, null);
      // });

      if (values.remoteUserId) {
        record = record.filter(
          (row) => row.Client.id === values.remoteUserId,
        );
      }

      record = record?.slice(0, 10);

      dispatch({
        type: eventActions.CREATE_SUCCESS,
        payload: record,
      });

      openSnackbar(
        i18n('widgets.event.createMessage'),
        'success',
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: eventActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (values, openSnackbar, showAll?) =>
    async (dispatch) => {
      try {
        dispatch({
          type: eventActions.UPDATE_STARTED,
        });

        let record = await TenantService.Update_Tenant_Data(
          values,
          false,
        );

        if (values.remoteUserId) {
          record = record.filter(
            (row) => row.Client.id === values.remoteUserId,
          );
        }

        if (!showAll) {
          const now = moment().startOf('day');

          record = record?.filter((row) => {
            const startDatePart = moment(
              row.StartDate,
            ).startOf('day');

            return startDatePart.isSameOrAfter(now, null);
          });

          // const now = moment();

          // record = record?.filter((row) => {
          //   const startDatePart = moment(row.StartDate)
          //     .utc()
          //     .format('YYYY-MM-DD');
          //   const startTimePart = moment(row.StartTime)
          //     .utc()
          //     .format('HH:mm:ss.SSS');
          //   const combinedStartDateTime = `${startDatePart}T${startTimePart}Z`;
          //   const utcStartDateTime = moment.utc(
          //     combinedStartDateTime,
          //   );

          //   const endDatePart = moment(row.EndDate)
          //     .utc()
          //     .format('YYYY-MM-DD');
          //   const endTimePart = moment(row.EndTime)
          //     .utc()
          //     .format('HH:mm:ss.SSS');
          //   const combinedEndDateTime = `${endDatePart}T${endTimePart}Z`;
          //   const utcEndDateTime = moment.utc(
          //     combinedEndDateTime,
          //   );

          //   // return now.isBetween(
          //   //   utcStartDateTime,
          //   //   utcEndDateTime,
          //   //   null,
          //   //   '[]',
          //   // );

          //   return now.isSameOrBefore(
          //     utcStartDateTime,
          //     null,
          //   );
          // });

          record = record?.slice(0, 10);
        }

        dispatch({
          type: eventActions.UPDATE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('widgets.event.updateMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: eventActions.UPDATE_ERROR,
        });
      }
    },

  doDelete:
    (values, openSnackbar, showAll?) =>
    async (dispatch) => {
      try {
        dispatch({
          type: eventActions.DELETE_STARTED,
        });

        let record = await TenantService.Delete_Tenant_Data(
          values,
        );

        if (!showAll) {
          const now = moment().startOf('day');

          record = record?.filter((row) => {
            const startDatePart = moment(
              row.StartDate,
            ).startOf('day');

            return startDatePart.isSameOrAfter(now, null);
          });

          // const now = moment();

          // record = record?.filter((row) => {
          //   const startDatePart = moment(row.StartDate)
          //     .utc()
          //     .format('YYYY-MM-DD');
          //   const startTimePart = moment(row.StartTime)
          //     .utc()
          //     .format('HH:mm:ss.SSS');
          //   const combinedStartDateTime = `${startDatePart}T${startTimePart}Z`;
          //   const utcStartDateTime = moment.utc(
          //     combinedStartDateTime,
          //   );

          //   const endDatePart = moment(row.EndDate)
          //     .utc()
          //     .format('YYYY-MM-DD');
          //   const endTimePart = moment(row.EndTime)
          //     .utc()
          //     .format('HH:mm:ss.SSS');
          //   const combinedEndDateTime = `${endDatePart}T${endTimePart}Z`;
          //   const utcEndDateTime = moment.utc(
          //     combinedEndDateTime,
          //   );

          //   // return now.isBetween(
          //   //   utcStartDateTime,
          //   //   utcEndDateTime,
          //   //   null,
          //   //   '[]',
          //   // );

          //   return now.isSameOrBefore(
          //     utcStartDateTime,
          //     null,
          //   );
          // });

          record = record?.slice(0, 10);
        }

        dispatch({
          type: eventActions.DELETE_SUCCESS,
          payload: record,
        });

        openSnackbar(
          i18n('widgets.event.deleteMessage'),
          'success',
        );
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: eventActions.DELETE_ERROR,
        });
      }
    },
};

export default eventActions;
