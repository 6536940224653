import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.paymentHistory.fields.id'),
  },
  {
    name: 'subscriber',
    label: i18n(
      'entities.paymentHistory.fields.fullName',
    ),
    render: (value) => value ? value.fullName : null,
  },
  {
    name: 'planStatus',
    label: i18n(
      'entities.paymentHistory.fields.planStatus',
    ),
  },
  {
    name: 'plan',
    label: i18n(
      'entities.paymentHistory.fields.plan',
    ),
  },
  {
    name: 'transactionAmount',
    label: i18n(
      'entities.paymentHistory.fields.transactionAmount',
    ),
  },
  {
    name: 'transactionDate',
    label: i18n(
      'entities.paymentHistory.fields.transactionDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.paymentHistory.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.paymentHistory.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
