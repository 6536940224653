import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'emergencyCardOrderID',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderID'),
  },
  {
    name: 'emergencyCardOrderName',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderName'),
  },
  {
    name: 'emergencyCardOrderPhone1',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderPhone1'),
  },
  {
    name: 'emergencyCardOrderPhone2',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderPhone2'),
  },
  {
    name: 'emergencyCardOrderEmail',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderEmail'),
  },
  {
    name: 'emergencyCardOrderAddress',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderAddress'),
  },
  {
    name: 'emergencyCardOrderQuantity',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderQuantity'),
  },
  {
    name: 'emergencyCardOrderPayment',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderPayment'),
  },
  {
    name: 'emergencyCardOrderCardID',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderCardID'),
  },
  {
    name: 'emergencyCardOrderDate',
    label: i18n('entities.emergencyCardOrder.fields.emergencyCardOrderDate'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.emergencyCardOrder.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.emergencyCardOrder.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
