import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import WalletService from 'src/modules/wallet/walletService';

const prefix = 'WALLET_FORM';

const walletFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: walletFormActions.INIT_STARTED,
      });

      const record = await WalletService.list(id);

      dispatch({
        type: walletFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: walletFormActions.INIT_ERROR,
      });

      getHistory().push('/wallet');
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: walletFormActions.UPDATE_STARTED,
        });

        await WalletService.update(id, values);

        dispatch({
          type: walletFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.wallet.update.success'),
          'success',
        );

        getHistory().push(`/wallet`);
        getHistory().go(0);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: walletFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default walletFormActions;
