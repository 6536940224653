import actions from 'src/modules/tasks/newTask/newTaskActions';


const initialData = {
  rows: [],
  count: 0,
  loading: false,
};

export default (state = initialData, { type, payload }) => {

    if (type === actions.FETCH_STARTED) {
        return {
        ...state,
        loading: true,
        rows: payload.rows,
        count: payload.count,
        };
    }
    
    if (type === actions.FETCH_ERROR) {
        return {
        ...state,
        loading: false,
        };
    }



  return state;
};
