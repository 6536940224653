import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';

export default class PetService {
  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet/${id}`,
    );

    return response.data;
  }

  static async list(
    filter,
    orderBy,
    limit,
    offset,
    withFileBuffer = false,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      withFileBuffer,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/pet/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  //new
  static async getAllTasks(query = {}) {
    const tenantId = AuthCurrentTenant.get();

    let url = `task/list/${tenantId}`;
    const queryParams = [];

    if (query.customerId) {
      queryParams.push(`customerId=${query.customerId}`);
    }

    if (query.status && Array.isArray(query.status)) {
      query.status.map((status) => {
        queryParams.push(`status=${status}`);
      });
    } else if (query.status) {
      queryParams.push(`status=${query.status}`);
    }

    if (query.level && Array.isArray(query.level)) {
      query.level.map((level) => {
        queryParams.push(`level=${level}`);
      });
    } else if (query.level) {
      queryParams.push(`level=${query.level}`);
    }

    if (query.date) {
      queryParams.push(`date=${query.date}`);
    }

    if (query.home) {
      queryParams.push(`home=${query.home}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    const response = await authAxios.get(url);
    return response.data;
  }

  static async createTask(data) {
    try {
      const tenantId = AuthCurrentTenant.get();
      const response = await authAxios.post(
        `/task/create/${tenantId}`,
        data,
      );

      return response.data;
    } catch (error) {
      console.log('error from createTask', error);
      Errors.handle(error);
      // return (error as any).response ? (error as any).response.status : 500; // Return status code or 500 if not available
    }
  }

  static async updateTask(data, openSnackbar) {
    const tenantId = AuthCurrentTenant.get();

    try {
      const response = await authAxios.put(
        `/task/update/${tenantId}`,
        data,
      );

      return response.data;
    } catch (error) {
      console.log('error from updateTask', error);
      Errors.handle(error);
    }
  }

  static async deleteTask(id) {
    const tenantId = AuthCurrentTenant.get();

    const data = {
      _id: String(id),
    };

    const response = await authAxios.delete(
      `/task/destroy/${tenantId}`,
      {
        data,
      },
    );

    return response;
  }
}
