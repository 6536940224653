import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import defaultUser from 'src/images/defaultUser.jpg';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import { TNotificationStatus } from 'src/modules/notification/notificationEnumerators';
import NotificationService from 'src/modules/notification/notificationService';
import { TNotification } from 'src/types';
import { timeSince } from 'src/utils/functions';

const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: '#f2f2f2',
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
}));

const isLatestNotice = (date: string | Date): boolean => {
  const inputDate: Moment = moment(date);
  const today: Moment = moment();

  const diffInDays: number = today.diff(inputDate, 'days');
  return diffInDays <= 3;
};

type TNotificationObj = {
  rows: TNotification[];
  count: number;
};

const chipSX = {
  height: 24,
  padding: '0 6px',
};
const chipErrorSX = {
  ...chipSX,
  color: 'red',
  backgroundColor: '#ffcccc',
  marginRight: '5px',
};

const chipWarningSX = {
  ...chipSX,
  color: 'orange',
  backgroundColor: '#ffedcc',
};

interface INotificationList {
  status: TNotificationStatus;
  setUnread: React.Dispatch<React.SetStateAction<number>>;
}

const NotificationList = ({
  setUnread,
  status,
}: INotificationList) => {
  const theme = useTheme();
  const [notificationList, setNotificationList] =
    useState<TNotification[]>();
  const [isStart, setIsStart] = useState(true);

  const notificationListByStatus = useMemo(
    () =>
      notificationList?.length > 0
        ? notificationList?.filter(
            (item: TNotification) => {
              if (status === 'unread') {
                return !item?.isRead;
              }
              if (status === 'new') {
                return isLatestNotice(
                  item?.createdAt ?? '',
                );
              }

              return item;
            },
          )
        : [],
    [status, notificationList],
  );

  const refreshNotification = async () => {
    try {
      const notificationList: TNotificationObj =
        await NotificationService.list(
          { title: null },
          { createdAt: -1 }, // DESC order
          10,
          0,
        );
      const formattedNotificationObj: TNotification[] =
        notificationList?.rows?.map((item) => {
          item.image = defaultUser;
          return item;
        });
      AuthCurrentTenant.setNotification(
        formattedNotificationObj,
      );
      setNotificationList(formattedNotificationObj);
      setIsStart(false);
      setUnread(notificationList?.count ?? 0);
    } catch (error) {
      console.log(
        '🚀 ~ refreshNotification ~ error:',
        error,
      );
    }
  };

  useEffect(() => {
    const notices = AuthCurrentTenant.getNotificationList();

    if (notices === null) {
      refreshNotification();
    } else {
      let duration = moment.duration(
        moment().diff(moment(notices.retrieve_time)),
      );

      if (duration.asMinutes() > 10) refreshNotification();
      else {
        setNotificationList(notices.data);
        setIsStart(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 330,
          py: 0,
          borderRadius: '10px',
          [theme.breakpoints.down('md')]: {
            maxWidth: 300,
          },
          '& .MuiListItemSecondaryAction-root': {
            top: 22,
          },
          '& .MuiDivider-root': {
            my: 0,
          },
          '& .list-container': {
            pl: 7,
          },
        }}
      >
        {isStart && (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              minHeight: '100px',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {notificationListByStatus &&
          notificationListByStatus.map((item, index) => {
            return (
              <div
                key={`${item?.createdAt ?? '-'}-${index}`}
              >
                <ListItemWrapper>
                  <ListItem alignItems="center">
                    <ListItemAvatar>
                      <Avatar src={item?.image ?? ''} />
                    </ListItemAvatar>
                    <ListItemText primary="System Info" />
                    <ListItemSecondaryAction>
                      <Grid
                        container
                        justifyContent="flex-end"
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {timeSince(
                              item?.updatedAt ?? '',
                            ) ?? '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Grid
                    container
                    direction="column"
                    className="list-container"
                  >
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {item?.title ?? '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        {item?.isRead === false && (
                          <Grid item>
                            <Chip
                              label="Unread"
                              sx={chipErrorSX}
                            />
                          </Grid>
                        )}
                        {isLatestNotice(
                          item?.createdAt ?? '-',
                        ) && (
                          <Grid item>
                            <Chip
                              label="New"
                              sx={chipWarningSX}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItemWrapper>
                <Divider />
              </div>
            );
          })}
      </List>
    </>
  );
};

export default NotificationList;
