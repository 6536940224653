import { i18n } from 'src/i18n';
import globalActions from 'src/modules/global/globalActions';
import listActions from 'src/modules/pledge/list/pledgeListActions';
import PledgeService from 'src/modules/pledge/pledgeService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'PLEDGE_DESTROY';

const pledgeDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: pledgeDestroyActions.DESTROY_STARTED,
      });

      await PledgeService.destroyAll([id]);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: pledgeDestroyActions.DESTROY_SUCCESS,
      });

      openSnackbar(
        i18n('entities.pledge.destroy.success'),
        'success',
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/pledge');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: pledgeDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: pledgeDestroyActions.DESTROY_ALL_STARTED,
      });

      await PledgeService.destroyAll(ids);
      dispatch(globalActions.doEditForm());
      dispatch({
        type: pledgeDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
        dispatch(listActions.doFetchCurrentFilter());
      }

      openSnackbar(
        i18n('entities.pledge.destroyAll.success'),
        'success',
      );

      getHistory().push('/pledge');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: pledgeDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default pledgeDestroyActions;
