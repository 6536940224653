import LegalGuardianService from 'src/modules/legalGuardian/legalGuardianService';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'LEGALGUARDIAN_FORM';

const legalGuardianFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: legalGuardianFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await LegalGuardianService.find(id);
      }

      dispatch({
        type: legalGuardianFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: legalGuardianFormActions.INIT_ERROR,
      });

      // getHistory().push('/legal-guardian');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: legalGuardianFormActions.CREATE_STARTED,
      });

      await LegalGuardianService.create(values);

      dispatch({
        type: legalGuardianFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.legalGuardian.create.success'),
        'success',
      );

      // getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: legalGuardianFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: legalGuardianFormActions.UPDATE_STARTED,
        });

        await LegalGuardianService.update(id, values);

        dispatch({
          type: legalGuardianFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.legalGuardian.update.success'),
          'success',
        );

        getHistory().push(`/guardian/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: legalGuardianFormActions.UPDATE_ERROR,
        });
      }
    },
  doUpdateMultiple:
    (values, silent, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: legalGuardianFormActions.UPDATE_STARTED,
        });
        await values.forEach((item, index) => {
          let tempData = {
            index: index,
            legalGuardianName: item.legalGuardianName,
            legalGuardianIdentificationType:
              item.legalGuardianIdentificationType,
            legalGuardianIdentificationNo:
              item.legalGuardianIdentificationNo,
            legalGuardianAddress: item.legalGuardianAddress,
            legalGuardianContactNo1:
              item.legalGuardianContactNo1,
            legalGuardianContactNo2:
              item.legalGuardianContactNo2,
            legalGuardianEmail: item.legalGuardianEmail,
            legalGuardianSequence: index,
          };

          LegalGuardianService.update(item.id, tempData);
        });

        dispatch({
          type: legalGuardianFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n(
            `entities.legalGuardian.update.${
              silent ? 'silent' : 'success'
            }`,
          ),
          'success',
        );

        getHistory().push('/guardian');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: legalGuardianFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default legalGuardianFormActions;
