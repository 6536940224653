import CouponGeneratorService from 'src/modules/couponGenerator/couponGeneratorService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'COUPON_GENERATOR_VIEW';

const couponGeneratorViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: couponGeneratorViewActions.FIND_STARTED,
      });

      const record = await CouponGeneratorService.find(id);

      dispatch({
        type: couponGeneratorViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: couponGeneratorViewActions.FIND_ERROR,
      });

      getHistory().push('/coupon-generator');
    }
  },
};

export default couponGeneratorViewActions;
