import list from 'src/modules/paymentHistory/list/paymentHistoryListReducers';
import form from 'src/modules/paymentHistory/form/paymentHistoryFormReducers';
import view from 'src/modules/paymentHistory/view/paymentHistoryViewReducers';
import destroy from 'src/modules/paymentHistory/destroy/paymentHistoryDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
