import DOMPurify from 'dompurify';
import moment from 'moment';

export const sanitizeObject = (data) => {
    if (
        moment.isMoment(data) ||
        data instanceof Date ||
        typeof data === 'number' ||
        typeof data === 'boolean' ||
        data === null ||
        data === undefined ||
        typeof data === 'function' ||
        Buffer.isBuffer(data)
      ) {
        // Skip sanitization for Moment.js, Date, Number, Boolean, Null, Undefined, Function, and Buffer objects
        return data;
      }
    if (typeof data === 'string') {
        const sanitizedData = DOMPurify.sanitize(data);
        if (sanitizedData !== data) {
            throw new Error('Suspicious data detected');
        }
        return sanitizedData;
    }
    if (Array.isArray(data)) {
        return data.map((item) => sanitizeObject(item));
    }
    if (typeof data === 'object' && data !== null) {
        const sanitizedObject = {};
        Object.keys(data).forEach((key) => {
            sanitizedObject[key] = sanitizeObject(data[key]);
        });
        return sanitizedObject;
    }
    return data;
};
