import { i18n } from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import GuardiansService from 'src/modules/guardians/guardiansService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'GUARDIANS_FORM';

const guardiansFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: guardiansFormActions.INIT_STARTED,
      });

      let record = { rows: [] };

      const isEdit = Boolean(id);

      if (isEdit) {
        // record = await GuardiansService.find(id);
        record = await TenantService.tenant_Find_Type(
          'Guardian',
          tenantId,
          null,
        );
        // record = await TenantService.tenant_Find('Guardian', id, tenantId);

        dispatch({
          type: guardiansFormActions.INIT_SUCCESS,
          payload: record,
        });
      } else {
        // record = await GuardiansService.list(null,null,null,null);
        record = await TenantService.tenant_List(
          'Guardian',
          null,
          null,
          null,
          null,
          tenantId,
        );

        dispatch({
          type: guardiansFormActions.INIT_SUCCESS,
          payload: record.rows[0],
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: guardiansFormActions.INIT_ERROR,
      });

      getHistory().push('/legal-guardian');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: guardiansFormActions.CREATE_STARTED,
      });

      await GuardiansService.create(values);

      dispatch({
        type: guardiansFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.guardians.create.success'),
        'success',
      );

      getHistory().push('/willListPage');
      // getHistory().push('/legal-guardian');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: guardiansFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: guardiansFormActions.UPDATE_STARTED,
        });

        await GuardiansService.update(id, values);

        dispatch({
          type: guardiansFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.guardians.update.success'),
          'success',
        );

        // getHistory().push('/willListPage');
        // getHistory().push('/legal-guardian');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: guardiansFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default guardiansFormActions;
