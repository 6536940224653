import list from 'src/modules/testamentaryTrust/list/testamentaryTrustListReducers';
import form from 'src/modules/testamentaryTrust/form/testamentaryTrustFormReducers';
import view from 'src/modules/testamentaryTrust/view/testamentaryTrustViewReducers';
import destroy from 'src/modules/testamentaryTrust/destroy/testamentaryTrustDestroyReducers';
import importerReducer from 'src/modules/testamentaryTrust/importer/testamentaryTrustImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
