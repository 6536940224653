const debtEnumerators = {
  debtCategory: [
    'Credit card',
    'Hire Purchase',
    'Mortgage Loan',
    'Individual/Education Loan',
    'Business Debt',
    'Investment Debt',
  ],
};

export default debtEnumerators;
