import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contactList.fields.id'),
  },
  {
    name: 'contactListPhoto',
    label: i18n('entities.contactList.fields.contactListPhoto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'contactListName',
    label: i18n('entities.contactList.fields.contactListName'),
  },
  {
    name: 'contactListPhone',
    label: i18n('entities.contactList.fields.contactListPhone'),
  },
  {
    name: 'contactListPhone2',
    label: i18n('entities.contactList.fields.contactListPhone2'),
  },
  {
    name: 'contactListEmail',
    label: i18n('entities.contactList.fields.contactListEmail'),
  },
  {
    name: 'contactListAddress',
    label: i18n('entities.contactList.fields.contactListAddress'),
  },
  {
    name: 'contactListRemarks',
    label: i18n('entities.contactList.fields.contactListRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.contactList.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.contactList.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
