import DigitalService from 'src/modules/digital/digitalService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'INSURANCE_VIEW';

const digitalViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: digitalViewActions.FIND_STARTED,
      });

      const record = await DigitalService.find(id);

      dispatch({
        type: digitalViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: digitalViewActions.FIND_ERROR,
      });

      getHistory().push('/digital');
    }
  },
};

export default digitalViewActions;
