import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TenantService from 'src/modules/tenant/tenantService';
import testamentaryTrustService from 'src/modules/testamentaryTrust/testamentaryTrustService';

import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'TESTAMENTARYTRUST_FORM';

const testamentaryTrustFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  INVITE_STARTED: `${prefix}_INVITE_STARTED`,
  INVITE_SUCCESS: `${prefix}_INVITE_SUCCESS`,
  INVITE_ERROR: `${prefix}_INVITE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: testamentaryTrustFormActions.INIT_STARTED,
      });

      let record = { rows: [] };

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TenantService.tenant_Find_Type(
          'TestamentaryTrust',
          tenantId,
          // false,
        );
        dispatch({
          type: testamentaryTrustFormActions.INIT_SUCCESS,
          payload:
            (record ?? ([] as any)).length > 0
              ? record[0]
              : record,
        });
      } else {
        record = await TenantService.tenant_List(
          'TestamentaryTrust',
          null,
          null,
          null,
          null,
          tenantId,
        );
        dispatch({
          type: testamentaryTrustFormActions.INIT_SUCCESS,
          payload: record.rows[0],
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: testamentaryTrustFormActions.INIT_ERROR,
      });

      getHistory().push('/testamentaryTrust');
    }
  },

  doCreate: (values, openSnackbar) => async (dispatch) => {
    try {
      dispatch({
        type: testamentaryTrustFormActions.CREATE_STARTED,
      });

      await testamentaryTrustService.create(values);

      dispatch({
        type: testamentaryTrustFormActions.CREATE_SUCCESS,
      });

      openSnackbar(
        i18n('entities.testamentaryTrust.create.success'),
        'success',
      );

      getHistory().push('/testamentaryTrust');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: testamentaryTrustFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: testamentaryTrustFormActions.UPDATE_STARTED,
        });

        await testamentaryTrustService.update(id, values);

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.testamentaryTrust.update.success'),
          'success',
        );

        getHistory().push(`/testamentaryTrust/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_ERROR,
        });
      }
    },

  doUpdateMultiple:
    (values, silent, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: testamentaryTrustFormActions.UPDATE_STARTED,
        });
        await values.map((item, index) => {
          let tempData = {
            index: index,
            testamentaryTrustName:
              item.testamentaryTrustName,
            testamentaryTrustIDType:
              item.testamentaryTrustIDType,
            testamentaryTrustIdentification:
              item.testamentaryTrustIdentification,
            testamentaryTrustAddress:
              item.testamentaryTrustAddress,
            testamentaryTrustPhone:
              item.testamentaryTrustPhone,
            testamentaryTrustPhone2:
              item.testamentaryTrustPhone2,
            testamentaryTrustEmail:
              item.testamentaryTrustEmail,
            testamentaryTrustPhoto:
              item.testamentaryTrustPhoto || [],
            fee: item.fee,
            testamentaryTrustCurrency:
              item.testamentaryTrustCurrency ||
              'malaysianRinggit',
            testamentaryTrustRemarks:
              item.testamentaryTrustRemarks,
            testamentaryTrustSequence: index,
            testamentaryTrustRelationship:
              item.testamentaryTrustRelationship,
            testamentaryTrustStatus:
              item.testamentaryTrustStatus,
          };

          testamentaryTrustService.update(
            item.id,
            tempData,
          );
          //BC19012022 - bypass array-callback-warning
          return index;
        });

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n(
            `entities.testamentaryTrust.update.${
              silent ? 'silent' : 'success'
            }`,
          ),
          'success',
        );

        getHistory().push('/testamentaryTrust');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_ERROR,
        });
      }
    },

  doInvite:
    (values, currentTenant, openSnackbar) =>
    async (dispatch) => {
      try {
        dispatch({
          type: testamentaryTrustFormActions.INVITE_STARTED,
        });

        var para = {
          email: values.testamentaryTrustEmail,
          token: values.testamentaryTrustToken,
          currentTenant: currentTenant,
        };

        await testamentaryTrustService.invite(values, para);

        dispatch({
          type: testamentaryTrustFormActions.INVITE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.testamentaryTrust.invite.success'),
          'success',
        );

        getHistory().push('/testamentaryTrust');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: testamentaryTrustFormActions.INVITE_ERROR,
        });
      }
    },

  doInitMain: (id) => async (dispatch) => {
    try {
      dispatch({
        type: testamentaryTrustFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await testamentaryTrustService.findMain(
          id,
        );
      }

      dispatch({
        type: testamentaryTrustFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: testamentaryTrustFormActions.INIT_ERROR,
      });

      getHistory().push('/testamentaryTrust');
    }
  },

  doCreateMain:
    (values, openSnackbar) => async (dispatch) => {
      try {
        dispatch({
          type: testamentaryTrustFormActions.CREATE_STARTED,
        });

        await testamentaryTrustService.create(values, true);

        dispatch({
          type: testamentaryTrustFormActions.CREATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.testamentaryTrust.create.success'),
          'success',
        );

        getHistory().push('/testamentaryTrust');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: testamentaryTrustFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdateMain:
    (id, values, openSnackbar) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: testamentaryTrustFormActions.UPDATE_STARTED,
        });

        await testamentaryTrustService.update(
          id,
          values,
          true,
        );

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_SUCCESS,
        });

        openSnackbar(
          i18n('entities.testamentaryTrust.update.success'),
          'success',
        );

        getHistory().push(`/testamentaryTrust/${id}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: testamentaryTrustFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default testamentaryTrustFormActions;
