import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pledge.fields.id'),
  },
  {
    name: 'orgCategory',
    label: i18n('entities.pledge.fields.orgCategory'),
  },
  {
    name: 'orgName',
    label: i18n('entities.pledge.fields.orgName'),
  },
  {
    name: 'orgAccountNo',
    label: i18n('entities.pledge.fields.orgAccountNo'),
  },
  {
    name: 'anonymousMode',
    label: i18n('entities.pledge.fields.anonymousMode'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'currency',
    label: i18n('entities.pledge.fields.currency'),
  },
  {
    name: 'amount',
    label: i18n('entities.pledge.fields.amount'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'remarks',
    label: i18n('entities.pledge.fields.remarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.pledge.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.pledge.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
