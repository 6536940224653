import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.debt.fields.id'),
  },
  {
    name: 'debtCategory',
    label: i18n('entities.debt.fields.debtCategory'),
  },
  {
    name: 'debtDescription',
    label: i18n('entities.debt.fields.debtDescription'),
  },
  {
    name: 'debtInstitution',
    label: i18n('entities.debt.fields.debtInstitution'),
  },
  {
    name: 'debtAccountNo',
    label: i18n('entities.debt.fields.debtAccountNo'),
  },
  {
    name: 'debtValue',
    label: i18n('entities.debt.fields.debtValue'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'debtCurrency',
    label: i18n('entities.debt.fields.debtCurrency'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'debtFile',
    label: i18n('entities.debt.fields.debtFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'debtRemarks',
    label: i18n('entities.debt.fields.debtRemarks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.debt.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.debt.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
