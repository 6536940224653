import selectors from 'src/modules/tasks/list/tasksListSelectors';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import Task_Service from 'src/modules/tasks/tasksService';

const tenantId = AuthCurrentTenant.get();

const prefix = 'NEW_TASK';

const newTaskActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  UPDATED_DATA: `${prefix}_UPDATED_DATA`,

  doInit:
    (query = {}) =>
    async (dispatch) => {
      try {
        const record = await Task_Service.getAllTasks(
          query,
        );

        dispatch({
          type: newTaskActions.FETCH_STARTED,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: newTaskActions.FETCH_ERROR,
        });
      }
    },

  // doCreate: (data) => async (dispatch) => {
  //     try {

  //         const record = await Task_Service.createTask(data);

  //         dispatch(newTaskActions.doInit());
  //     } catch (error) {
  //         Errors.handle(error);

  //         dispatch({
  //             type: newTaskActions.FETCH_ERROR,
  //         });
  //     }
  // }
};

export default newTaskActions;
