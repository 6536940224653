import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'resBen',
    label: i18n('entities.residuary.fields.resBen'),
    schema: schemas.relationToOne(
      i18n('entities.residuary.fields.resBen'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'resPercent',
    label: i18n('entities.residuary.fields.resPercent'),
    schema: schemas.integer(
      i18n('entities.residuary.fields.resPercent'),
      {
        "required": true,
        "max": 100,
        "min": 0
      },
    ),
  },
  {
    name: 'resRemarks',
    label: i18n('entities.residuary.fields.resRemarks'),
    schema: schemas.string(
      i18n('entities.residuary.fields.resRemarks'),
      {},
    ),
  },
];