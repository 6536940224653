import PetService from 'src/modules/tasks/tasksService';
import selectors from 'src/modules/tasks/list/tasksListSelectors';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import TenantService from 'src/modules/tenant/tenantService';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const tenantId = AuthCurrentTenant.get();

const prefix = 'TASKS_LIST';

const tasksListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doChangeSelected(payload) {
    return {
      type: tasksListActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: tasksListActions.RESETED,
    });

    dispatch(tasksListActions.doFetch());
  },

  doChangePaginationAndSort:
    (pagination, sorter) => async (dispatch, getState) => {
      dispatch({
        type: tasksListActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch({
        type: tasksListActions.SORTER_CHANGED,
        payload: sorter,
      });

      dispatch(tasksListActions.doFetchCurrentFilter());
    },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        tasksListActions.doFetch(filter, rawFilter, true),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: tasksListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        // const response = await PetService.list(
        //   filter,
        //   selectors.selectOrderBy(getState()),
        //   selectors.selectLimit(getState()),
        //   selectors.selectOffset(getState()),
        // );

        const response = await TenantService.tenant_List(
          'Tasks',
          null,
          null,
          null,
          null,
          tenantId,
        );

        dispatch({
          type: tasksListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tasksListActions.FETCH_ERROR,
        });
      }
    },
};

export default tasksListActions;
