import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'planPackage',
    label: i18n('entities.accessForm.fields.planPackage'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.accessForm.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.accessForm.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
