import list from 'src/modules/contactList/list/contactListListReducers';
import form from 'src/modules/contactList/form/contactListFormReducers';
import view from 'src/modules/contactList/view/contactListViewReducers';
import destroy from 'src/modules/contactList/destroy/contactListDestroyReducers';
import importerReducer from 'src/modules/contactList/importer/contactListImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
