import { Search as SearchIcon } from '@mui/icons-material';
import {
  InputBase,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { i18n } from 'src/i18n';

const Search = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.dark, 0.1),
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.primary.dark,
      0.15,
    ),
  },
  margin: '0px 10px 0px 0px',
  width: '100%',
  padding: '3px 10px 3px 10px',
}));

interface ISearchBar {
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar = ({ onClick }: ISearchBar) => {
  const isMac =
    navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;

  return (
    <Search>
      <InputBase
        placeholder={i18n('common.findCustomer')}
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={
          <Typography color={'#a8a8a8'}>
            {isMac ? '⌘' : 'ctrl+'}k
          </Typography>
        }
        startAdornment={
          <SearchIcon sx={{ color: '#a0a0a0' }} />
        }
        readOnly
        onClick={() => onClick(true)}
        fullWidth
      />
    </Search>
  );
};

export default SearchBar;
